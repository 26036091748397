require('./autocomplete.js');
require('./gh_input.scss');
require('./directive_Input.js');
require('./gh_not_found.js')

angular.module('ghInputModule', [
  'autoCompleteMod',
  'directive_input',
  'ghNotFoundModule'
])


/**************************************************************************************************************************************************************************************************|
|**************************************************************************     GH-INPUT    *******************************************************************************************************|
|**************************************************************************************************************************************************************************************************|
|  ┌───────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────┐
|  │                                                                                                                                                                                               |
|  |  <gh-input ng-model="field_model.field_value" gh-dropdown autocomplete="fieldsList {{field_model.data_model.app_id}}" gh-data-type="field" size="large" style="max-width: 195px;"></gh-input> |
|  |                                                                                                                                                                                               |
|  └───────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────┘
|
|  gh-element could be:
|      ◆ options  - if this attribute set we used a drop down list with material autocomplete
|      ◆ gh-data-type  - this shows what data type was by render
|      ◆ ng-model - used bind field values
|      ◆ onEnterNewDropdownValue  - used for adding new values with enter button
|      ◆ ghField  - used to render fields
|      ◆ showLoader  - show load animation (pass true to show animation)
|      ◆ ghRegularExp  - used ragular expresion to check if field value pass ragular expresion (function checkRegularExp)
|      ◆ ghEditable  - used for disabled material auto complete and clear button
|
|**********************************************************************************************************|
*/


.directive('ghInput', ['ghInputRegular', '$timeout', '$interpolate', 'AutoComplete', 'PipeService', function( ghInputRegular, $timeout, $interpolate, AutoComplete, PipeService) {
  var directive = {};

  directive.require = '^ngModel';
  directive.scope = {
    options: '=?ghDropdown',
    ghDataType: '@',
    ngModel: '=',
    onEnterNewDropdownValue: '&?',
    ghField: '=?',
    showLoader: '=?',
    ghRegularExp: '=?',
    ghEditable: '=?',
    useEventEnter: '@'
  };

  directive.controller = [ '$scope', '$element', '$attrs', function($scope, $element, $attrs) {

    // Used in gh-not-found to set new value in input
    $scope.setNgModel = function(value){
      $scope.ngModel = value
    }

    // Used in gh-not-found for after create new item push it to options
    $scope.addOption = function (value){
      $scope.options.push(value)
    }

    $scope.defaultFieldModel = {
      data_model: {
        options: $scope.options || undefined
      },
      data_type: $scope.ghDataType
    };
    
    $scope.decoratedFieldModel = angular.merge(angular.copy($scope.ghField || $scope.defaultFieldModel), {
      data_model: {
        show_field_name: false
      }
    });
    if (!$scope.decoratedFieldModel.data_type) $scope.decoratedFieldModel.data_type = $scope.ghDataType;

    if ($attrs.modelUpdate && $attrs.modelUpdate.indexOf('blur') != -1) {
      $scope.ngModelOptions = $attrs.modelUpdate.indexOf('update_by_enter') != -1 ? {updateOn: 'blur default'} : {updateOn: 'blur'};
    } else {
      $scope.ngModelOptions = {debounce: 500};
    }

    $scope.getMatches = function (query) {

      var filter = function (opt) {
        return !query || opt.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
      };  

      if ($attrs.autocomplete && $attrs.autocomplete.split(' ')[0] == 'usersList') {
        var arg = $attrs.autocomplete.replace('keyword', '{{searchText}}');
        return AutoComplete.getUsersList($interpolate(arg)($scope).split(' ')).then(function (result) {
          $scope.options = result;
          return result.filter(filter);
        });
      } else {
        return query ? $scope.options.filter(filter) : $scope.options;
      }
    };

    Object.defineProperty($scope, 'proxyModel', {
      get: function () {
        if ($scope.options) {
          return $scope.options.find(function(opt) {
            if ($scope.ngModel && (typeof $scope.ngModel == 'object')) {
              return angular.equals(opt, $scope.ngModel)
            } else {
              return String($scope.ngModel).length && opt.value == $scope.ngModel;
            }
          }) || null;
        } else {
          return null;
        }
      },
      set: function (newValue) {
        if (newValue) {
          if (newValue && (typeof newValue.value == 'object')) {
            $scope.ngModel = newValue;
          } else {
            $scope.ngModel = String(newValue.value);
          }
        } else {
          $scope.ngModel = '';
          // $scope.searchText = '';
        }
      }
    });
    // call every  time if we write on input
    $scope.addOnKeyPressListener = function() {
      if (typeof $scope.onEnterNewDropdownValue == 'function') {
          var input = $element.find('input');
          input.on('keypress', function(event) {  
            if (event.key == "Enter") {
              var finded = $scope.options.find(function(opt) {
                return opt.name.indexOf($scope.searchText) != -1;
              });

              if (!finded) {
                $scope.onEnterNewDropdownValue({value: $scope.searchText});
              }
            }
          })
      }
      if($scope.useEventEnter){
        let inputTest = $element.find('input');
        inputTest.on('blur', function(event) {
          if(event.type == "blur"){
            $scope.ngModel = String($scope.searchText);
            $scope.$apply();
          }
        })
      }
    };
    $scope.$watch('ngModel', function(n,o) {
      if (o && !n) {
        // $scope.proxyModel = '';
        $scope.searchText = '';
      }
    });

    
  }];

  directive.template = function(tElement, tAttrs) {

    var ngModelOptions = '';
    if (tAttrs.modelUpdate && tAttrs.modelUpdate.indexOf('blur') !== -1) {
      ngModelOptions = 'ng-model-options="ngModelOptions"';
    } 
    
    var inputControl;
    if (angular.isDefined(tAttrs.ghDropdown) || angular.isDefined(tAttrs.autocomplete)) {
      inputControl =
        `<md-autocomplete
           placeholder="Select value" 
           ng-disabled="!editable" 
           md-clear-button="editable" 
           md-selected-item="proxyModel" 
           ng-disabled="isDisabledAutocomplete" 
           md-search-text-change="addOnKeyPressListener()" 
           md-min-length="0"
           md-search-text="searchText" 
           md-items="item in getMatches(searchText)" 
           md-item-text="item.name">
            <span md-highlight-text="searchText">{{item.name}}</span> 
            <md-not-found>
              <gh-not-found type-list="typeList" gh-field="ghField" search-text="searchText" set-ng-model="setNgModel" add-option="addOption" ></gh-not-found>
            </md-not-found>
        </md-autocomplete>`;
      // '     <gh-element value="opt.value" decorator="decoratedFieldModel" elem-src="dropdown"/>' +
    } else {
      inputControl =
        ' <md-input-container md-no-float>' +
        '   <input autocomplete="off" name="localInput" ng-disabled="!editable" type="text" ng-model="localValue" ng-trim="false" ' + ngModelOptions + ' placeholder="Type value">' +
        ' </md-input-container>';
    }

    return '' +
      '<div ng-show="showLoader" style="width: 200px; position:relative; top: 20px; left: 5px">' +
      ' <md-progress-linear md-mode="query"></md-progress-linear>' +
      '</div>' +
      '<span ng-show="!showLoader" class="btn-group gh-btn-group" ng-form="form">' + inputControl + '</span>';
  };

  directive.link = function(scope, iElement, iAttrs, ngModelCtrl) {
    if (!ngModelCtrl) return;
    var input = iElement.find('input');
    // Parameter for editable input. 
    scope.editable = angular.isDefined(iAttrs.ghEditable) ? scope.ghEditable : true; 

    function prependValueWithSymbols(val, viewLength, prependSymbol = '0') {
      if (String(val).length < Number(viewLength)) {
        let arr = new Array(Number(viewLength));
        arr.fill(prependSymbol);
        return arr.join('').slice(String(val).length - viewLength) + val;
      }
      return val;
    }

    if (angular.isUndefined(iAttrs.ghDropdown) && angular.isUndefined(iAttrs.autocomplete)) {
      
      var updateByEnter = iAttrs.modelUpdate && iAttrs.modelUpdate.indexOf('update_by_enter') !== -1 ? true : false;
    
      /* delete ' update_by_enter' from events*/
      if (updateByEnter) iAttrs.modelUpdate = iAttrs.modelUpdate.replace('update_by_enter', 'keyup');

      if (angular.isDefined(scope.decoratedFieldModel.data_model.precision) && scope.decoratedFieldModel.data_model.precision !== '0' && scope.ngModel) {
        ngModelCtrl.$setViewValue(scope.ngModel);
        scope.$applyAsync(function() {
          ngModelCtrl.$render();
        });
      }
  
      input.on(iAttrs.modelUpdate || 'keyup', function(event) {
        if(event.type == 'focus') {
          scope.localValue = ngModelCtrl.$viewValue
        }
        if (scope.form.localInput.$valid) {
          if (event.type == 'blur' || (scope.localValue && event.type == 'keyup' && checkKeyCode(event.keyCode, updateByEnter))) {
            if (scope.decoratedFieldModel.data_model.min_digits_count && scope.decoratedFieldModel.data_model.min_digits_count != -1) {
              ngModelCtrl.$setViewValue(prependValueWithSymbols(scope.localValue, scope.decoratedFieldModel.data_model.min_digits_count));
              ngModelCtrl.$render();
            } else {
              if (angular.isDefined(scope.decoratedFieldModel.data_model.precision) && scope.decoratedFieldModel.data_model.precision !== '0' && scope.localValue) {
                ngModelCtrl.$setViewValue(Number(scope.localValue).toFixed(scope.decoratedFieldModel.data_model.precision));
                ngModelCtrl.$render();
              } else {
                ngModelCtrl.$setViewValue(scope.localValue);
                ngModelCtrl.$render();
              }
            }
          }
        }
          scope.$digest();


        function checkKeyCode(key, flag) {
          var keyCodes = [13, 188];
          if (!flag) {
            return true;
          } else {
            if (keyCodes.indexOf(key) !== -1) {
              scope.localValue = scope.localValue.replace(/[\s,]{1}$/, '');
              return true;
            }
            return false;
          }
        }
      });

      ngModelCtrl.$render = function() {
        const prefix = scope.decoratedFieldModel.data_model.prefix;
        const suffix = scope.decoratedFieldModel.data_model.suffix;
        if(prefix || suffix && !scope.decoratedFieldModel.data_model.multiple_value){
          scope.localValue = ngModelCtrl.$viewValue ? `${prefix ? prefix : ''}${ngModelCtrl.$viewValue}${suffix ? suffix : ''}`: ngModelCtrl.$viewValue
        }else {
         scope.localValue = ngModelCtrl.$viewValue;
        }
      };

      // scope.form.localInput.$validators.dataCheck = function(modelValue, viewValue) {
      //   return ghInputService.checkData(scope.ghDataType, viewValue);
      // };
      scope.form.localInput.$validators.dataCheck = function (
        modelValue,
        viewValue
      ) {
        if (
          scope.ghField &&
          scope.decoratedFieldModel &&
          viewValue != undefined
        ) {
          const prefix = scope.decoratedFieldModel.data_model.prefix;
          const suffix = scope.decoratedFieldModel.data_model.suffix
          const regexp = `${
            prefix
              ? "^" +
              prefix.replaceAll(
                /[-\/\\^$*+?.()|[\]{}]/g,
                "\\$&"
              )
              : ""
          }${
            suffix &&
            prefix
              ? "|"
              : ""
          }${
            suffix
              ? suffix.replaceAll(
              /[-\/\\^$*+?.()|[\]{}]/g,
              "\\$&"
            ) + "$"
              : ""
          }`;
          return ghInputRegular.checkRegularExp(
            scope.ghRegularExp,
            regexp ? viewValue.replaceAll(new RegExp(regexp, 'g'), '') : viewValue
          );
        }
        return ghInputRegular.checkRegularExp(
          scope.ghRegularExp,
          viewValue
        );
      };
    } else {
      if (iAttrs.autocomplete) {  
        iAttrs.$observe('autocomplete', function() {
          switch (iAttrs.autocomplete.split(' ')[0]) {
            case 'itemsList':
              scope.isDisabledAutocomplete = true;
              AutoComplete.getItemsList($interpolate(iAttrs.autocomplete)(scope).split(' ')).then(function (result) {
               
                if (result == 'not_enough_args') {
                  scope.proxyModel = '';
                  if (scope.options) {
                    scope.options.length = 0;
                  }
                } else {
                  if (scope.options) {
                    scope.options.length = 0;
                    scope.options.push(...result);
                  } else {
                    scope.options = result;
                  }
                }
                scope.isDisabledAutocomplete = false;
              });


              //subscribe changes
              $interpolate(iAttrs.autocomplete)(scope).split(' ').forEach(function (reference) {
                PipeService.on('gh_items_update', {app_id: reference.split('.')[0]}, function itemsPipe() {
                  AutoComplete.getItemsList($interpolate(iAttrs.autocomplete)(scope).split(' ')).then(function (result) {
                    scope.options.push(...result
                      .filter(function (newItem) {
                        return scope.options
                          .every(function (source) {
                            return newItem.value != source.value;
                          });
                      }));
                  });
                }, scope);
              });

              break;
            case 'filterItemList':
              scope.isDisabledAutocomplete = true;
              scope.typeList = 'itemList'; 
              AutoComplete.filterItemList($interpolate(iAttrs.autocomplete)(scope).split(' ')).then(function (result) {
                if (result == 'not_enough_args') {
                  scope.proxyModel = '';
                  if (scope.options) {
                    scope.options.length = 0;
                  }
                } else {
                  if (scope.options) {
                    scope.options.length = 0;
                    scope.options.push(...result);
                  } else {
                    scope.options = result;
                  }
                }
                scope.isDisabledAutocomplete = false;
              });
              //subscribe changes
              $interpolate(iAttrs.autocomplete)(scope).split(' ').forEach(function (reference) {
                PipeService.on('gh_items_update', {app_id: reference.split('.')[0]}, function itemsPipe() {
                  AutoComplete.filterItemList($interpolate(iAttrs.autocomplete)(scope).split(' ')).then(function (result) {
                    scope.options.push(...result
                      .filter(function (newItem) {
                        return scope.options
                          .every(function (source) {
                            return newItem.value != source.value;
                          });
                      }));
                  });
                }, scope);
              });
            break;
            case 'groupeItemList':
              scope.isDisabledAutocomplete = true;
              AutoComplete.groupeItemList($interpolate(iAttrs.autocomplete)(scope).split(' ')).then(function (result) {
                if (result == 'not_enough_args') {
                  scope.proxyModel = '';
                  if (scope.options) {
                    scope.options.length = 0;
                  }
                } else {
                  if (scope.options) {
                    scope.options.length = 0;
                    scope.options.push(...result);
                  } else {
                    scope.options = result;
                  }
                }
                scope.isDisabledAutocomplete = false;
              });
            break;
            case 'fieldsList':
              scope.isDisabledAutocomplete = true;
              AutoComplete.getFieldsList($interpolate(iAttrs.autocomplete)(scope.$parent).split(' ')).then(function (result) {
                if (result == 'not_enough_args') {
                  scope.proxyModel = '';
                  if (scope.options) {
                    scope.options.length = 0;
                  }
                } else {
                  if (scope.options) {
                    scope.options.length = 0;
                    scope.options.push(...result);
                  } else {
                    scope.options = result;
                  }
                }
                scope.isDisabledAutocomplete = false;
              });
              break;
            case 'appsList':
              scope.isDisabledAutocomplete = true;
              AutoComplete.getAppsList($interpolate(iAttrs.autocomplete)(scope).split(' ')).then(function (result) {
                if (scope.options) {
                  scope.options.length = 0;
                  scope.options.push(...result);
                } else {
                  scope.options = result;
                }
                scope.isDisabledAutocomplete = false;
              });
              break;
            case 'viewList':
            scope.isDisabledAutocomplete = true;
              AutoComplete.getViewList($interpolate(iAttrs.autocomplete)(scope).split(' ')).then(function (result) {

                if (scope.options) {
                  scope.options.length = 0;
                  scope.options.push(...result);
                } else {
                  scope.options = result;
                }
                scope.isDisabledAutocomplete = false;
              });
              break;
          }
        });
      }
    }
  };

  return directive;
}])



