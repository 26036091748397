angular.module('ghElementSettingsModule', [])

/*============================================================================================================*/
/*====================================    SETTING FIELD DIRECTIVE    ========================================*/
/*===========================================================================================================*/

/*---------------------- APPLICATION FOR HELP ----------------------------------*/
/* We use this application to get data for tooltip */
// app_help: {
//   app_id: 7054,
//     field_id_map: 82396,
//     field_id_description: 82391
// }
/*------------------------------------------------------------------------------*/


.directive('fieldSetting', ['$compile', 'fieldSettingsService', function($compile, fieldSettingsService) {
  let directive = {
    restrict: 'E'
  };
  directive.link = function (scope, element) {
    fieldSettingsService.getSettingHtml(scope).then(function (result) {
      element.empty().html( result );
       $compile( element.contents() )(scope);

       // Add class
       if(scope.setting.class){
        element.parent().addClass(scope.setting.class);
       }
    });
  };

  return directive;
}])
/*============================================================================================================*/
    /*===================================     FIELD PREVIEW DIRECTIVE    ========================================*/
    /*===========================================================================================================*/
    /* This directive generates fields sample in Field Editor*/

    .directive('fieldPreview', ['$compile', function($compile) {
      var directive = {};

      directive.scope = {
        fieldModel: '=',
        elemSrc: '@',
        value: '=',
        showField: '=', // Needs to show hide field in editing form
        interpretationType: '@', // type of interpretation
        interpretationObjIndex: '@', // index of interpretation in data_model interpretation array. Must be a number
        interpretation: '='
      };

      directive.link = function(scope, elem, attrs) {
      // Private compile scope
        var privateFieldPreviewScope = scope.$new();

        scope.fieldModelScope = {};
        //-- These staff is run each time when we change directive's attribute
        scope.$watch('fieldModel', function(newValue, oldValue) {
          if(newValue != oldValue){
            privateFieldPreviewScope.$destroy();
            renderFieldPreview();
          }
        }, true);

        //-------- RENDERING FIELDS SETTINGS -------------
        function renderFieldPreview() {

          // If not present source, delete field interpretation
          if(!scope.elemSrc){
            elem.empty();
            return;
          }

          // This need to set current interpretation, when we have more than one
          // same interpretation place

          scope.field_model = angular.merge(angular.copy(scope.fieldModel), {
            data_model:{
              interpretation: []
            },
            field_value: scope.value
          });



          if(!scope.field_model.data_model.interpretation.length){
            scope.field_model.data_model.interpretation = [scope.interpretation];
          }



          // This need to set current interpretation, when we have more than one
          // same interpretation place and diferend interpretation for one place

          var index = scope.interpretationObjIndex;

          let editorInterpritation = scope.field_model.data_model.interpretation.find(i => i.src == 'editor');
          let editorInterpritationSrc;

          if (editorInterpritation) {
            editorInterpritationSrc = editorInterpritation.src;
          }

          scope.field_model.data_model.interpretation = scope.field_model.data_model.interpretation.filter(function (intrpr, i) {
            return index ? i == index : true;
          }).map(function (intrpr) {
            angular.merge(intrpr, {
              id: editorInterpritationSrc ? editorInterpritationSrc : scope.interpretationType || intrpr.id,
              settings: {
                show_field: scope.showField || intrpr.settings.show_field
              }
            });

            return intrpr;
          });

          //-- Constructing Field
          var fieldPreviewHtml = '<div class="field" gh-element decorator="field_model" elem-src="{{elemSrc}}" ></div>';

          privateFieldPreviewScope = scope.$new();
          //-- Rendering Field
          var el = angular.element(fieldPreviewHtml);
          var compiled = $compile(el);
          elem.empty();
          elem.append(el);
          compiled(privateFieldPreviewScope);
        }

        renderFieldPreview();
      };

      return directive;
    }])

.service('fieldSettingsService', ['$injector', 'PipeService',  'fileManager', 'cnfg', '$q', function ($injector, PipeService, fileManager, cnfg, $q) {


  // Returm html template for each field setting type
  this.getSettingHtml = function (scope) {
    let deferred = $q.defer();
    let negativeExpr = '';
    let templateList = {
      header: function (setting) {
        return '<h3>' + setting.title + '</h3>';
      },
      ghElement: function (setting) {
        if (setting.showIf && setting.showIf.indexOf('!') == 0) {
          negativeExpr = '!';
          setting.showIf = setting.showIf.slice(1);
        }

        return ' <div class="flex">' +
          '   <div ' + (setting.showIf ? 'ng-show="' + negativeExpr + 'fieldModel.' + setting.showIf + '"' : '') + ' gh-element decorator="field_model" value="fieldModel.' + setting.property + '" elem-src="form"></div>' +
          ' </div>';
      },
      html: function (setting) {
        return '<div class="flex"' + (setting.showIf ? 'ng-show="' + negativeExpr + 'fieldModel.' + setting.showIf + '"' : '') + '>' + setting.control + '</div>';
      }
    };

    // Set field model to scope
    scope.field_model = {};

    if (typeof scope.setting.data_model == 'function') {
      angular.extend(scope.field_model, scope.setting.data_model(scope.fieldModel, scope.appId));
    }

    // Initialization init function if it present
    if (typeof scope.setting.onInit == 'function') {
      scope.setting.onInit(scope, scope.fieldModel, scope.setting);
    }
    // Generation text for ToolTip
    if(scope.field_model && scope.field_model.data_type && scope.field_model.name_space){
      if (!scope.field_model.data_model) {
        scope.field_model.data_model = {};
      }
      if (!scope.field_model.data_model.tooltip) {
        scope.field_model.data_model.tooltip = {};
      }
      scope.field_model.data_model.tooltip = {
        ...scope.field_model.data_model.tooltip,
        filter_valuesArray : [scope.fieldModel.data_type, scope.field_model.name_space]
      }
    }

    // if (scope.field_model && scope.field_model.data_type && scope.field_model.name_space && false) {
    //   let address = {
    //     app_id:  cnfg.app_help.app_id
    //   };
    //
    //   let filterList = [{
    //     data_type: 'text',
    //     field_id: cnfg.app_help.field_id_map,
    //     search_type: 'equal_and',
    //     valuesArray: [scope.fieldModel.data_type, scope.field_model.name_space]
    //   }];
    //
    //   PipeService.on('gh_items_get', address, function getAppItemsPipe(event, items) {
    //     PipeService.destroy('gh_items_get', address, getAppItemsPipe);
    //
    //     advancedFiltering(items, filterList).then(function (itemsList) {
    //       if (itemsList.length) {
    //         let modelAddress = {
    //           app_id: cnfg.app_help.app_id,
    //           item_id: itemsList[0].item_id,
    //           field_id: cnfg.app_help.field_id_description
    //         };
    //
    //         PipeService.on('gh_value_get', modelAddress, function ghValueGet(event, field_value) {
    //           PipeService.destroy('gh_value_get', modelAddress, ghValueGet);
    //           if (!scope.field_model.data_model) {
    //                   scope.field_model.data_model = {};
    //           }
    //           scope.field_model.data_model = angular.merge(scope.field_model.data_model, {
    //             tooltip: {
    //               settings_file_id: field_value,
    //             }
    //           });
    //
    //           deferred.resolve( templateList[scope.setting.type](scope.setting) );
    //
    //         }).emit('gh_value_get', {}, modelAddress);
    //       } else {
    //         deferred.resolve( templateList[scope.setting.type](scope.setting) );
    //       }
    //     });
    //   }).emit('gh_items_get', {}, address);
    // } else {
      deferred.resolve( templateList[scope.setting.type](scope.setting) );
    // }

    return deferred.promise;
  };

  //Return default setting type settings
  this.getDefaultSettings = function (settingsType) {
    let defaultSettings = {

      // ----------------------------------------- GENERAL SETTING --------------------------------------------
      // ------------------------------------------------------------------------------------------------------
      general_setting: [
        {
          title: 'Options',
          type: 'general_setting',
          icon: 'menu',
          columns_list:[
            [
              {
                title: 'Field Settings',
                type: 'header'
              },{
              type: 'ghElement',
              property: 'field_name',
              data_model: function () {
                return {
                  field_name: 'Field Name',
                  name_space: 'field_name',
                  data_type: 'text',
                  data_model: {
                    tooltip: {
                      default: '<h3>Field Name</h3>Here you can change the name of the field.',
                      en: 'EN',
                      ua: 'UA'
                    }
                  }
                };
              }
            },{
              type: 'ghElement',
              property: 'name_space',
              data_model: function () {
                return {
                  field_name: 'Name space',
                  name_space: 'name_space',
                  data_type: 'text',
                  data_model: {
                    tooltip: {
                      default: `<h3>Name space</h3>Name space is text identification field.
                        It is used for data export/import and binding with other applications.
                        The value for this field is set by default, but can be changed if necessary.
                        Name space field must not contain capital letters or blank spaces.`,
                      en: 'EN',
                      ua: 'UA'
                    }
                  }
                };
              }
            }
            ]
          ]
        },{
          title: 'Style',
          type: 'general_setting',
          icon: 'eye',
          columns_list:[
            [
              {
                type: 'html',
                class: 'interpretation_type_settings_table',
                data_model: function () {
                  return {};
                },
                control:
                  `<table ng-cloak>
                       <tr>
                         <th>Place</th>
                         <th colspan="2">Edit</th>
                       </tr>
                       <tr ng-repeat="intr in fieldModel.data_model.interpretation">
                         <td>{{(intr.src.charAt(0).toUpperCase() + intr.src.slice(1)).replace(\'_\', \' \')}}</td>
                         <td gh-icon="pencil 86BEF6 25px normal" ng-click="editStyle($index)"></td>
                         <td gh-icon="rubbish 86BEF6 25px normal" ng-click="fieldModel.data_model.interpretation.splice($index, 1)"></td>
                       </tr>
                       <tr class="transition">
                         <td colspan="3" ng-click="addInterpreter()">
                           <div><span gh-icon="plus fff 25px normal"></span><span>Add interpretation</span></div>
                         </td>
                       </tr>
                   </table>`
              }
            ]
          ]
        },{
          title: 'Embed',
          type: 'general_setting',
          icon: 'embed',
          columns_list:[
            [
              {
                type: 'html',
                class: 'option-column_800_size',
                data_model: function () {
                  return {};
                },
                control: '<gh-embed app-id="{{appId}}" field-id="{{fieldModel.field_id}}" field-data-type="{{fieldModel.data_type}}"></gh-embed>'
              }
            ]
          ]
        }, {
          title: 'Code',
          type: 'general_setting',
          icon: 'code',
          columns_list:[
            [
              {
                type: 'ghElement',
                class: 'option-column_full_size',
                property: 'data_model.code',
                data_model: function () {
                  return {
                    data_model:{
                      code_mode: 'javascript',
                      save_file: false,
                      interpretation:[
                        {
                          src: 'form',
                          settings:{
                            show_field_name: 0
                          }
                        }
                      ],
                    },
                    field_name: 'Code Editor',
                    name_space: 'code_editor',
                    data_type: 'code_editor'
                  };
                },
                // control: '<gh-code-editor model="fieldModel.data_model" destination-property="code" mode="javascript" editable="1"></gh-code-editor>'
              },
            ]
          ]
        },{
          title: 'Tooltip',
          type: 'general_setting',
          icon: 'txt',
          columns_list:[
            [
              {
                type: 'ghElement',
                class: 'option-column_full_size',
                property: 'data_model.tooltip.default',
                data_model: function () {
                  return {
                    data_model:{
                      code_mode: 'htmlmixed',
                      save_file: false,
                      interpretation:[
                        {
                          src: 'form',
                          settings:{
                            show_field_name: 0
                          }
                        }
                      ],
                    },
                    field_name: 'Code Editor',
                    name_space: 'code_editor',
                    data_type: 'code_editor'
                  };
                },
              },
              //   control: '<gh-code-editor model="fieldModel.data_model" destination-property="tooltip.default" mode="htmlmixed" editable="1"></gh-code-editor>'
            ]
          ]
        },{
          title: 'Automation',
          type: 'general_setting',
          icon: 'menu',
          columns_list: [
						[
              {
                type: 'ghElement',
                property: 'data_model.automation.active',
                data_model: function () {
                  return {
                    field_name: 'Activate',
                    name_space: 'activate',
                    data_type: 'boolean'
                  };
                }
              },
              {
                type: 'html',
                class: 'option-column_full_size',
                data_model: function (fieldModel) {
                  let constructorType =  $injector.get(fieldModel.data_type).getTemplate().constructor;

                  //adding default automation model to element if it doesn't have it
                  if ( !fieldModel.data_model.hasOwnProperty('automation') && constructorType == 'field'){
                    fieldModel.data_model.automation = {
                      active: false,
                      model:{"id":"trigger@0.1.0","nodes":{"1":{"id":1,"data":{},"inputs":{},"outputs":{"updatedItems":{"connections":[]}},"position":[80,200],"name":"Gh Element Node"},"8":{"id":8,"data":{"output_value":null},"inputs":{"inpItems":{"connections":[]}},"outputs":{},"position":[605.07421875,193.32421875],"name":"Populate Element"}}}
                    }
                  }

                  return {
										include_nodes: ['Filter','GetItems' ,'GetItemByItemRef', 'GhElementNode', 'PopulateElement', 'PopulateWithDate', 'ItemConstructor', 'ItemDestructor', 'ifCondition', 'FileDuplicate', 'Calculator', "CreateItemApi", "UpdateItemsApi", "PopulateItems", "CompareItems", "PopulateWithItemRef", "MergeItems", "Request", "Constants", "MessageConstructor", "ObjectDestructor", "ObjectConstructor", "TwilioSms"]
									};
                },
								control: '<gh-automation app-id="appId" element-id="elementId" gh-model="fieldModel.data_model.automation.model" nodes-model="field_model"/>'
              },
						]
					]
				}
      ],

      // ----------------------------------- INTERPRETATION SETTING --------------------------------------
      // -------------------------------------------------------------------------------------------------
      interpretation_setting:[
        {
          title: 'Style',
          icon: 'eye',
          type: 'interpretation_setting',
          columns_list:[
            [{
              title: 'General settings',
              type: 'header'
            },{
              type: 'ghElement',
              property: 'interpreter.src',
              data_model: function (fieldModel) {
                let module = $injector.get(fieldModel.data_type);
                let options = module.getTemplate().model.data_model.interpretation.map(function (intrpr) {
                  return {
                    name: (intrpr.src.charAt(0).toUpperCase() + intrpr.src.slice(1)).replace('_', ' '),
                    value: intrpr.src
                  };
                });

                options.push({
                  name: 'Container',
                  value: fieldModel.container_id
                });

                return {
                  data_model:{
                    // List of element places interpretations
                    options: options
                  },
                  field_name: 'Source place',
                  data_type: 'text_opt'
                };
              }
            },{
              type: 'ghElement',
              property: 'interpreter.settings.editable',
              data_model: function () {
                return {
                  field_name: 'Editable',
                  data_type: 'boolean'
                };
              }
            },{
              type: 'ghElement',
              property: 'interpreter.settings.show_field_name',
              data_model: function () {
                return {
                  field_name: 'Show field name',
                  data_type: 'boolean'
                };
              }
            },{
              type: 'ghElement',
              property: 'interpreter.settings.show_field',
              data_model: function () {
                return {
                  field_name: 'Show field',
                  data_type: 'boolean'
                };
              }
            }], [{
              title: 'Font size',
              type: 'header'
            },{
              type: 'html',
              control:
                '<div class="font-size-setting">' +
                '   <div><p class="font42">A</p></div>' +
                '   <div>' +
                '     <md-slider md-discrete ng-model="fieldModel.interpreter.style.font_size" step="1" min="4" max="64" aria-label="font_size"></md-slider>' +
                '   </div>' +
                '   <div><p class="font28">A</p></div>' +
                '   <md-input-container class="transition-disabled">' +
                '      <input type="number" ng-model="fieldModel.interpreter.style.font_size" min="4" max="64" step="1" aria-label="fontSize">' +
                '   </md-input-container>' +
                '</div>'
            },{
              title: 'Alignment',
              type: 'header'
            },{
              type: 'ghElement',
              property: 'interpreter.style.position',
              data_model: function () {
                return {
                  data_model:{
                    multiple_value: 0,
                    interpretation:[
                      {
                        src: 'form',
                        settings:{
                          show_field_name: 0
                        }
                      }
                    ],
                    options:[
                      { name: 'Left', value: 'left', color: '#d1d8dd', icon: 'left_position' },
                      { name: 'Right', value: 'right', color: '#d1d8dd', icon: 'right_position' },
                      { name: 'Justify', value: 'beetwen', color: '#d1d8dd', icon: 'beetwen_position' }
                    ],
                    show_field_name: false
                  },
                  data_type: 'radio_icon'
                };
              }
            }], [{
              title: 'Interpretation type',
              type: 'header'
            },{
              type: 'html',
              class: 'interpretation-type',
              onInit: function (scope, fieldModel) {
                let module = $injector.get(fieldModel.data_type);

                // List of element interpretations
                module.getInterpretation().then(function (interpetations) {
                  scope.setting.interpretations = interpetations;
                });
              },
              control:
                '<md-radio-group ng-model="fieldModel.interpreter.id" class="interpretation-type">' +
                '   <md-radio-button ng-repeat="interpretation in setting.interpretations" ng-value="interpretation.id" aria-label="{{interpretation.id}}">' +
                '      <span style="font-size: 11px; color: #bbbcc5;">{{interpretation.name}}</span>'+
                '       <field-preview interpretation="interpretation" field-model="fieldModel" elem-src="{{fieldModel.interpreter.src || \'form\'}}" value="value" show-field="1" interpretation-type="{{interpretation.id}}" interpretation-obj-index="{{interpretationObjIndex}}"></field-preview>' +
                '   </md-radio-button>' +
                '</md-radio-group>'
            }]
          ]
        }
      ]
    };

    return defaultSettings[settingsType] || [];
  };

  return this;
}])

.directive('ghToolTip', ['$compile', 'fileManager', 'cnfg', 'PipeService', '$window', 'itemsDataProcessing',($compile, fileManager, cnfg, PipeService, $window, itemsDataProcessing) => {
  return {
    restrict: 'E',
    scope: {
      model: '=',
      ghField: '='
    },
    template:
      '<span class="field-tooltip">'+
      '<span ng-mouseenter="mouseEnterTarget($event)" class="field-tooltip-icon">?</span>'+
      '<div ng-style="position" class="field-tooltip-wrap"><div class="field-tooltip-container"></div></div>'+
      '</span>',
    link: async function(scope, element) {
      scope.position = {
        'top': '0',
        'left': '0'
      };

      function getItems(address) {
        return new Promise((resolve) => {
          PipeService.on("gh_items_get", address, function itemPipe(event, items) {
            resolve(items)
            PipeService.destroy("gh_items_get", address, itemPipe);
          }).emit("gh_items_get", {}, address);
        });
      }

      function getValue(address) {
        return new Promise((resolve) => {
          PipeService.on("gh_value_get", address, function ghValueGet(
              event,
              field_value
          ) {
            resolve(field_value)
            PipeService.destroy("gh_value_get", address, ghValueGet);
          }).emit("gh_value_get", {}, address);
        });
      }

      function getAppList(){
        return new Promise((resolve) => {
          PipeService.on("gh_apps_list_get", {app_id: cnfg.app_help.app_id,}, function itemPipe(event, apps) {
            resolve(apps)
            PipeService.destroy("gh_apps_list_get", {app_id: cnfg.app_help.app_id,}, itemPipe);
          }).emit("gh_apps_list_get", {}, {app_id: cnfg.app_help.app_id,});
        });
      }

      const apps = await getAppList();
      const helpApp = apps.find(app => app.app_id == cnfg.app_help.app_id);
      scope.isHasEditPermission = !!helpApp && helpApp.permission > 1;
      
      async function getTooltip() {
        if (
            scope.model &&
            scope.model.tooltip &&
            scope.model.tooltip.filter_valuesArray
        ) {
          const address = {
            app_id: cnfg.app_help.app_id,
          };

          const filterList = [
            {
              data_type: "text",
              field_id: cnfg.app_help.field_id_map,
              search_type: "equal_and",
              valuesArray: scope.model.tooltip.filter_valuesArray,
            },
          ];
          
          const items = await getItems(address);
          const itemsList = gudhub.util.filter(items, filterList);
            if (itemsList.length) {
              let modelAddress = {
                app_id: cnfg.app_help.app_id,
                item_id: itemsList[0].item_id,
                field_id: cnfg.app_help.field_id_description,
              };
              const field_value = await getValue(modelAddress);
              scope.model = angular.merge(scope.model, {
                tooltip: {
                  settings_file_id: field_value,
                  edit_ref: scope.isHasEditPermission ? `${cnfg.app_help.app_id}/${cnfg.app_help.view_id}/${itemsList[0].item_id}` : null,
                },
              });
            }
        }
      }
       await getTooltip();
      
      scope.openItem = function (address){
        $window.open(`act/open_item/${address}`, '_blank');
      }

      scope.createTooltip = async function (){
          const newItems = [
            {
              item_id: 0,
              fields: [
                {
                  field_id: cnfg.app_help.field_id_map,
                  field_value: `${scope.model.tooltip.filter_valuesArray.join(',')},options_set`,
                },
              ],
            },
          ];
          const items = await itemsDataProcessing.addNewItems(cnfg.app_help.app_id, newItems);
          scope.model.tooltip.edit_ref = `${cnfg.app_help.app_id}/${cnfg.app_help.view_id}/${items[0].item_id}`

          scope.openItem(scope.model.tooltip.edit_ref);
      }
      
      function render(tooltip) {
        const template_string = `<div class="field-tooltip-block">
                                    <div>${tooltip}</div>
                                    <span class="field-tooltip-edit"
                                          title="Open tooltip"
                                          ng-if="model.tooltip.edit_ref" 
                                          ng-click="openItem(model.tooltip.edit_ref)" 
                                          gh-icon="go_to_view 86BEF6 25px normal">
                                    </span>
                                    <span class="field-tooltip-edit"
                                          title="Create tooltip"
                                          ng-if="!model.tooltip.edit_ref && !model.tooltip.default && isHasEditPermission"
                                          ng-click="createTooltip()" 
                                          gh-icon="plus 86BEF6 25px normal">
                                    </span>  
                                 </div>`;
        const linkFn = $compile(template_string);

        angular.element( element[0].querySelector(".field-tooltip-container") ).empty().append(linkFn(scope));
      }

      function removeTooltip () {
        angular.element( element.remove());
      }
      
      scope.mouseEnterTarget = function (event) {
        scope.position.top = event.clientY + "px";
        scope.position.left = event.clientX + "px";
        if(scope.model.tooltip.settings_file_id){

          render('<gh-loader color="blue"></gh-loader>');
          fileManager.downloadFileFromString(cnfg.app_help.app_id, scope.model.tooltip.settings_file_id).then(function (file) {
            if (file) {
              scope.model = angular.merge(scope.model, {
                tooltip: {
                  default: file.data
                }
              });
              render(scope.model.tooltip.default);
            }
          });
          
        } 
        else if(!scope.model.tooltip.default && scope.isHasEditPermission) {
          render('<p>No description</p>');
        } else if(scope.model.tooltip.default){
          render(scope.model.tooltip.default);
        } else {
          removeTooltip();
        }
      }

    }
  };
}]);


