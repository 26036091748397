import { PopulateWithItemRef } from "./modules/populate_with_item_ref.js";
import { GetItemByItemRef } from "./modules/get_item_by_item_ref.js";
import { PopulateWithDate } from "./modules/populate_with_date.js";
import { ObjectDestructor } from "./modules/object_destructor.js";
import { ObjectConstructor } from "./modules/object_constructor";
import { ItemConstructor } from "./modules/item_constructor.js";
import { PopulateElement } from "./modules/populate_element.js";
import { UpdateItemsApi } from "./modules/update_items_api.js";
import { ItemDestructor } from "./modules/item_destructor.js";
import { GhElementNode } from "./modules/gh_element_node.js";
import { CreateItemApi } from "./modules/create_item_api.js";
import { FileDuplicate } from "./modules/file_duplicate.js";
import { PopulateItems } from "./modules/populate_items.js";
import { FaceDetector } from "./modules/face_detector.js";
import { CompareItems } from "./modules/compare_items.js";
import { ObjectToItem } from "./modules/obj_to_item.js";
import { ifCondition } from "./modules/if_condition.js";
import { CreateFiles } from "./modules/create_files.js";
import { MergeItems } from "./modules/merge_items.js";
import { SmartInput } from "./modules/smart_input.js";
import { Response } from "./modules/response_node.js";
import { Calculator } from "./modules/calculator.js";
import { Trigger } from "./modules/trigger_node.js";
import { Request } from "./modules/request_node.js";
import { JSONScheme } from "./modules/json_scheme";
import { GetItems } from "./modules/get_items.js";
import { Filter } from "./modules/filter_node.js";
import { API } from "./modules/api_node.js";
import { Constants } from "./modules/constants.js";
import { MessageConstructor } from "./modules/message_constructor.js";
import { TwilioSms } from './modules/twilio_sms.js';
import { PopUpForm } from './modules/popup_form.js';
import { QuizNode } from './modules/quiz_node.js';
import { QuizForm } from './modules/quiz_form';


export const Modules = {
  PopulateWithItemRef,
  ObjectConstructor,
  GetItemByItemRef,
  PopulateWithDate,
  ObjectDestructor,
  PopulateElement,
  ItemConstructor,
  UpdateItemsApi,
  ItemDestructor,
  PopulateItems,
  GhElementNode,
  CreateItemApi,
  FileDuplicate,
  CompareItems,
  ObjectToItem,
  FaceDetector,
  ifCondition,
  CreateFiles,
  SmartInput,
  JSONScheme,
  Calculator,
  MergeItems,
  GetItems,
  Response,
  Request,
  Trigger,
  Filter,
  API,
  Constants,
  MessageConstructor,
  TwilioSms,
  PopUpForm,
  QuizNode,
  QuizForm
};
