angular.module('WebSocketModule', [])



/*=======================================================================================================*/
/*==========================================  WEBSOCKET CONTROLLER   ====================================*/
/*=======================================================================================================*/
//-- WebSocket service manage connections to each App
//-- We setup connection each time when user open new application.
//-- The connection starts from app_processor after appDataProcesingService.getApp compleated
.service('WebSocket', [function() {
    return gudhub.websocket
  // let apiUrl = cnfg.ws_url + '/ws/app/';
  // let ws;
  // let connected = false;
  // let queue = [];
  // let heartBeatTimeStemp = 10000000000000;//-- We dont realy know when customer deside to subscribe on App that is why Initial time stemp is Sat Nov 20 2286
  // const ALLOWED_HEART_BEAT_DELEY = 100000;//-- PROBLEM If browser is busy with rendering we don't receive messages from WebSockets
  // let firstHeartBeat = true;
  // let supportsWebSockets = 'WebSocket' in window || 'MozWebSocket' in window;
  //
  //
  //
  //
  // //******************** APP SUBSCRIBE *********************//
  // //-- we run it each time when user request an App
  // //-- it runse in appDataProcesingService.getApp() method
  // this.appSubscribe = function(app_id, token){
  //   let subscription = "token=" + token + "/~/app_id=" + app_id;
  //
  //   if(connected){
  //     ws.send(subscription);
  //     queue.push(app_id);
  //   } else queue.push(app_id);
  //
  // }
  //
  //
  //
  //
  // //******************** INIT WEBSOCKET *********************//
  // const initWebSocket = function() {
  //   ws = new WebSocket(apiUrl);//-- Setting Websocket Connection
  //
  //
  //   //------------ ON CONNECTION OPEN ------------//
  //   ws.onopen = async function() {
  //     let token = await authService.getToken();
  //     connected = true;
  //
  //     //------- SUBSCRIPTION on APPs  -------//
  //     queue.forEach(app_id =>{
  //       let subscription = "token=" + token + "/~/app_id=" + app_id;
  //       ws.send(subscription);
  //     })
  //   };
  //
  //
  //   //------------ ON ERROR ------------//
  //   ws.onerror = function(error) {
  //     ws.close();
  //   };
  //
  //
  //   //------------ ON CLOSE ------------ //
  //   ws.onclose= function(error) {
  //     connected = false;
  //     initWebSocket();//-- Setting Websocket Connection
  //   };
  //
  //
  //   //------------ ON MESSAGE ------------ //
  //   ws.onmessage = function(evt) {
  //
  //     //-- HEART BEAT
  //     if (evt.data.match(/HeartBeat/)){
  //       let hartBeatDelay = new Date().getTime() - heartBeatTimeStemp;
  //
  //       if( ALLOWED_HEART_BEAT_DELEY < hartBeatDelay){
  //         onConnectionLost(hartBeatDelay);
  //       }else{
  //         ws.send('HeartBeat');
  //         heartBeatTimeStemp = new Date().getTime();
  //       }
  //     }
  //
  //     //-- After first subscription we start checking WS connection
  //     if(firstHeartBeat){
  //       connectionChecker();
  //       firstHeartBeat = false;
  //     }
  //
  //     //-- MESSAGE
  //     if (evt.data.match(/[{}]/) ){
  //       let incomeMessage = JSON.parse( evt.data );
  //       let token = storage.getUser().accesstoken;
  //
  //       //-- We don't want to update storage of user who initieted the update. That is why we check if token from websocket is not the same as users token.
  //       if(incomeMessage.token != token){
  //         onMessage(incomeMessage);
  //       }
  //     }
  //
  //   };
  // }
  //
  //
  //
  //
  // //********************  START WS *********************//
  // if (supportsWebSockets){
  //   initWebSocket();
  // }
  //
  //
  //
  //
  // //******************** CHECK IF WS CONNECTION IS ACTIVE *********************//
  // //-- Each second we check if connection is stabile
  // const connectionChecker = function (){
  //   setInterval(async function() {
  //     let hartBeatDelay = new Date().getTime() - heartBeatTimeStemp;
  //     if( ALLOWED_HEART_BEAT_DELEY < hartBeatDelay){
  //       onConnectionLost(hartBeatDelay);
  //     }// else console.log('HeartBeat', hartBeatDelay);
  //   }, 1000);
  // }
  //
  //
  //
  //
  // //**************** ON MESSAGE CALLBACK ****************//
  // //-- We run this function each time when we receive message from websocket
  // //-- Each 'message' has 'api' parameters. We use it to determine which update method we should run
  // const onMessage = function(message){
  //   switch(message.api) {
  //     case '/items/update':
  //         console.log('/items/update - ', message);
  //         itemStorage.updateItemsInStorage(message.app_id, message.response);
  //         break;
  //     case '/items/add':
  //         console.log('/items/add - ', message);
  //         itemStorage.addItemsToStorage(message.app_id, message.response);
  //         break;
  //     case '/items/delete':
  //         console.log('/items/delete - ', message);
  //         itemStorage.deleteItemsFromStorage(message.app_id, message.response);
  //         break;
  //     case '/app/update':
  //         console.log('/app/update - ', message);
  //         appsStorage.updatingAppInStorage(message.response);
  //         break;
  //     case '/file/delete':
  //         console.log('file/delete - ', message);
  //         fileStorage.deleteFileFromStorage(message.response.file_id, message.app_id);
  //         break;
  //     case '/file/upload':
  //         console.log('file/upload - ', message);
  //         fileStorage.addFileToStorage(message.app_id, message.response);
  //         break;
  //     case '/file/formupload'://I'm not shure if we are using it (probably in contact form)
  //         console.log('file/formupload - ', message);
  //         break;
  //     case '/file/update'://I'm not shure if we are using it (probably in contact form)
  //         fileStorage.updateFileInStorage(message.response.file_id, message.response.app_id, message.response)
  //         console.log('file/update - ', message);
  //         break;
  //     case '/new/file/duplicate'://I'm not shure if we are using it (probably in contact form)
  //         fileStorage.addFilesToStorage(message.app_id, message.response)
  //         console.log('new/file/duplicate - ', message);
  //         break;
  //     default:
  //         console.warn('WEBSOCKETS is not process this API:', message.api);
  //   }
  // }
  //
  //
  //
  // //**************** ON CONNECTION LOST ****************//
  // const onConnectionLost = function(hartBeatDelay){
  //   console.log(' hartBeatDelay more then ALLOWED_HEART_BEAT_DELEY', hartBeatDelay);
  //   if(navigator.onLine){
  //     console.log('navigator online');
  //     authService.getVersion().then(version =>{
  //       console.log('version', version);
  //       ws.close();
  //       location.reload();
  //     });
  //   }else console.log('OFF-LINE', hartBeatDelay);
  // }



}]);
