import Rete from "rete";
import { NoEditControl } from "../controls/no_edit_control.js";
import { field_value, items } from "../controls/sockets.js";
import { ifNoConnections } from "../controls/utilities.js";

export class SmartInput extends Rete.Component {
  constructor() {
    super("SmartInput");
  }

  builder(node) {
    const outCurrentItem = new Rete.Output("outCurrentItem", "Current Item", items, true);
    const outSelectedItem = new Rete.Output("outSelectedItem", "Selected Item", items, true);
    const outNewItem = new Rete.Output("outNewItem", "New Item", items, true);
    const outValue = new Rete.Output("outValue", "Value", field_value, true);
    const cntr = new NoEditControl(
      this.editor,
      "text",
      false,
      this.getTemplate()
    );

    return node
      .addControl(cntr)
      .addOutput(outCurrentItem)
      .addOutput(outSelectedItem)
      .addOutput(outNewItem)
      .addOutput(outValue)
  }

  async worker(node, inputs, outputs, triggeredData, resolve,) {
    try {
      switch (triggeredData.type) {
        case "button":
          outputs["outCurrentItem"] = triggeredData.current_item;
          outputs["outSelectedItem"] = triggeredData.selected_items;
          break;
        case "input":
          outputs["outCurrentItem"] = triggeredData.current_item;
          outputs["outValue"] = [triggeredData.value];
          outputs["outSelectedItem"] = triggeredData.selected_items;
          break;
        case 'form':
        case 'list':
          outputs["outCurrentItem"] = triggeredData.current_item;
          outputs["outNewItem"] = triggeredData.new_item;
          outputs["outSelectedItem"] = triggeredData.selected_items;
          break;
        default:
          break;
      }
      if (ifNoConnections(node.outputs)) resolve(triggeredData.response);
    } catch (err) {
      reject(err);
    }
  }

  getTemplate() {
    return {
      name: "Smart Input",
      icon: "remote_add",
      constructor: "smart_input",
      data_model: {},
    };
  }

  getSettings() {
    return [];
  }
}
