import Rete from "rete";
import {NoEditControl} from "../controls/no_edit_control.js"
import { field_value, items } from "../controls/sockets.js";
import {ifNoConnections} from "../controls/utilities.js"


/*======================================================================================================|
|======================================   QUIZ NODE   ==================================================|
|=======================================================================================================|
|-- Quiz node is a triggers node and it works with "quiz_form" node in pair. Also it could work with other nodes too.
|-- We recomend you to use quiz from data_ref, in this way you can collect data after quiz is passed and save it to current Item
|
|-- The quiz node hase folowing outputs:
|
|           start - It pushes "start" value to runs a quiz_form
|           quizItem - This is an item where the quiz is created
|           currentItem - This is where quiz is shown. The thing is that quiz could be shown through data_ref so quizItem and currentItem will be different
*/


export class QuizNode extends Rete.Component {
  constructor(){
      super("Quiz Node");
  }

  builder(node) {
      var oldValue = new Rete.Output('start', "Start", field_value, true);
      var currentItem = new Rete.Output('currentItem', "Current Item", items, true);
      var quizItem = new Rete.Output('quizItem', "Quiz Item", items, true);
      var cntr = new NoEditControl( this.editor, 'text', false, this.getTemplate() );

      return node
          .addControl(cntr)
          .addOutput(oldValue)
          .addOutput(quizItem)
          .addOutput(currentItem);
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    outputs['start'] = "start";
    outputs['currentItem'] = [triggeredData.curent_item];
    outputs['quizItem'] = [triggeredData.quiz_item];

    if( ifNoConnections(node.outputs) )
      resolve(null);    
  }

  
  getTemplate() {
    return {
      name: 'Quiz Node',
      icon: 'check_circle',
      constructor: 'trigger', //-- if constructor is "node" then we can add it 
      data_model: {}
    }
  }


  getSettings(){
    return [];
  }
}