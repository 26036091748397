import { MainControl } from "./../controls/main_control.js";
import Rete from "rete";
import { field_value, items } from "./../controls/sockets.js";
import { ifNoConnections } from ".././controls/utilities.js";
import { act } from "./../../actions/actions.js";
import { GUDHUB_SETTINGS } from "../../config.js";

export class TwilioSms extends Rete.Component {
  constructor() {
    super("Twilio SMS");
  }

  builder(node) {
    const inpCaller = new Rete.Input('inpCaller', 'Caller ID', field_value);
    const inpRecipient = new Rete.Input('inpRecipient', 'Recipient', field_value);
    const inpMessage = new Rete.Input('inpMessage', 'Message', field_value);
    const inpImage = new Rete.Input('inpImage', 'Image', field_value);
    const cntr = new MainControl(
        this.editor,
        "text",
        false,
        node,
        this.getSettings(),
        this.getTemplate()
    );

    return node
        .addControl(cntr)
        .addInput(inpCaller)
        .addInput(inpRecipient)
        .addInput(inpMessage)
        .addInput(inpImage);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    const app_id = node.data.app_id;

    let message = {
      account_sid: node.data.sid,
      auth_token: node.data.token,
      caller_id: inputs.inpCaller[0][0],
      recipient: inputs.inpRecipient[0][0],
      messege: inputs.inpMessage[0][0]
    };

    if(inputs.inpImage.length > 0 && Boolean(inputs.inpImage[0][0])) {

      message.image = inputs.inpImage[0][0];

    }
    
    const response = await fetch(GUDHUB_SETTINGS.node_services_api_path + '/twilio/send/sms', { 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(message)
    });
      console.log(response);

    if (ifNoConnections(node.outputs)) {
      resolve(result);
    }
  }

  getTemplate() {
    return {
      name: "Twilio",
      icon: "speech_bubble",
      constructor: "node",
      data_model: {
        app_id: "",
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Settings",
              type: "header",
            },
            {
              type: "ghElement",
              property: "app_id",
              data_model: function (fieldModel) {
                return {
                  field_name: "Dest Application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              class: "option-column_500px",
              type: "ghElement",
              property: "sid",
              data_model() {
                return {
                  field_name: "Account SID",
                  name_space: "account_sid",
                  data_type: "text",
                };
              },
            },
            {
              class: "option-column_500px",
              type: "ghElement",
              property: "token",
              data_model() {
                return {
                  field_name: "Auth Token",
                  name_space: "auth_token",
                  data_type: "text",
                };
              },
            },
          ],
        ],
      },
    ];
  }
}
