import Rete from "rete";
import {NoEditControl} from "../controls/no_edit_control.js"
import {items, field_value} from "../controls/sockets.js"
import {ifNoConnections} from "../controls/utilities.js"





export class Trigger extends Rete.Component {
  constructor(){
      super("Trigger");
  }

  builder(node) {
      var createdItems = new Rete.Output('createdItems', "Created Items", items, true);
      var deletedItems = new Rete.Output('deletedItems', "Deleted Items", items, true);
      var updatedItems = new Rete.Output('updatedItems', "Updated Items", items, true);
      var updatedFile = new Rete.Output('updateFile', "Updated File", field_value, true);
      var updatedValue = new Rete.Output('updatedValue', "Updated Value", items, true);
      //var deletedItems = new Rete.Output('deletedItems', "Deleted Items", items, true);
      var cntr = new NoEditControl( this.editor, 'text', false, this.getTemplate() );

      return node
          .addControl(cntr)
          .addOutput(createdItems)
          .addOutput(deletedItems)
          .addOutput(updatedItems)
          .addOutput(updatedFile)
          .addOutput(updatedValue);
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    // outputs['updatedItems'] = [];
    // outputs['createdItems'] = [];
    //outputs['deletedItems'] = [];

    switch (triggeredData.api){
        case '/items/update':
          outputs['updatedItems'] = triggeredData.response;
          break;
        case '/file/update':
            outputs['updateFile'] = triggeredData.response.file_id.toString();
            break;
        case '/items/add':
            outputs['createdItems'] = triggeredData.response;
          break; 
        case '/items/delete':
            outputs['deletedItems'] = triggeredData.response;
          break;
        case '/values/update':
            outputs['updatedValue'] = triggeredData.response;
            break;
        default:

    }

    if( ifNoConnections(node.outputs) )
      resolve(triggeredData.response);    
  }

  
  getTemplate() {
    return {
      name: 'Trigger',
      icon: 'remote_add',
      constructor: 'trigger',
      data_model: {}
    }
  }


  getSettings(){
    return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
              {
                title: 'Default Settings',
                type: 'header'
              },
              {
                type: 'ghElement',
                property: 'app_id',
                data_model: function (fieldModel) {
                    return {
                        field_name: 'Application',
                        data_type: 'app',
                        data_model: {
                            current_app: false,
                            interpretation: [{
                                src: 'form',
                                id: 'with_text',
                                settings: {
                                    editable: 1,
                                    show_field_name: 1,
                                    show_field: 1
                                }
                            }]
                        }
                    };
                }
            },{
              type: 'html',
              onInit: function (settingScope,) {
                settingScope.$watch(function () {
                  return settingScope.fieldModel.app_id;
                }, function(newValue) {
                  settingScope.field_model.recipient.app_id = newValue;
                });
              },
              data_model: function (fieldModel) {
                return {
                  recipient:{
                    app_id: fieldModel.app_id
                  }
                };
              },
              control:
                '<gh-filter gh-filter-data-model="field_model" filter-list="fieldModel.filters_list" gh-mode="variable"></gh-filter>'
            }]]
      }]
  }
}