angular.module('ghUserSelectingModule', [
  'authorizationMod'
])
.directive('ghUserSelecting', ['authService', '$compile', function(authService, $compile) {
  var directive = {};

  directive.scope = {
    ghModel: '=',
    options: '=?ghDropdown',
    ghField: '=?',
    ghMode: '@',
    editable: '='
  };

  directive.controller = [ '$scope', '$element', '$attrs', function($scope, $element, $attrs) {

    if (angular.isUndefined($scope.options)) $scope.options = [];

    $scope.settings = {
      photo_size: $scope.ghField.data_model && $scope.ghField.data_model.photo_size ? $scope.ghField.data_model.photo_size : 64,
      display_type: $scope.ghMode || 'photo_full_name',
      url: ''
    };

    $scope.userData = null;


  let firstlyLoaded = true;

    $scope.$watch('ghModel', async function(userId, oldUserId) {
      if (userId) {

        if (firstlyLoaded || userId != oldUserId) {
          firstlyLoaded = false;
          $scope.userData = await authService.getUserById(userId)
          let url = $scope.ghField.data_model.photo_size > 255 ? $scope.userData.avatar_512 : $scope.userData.avatar_128;
          if (url) {
            $scope.settings.url = url.replace('http://gudhub.com', 'https://gudhub.com');
          } else {
            $scope.settings.url = null;
          }
        }
      } else {
        $scope.userData = null;
        $scope.settings.url = null;
      }
      const timer = setTimeout(() => {
        $scope.$digest();
        clearTimeout(timer)
      }, 0)

    });

    $scope.deleteUser = function () {
      $scope.ghModel = "";
    };

  }];

    directive.link = function (scope, element, attrs) {
      var showInput = scope.editable ? ' <div>' +
      '   <gh-input ng-show="!ghField.field_value" gh-field="ghField" ng-model="ghModel" autocomplete="usersList keyword" gh-dropdown="options" gh-data-type="user" size="large"></gh-input>' +
      '   <span ng-show="ghModel" ng-click="deleteUser()" class="edit-btn" gh-icon="cross 0893d2 30px normal"></span>'+
      ' </div>' : '';
      var template =
      '<div class="gh-user">' +
      ' <div ng-show="ghModel" ng-switch="settings.display_type">' +
      '     <div ng-switch-when="photo_full_name" class="gh-photo_full_name">'+
      '       <div class="photo" ng-show="!settings.url" gh-icon="user_round 0fb5ff {{settings.photo_size || 30}}px normal"></div>'+
      '       <img class="photo" ng-show="settings.url" style="height:{{settings.photo_size}}px" ng-src="{{settings.url}}" />'+
      '       <p class="name">{{userData.fullname}}</p>'+
      '     </div>'+
      '     <div ng-switch-when="photo">'+
      '       <div ng-show="!settings.url" gh-icon="user_round 0fb5ff 40px normal"></div>'+
      '       <img class="photo" ng-show="settings.url" style="height:{{settings.photo_size}}px" ng-src="{{settings.url}}" />'+
      '     </div>'+
      '     <div ng-switch-when="icon">'+
      '       <div class="photo" ng-show="settings.url" gh-icon="user_round 0fb5ff {{settings.photo_size}}px normal"></div>'+
      '     </div>'+
      '     <div ng-switch-when="little_photo" class="gh-photo_full_name one_line">'+
      '       <div class="photo" ng-show="!settings.url" gh-icon="user_round 0fb5ff {{settings.photo_size || 15}}px normal"></div>'+
      '       <img class="photo" ng-show="settings.url" style="height:40px" ng-src="{{settings.url}}" />'+
      '       <p class="name">{{userData.fullname}}</p>'+
      '     </div>'+
      '     <div ng-switch-when="custom_size" class="gh-photo_full_name one_line align_text_center">'+
      '       <div class="photo" ng-show="!settings.url" gh-icon="user_round 0fb5ff {{settings.photo_size || 15}}px normal"></div>'+
      '       <img class="photo" ng-show="settings.url" style="height:{{settings.photo_size}}px" ng-src="{{settings.url}}" />'+
      '       <p >{{userData.fullname}}</p>'+
      '     </div>'+
      '     <div ng-switch-default>'+
      '       <p class="name">{{userData.fullname}}</p>'+
      '     </div>'+
      ' </div>' + showInput +
      '</div>';

      var el = $compile(angular.element(template))(scope);
      element.append(el);
    };

  return directive;
}]);
