import Rete from "rete";
import { MainControl } from "./../controls/main_control.js";
import { ifNoConnections } from "./../controls/utilities.js";
import { items, field_value } from "./../controls/sockets.js";
import { act } from "./../../actions/actions.js";
import {
  awaitDebugger,
  DeferredPromise,
} from "../controls/automation_debugger";

export class ItemConstructor extends Rete.Component {
  constructor() {
    super("Item Constructor");
  }

  builder(node) {
    
    const outItems = new Rete.Output("outItems", "Items", items);
    const inpItems = new Rete.Input("inpItems", "Items", items);
    const inpItemID = new Rete.Input("inpItemID", "Item ID", field_value)
    const inpPush = new Rete.Input("inpPush", "PUSH", field_value);
    const inputFields = node.data.fields || [];
    const inputs = inputFields.map((field) => new Rete.Input(field.field_id, field.field_name, field_value, true));
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );

    inputs.forEach((input) => node.addInput(input));
    
    if(+node.data.push){
      node.addInput(inpPush);
    }

    return node.addControl(cntr).addInput(inpItems).addInput(inpItemID).addOutput(outItems);
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    const fieldsId = node.data.fields || [];
    const inputItems = inputs["inpItems"][0];
    const inpItemID = inputs["inpItemID"][0];
    const fieldsDataArray = [];


    console.log("inputs",inputs);

    fieldsId.forEach((field) => {
      inputs[field.field_id].forEach((connection, indx) =>  { 
        if(connection) {
          connection.forEach((input, index) => {
            const newField = {
              field_id: field.field_id,
              element_id: field.field_id,
              field_value: input,
            };
            if (fieldsDataArray[index]) {
              fieldsDataArray[index].push(newField);
            } else {
              fieldsDataArray.push([newField]);
            }
          });
        }
      });  
    });

    // Creating Items
    let result = fieldsDataArray.map((fieldsData) => ({
      item_id: inpItemID ? inpItemID[0] : 0,
      fields: fieldsData,
    }));

    //getting index of most Pupulated Item 
    let indexOfmostPupulatedItem = 0;
    result.forEach((item, index) => {
      if(result[indexOfmostPupulatedItem].fields.length < item.fields.length)
      indexOfmostPupulatedItem = index;
    });

    //Populating Empty fields with fields of most populated item
    if(+node.data.fill_empty_fields)
    result = result.map((item) => {
      console.log("Merged",act.mergeItems([item], [result[indexOfmostPupulatedItem]])[0]);
      return act.mergeItems([item], [result[indexOfmostPupulatedItem]])[0];
    });

    console.log("node.data", node.data);


    //If was passed to items constructor
    if (inputItems && inputItems.length) {
      console.log("inputItems && inputItems.length");
      const fieldsData = fieldsId.map((field) => ({
        field_id: field.field_id,
        element_id: field.field_id,
        field_value: inputs[field.field_id].filter((value) => {
            return value ? value.join(",") : null;
        }).join(",")
      }));

      result = inputItems.map((item) => {
        const newFields = item.fields.filter(
          (field) =>
            !fieldsData.find(
              (fieldData) => fieldData.field_id == field.field_id
            )
        );
        newFields.push(...fieldsData);
        return {
          ...item,
          fields: newFields,
        };
      });
    }

  
    //There could be PUSH state so in this case we send data when they was pushed only
    let sendData = +node.data.push ? (inputs["inpPush"][0] ? inputs["inpPush"][0][0] : false)  : true; 
    console.log("sendData", sendData);

    if(sendData )
      outputs["outItems"] = result;

    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) resolve(result);
  }

  getTemplate() {
    return {
      name: "Item Constructor",
      icon: "plus",
      constructor: "node",
      data_model: {
        app_id: "",
        push: "0",
        fill_empty_fields: "0",
        fields: [],
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Default Settings",
              type: "header",
            },
            {
              type: "ghElement",
              property: "app_id",
              data_model: function (fieldModel) {
                return {
                  field_name: "Application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: "ghElement",
              property: "push",
              data_model() {
                return {
                  field_name: "Send Constant By",
                  name_space: "send_constant_by",
                  data_type: "radio_button",
                  data_model: {
                    options: [
                      {
                        name: "PULL",
                        value: "0",
                        color: "#05b52c",
                      },
                      {
                        name: "PUSH",
                        value: "1",
                        color: "#ffa200",
                      }
                    ],
                    interpretation: [
                      {
                        src: "form",
                        id: "current_one",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: 'ghElement',
              property: 'fill_empty_fields',
              data_model: function () {
                  return {
                      field_name: 'Fill Empty Fields',
                      name_space: 'fill_empty_fields',
                      data_type: 'boolean',
                      data_model: {
                        tooltip: {
                          default: `<h3>Fill Empty Fields</h3>
                            Populating empty fields of all items with the fields of most populated item.`,
                          en: 'EN',
                          ua: 'UA'
                        }
                      }
                  };
              }
            }
          ],
          [
            {
              title: "Value Inputs",
              type: "header",
            },
            {
              type: "html",
              class: "option-column_750px",

              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "field_id",
                      type: "field",
                      prop_name: "Field",
                      data_model: function (option) {
                        return {
                          app_id: fieldModel.app_id,
                        };
                      },
                      display: true,
                    },
                    {
                      property: "field_name",
                      type: "text",
                      prop_name: "Field Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.fields" pattern="field_model.patterns" ></gh-option-table>',
            },
          ],
        ],
      },
    ];
  }
}
