import { MainControl } from "./../controls/main_control.js";
import Rete from "rete";
import { field_value, items } from "./../controls/sockets.js";
import { ifNoConnections } from "./../controls/utilities.js";
import { act } from "./../../actions/actions.js";

export class CreateFiles extends Rete.Component {
  constructor() {
    super("Create Files");
  }

  builder(node) {
    const inpItems = new Rete.Input("inpItems", "Items", items, true);
    const outItems = new Rete.Output("outItems", "Item", items);
    const inpFiles = new Rete.Input("inpFiles", "Files", field_value);
    const cntr = new MainControl(
        this.editor,
        "text",
        false,
        node,
        this.getSettings(),
        this.getTemplate()
    );

    return node
        .addControl(cntr)
        .addInput(inpItems)
        .addInput(inpFiles)
        .addOutput(outItems);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    const app_id = node.data.app_id;
    const field_id = node.data.field_id;
    const type = node.data.type
    const inpItems = inputs.inpItems || [];
    const inpFiles = inputs.inpFiles[0] || [];
    let result = [];

    for (const items of inpItems) {
      if (items[0]) {
        result = await act.createFiles(inpFiles, app_id, items[0].item_id, field_id, gudhub, type);
        outputs.outItems = result;
      }
    }

    if (ifNoConnections(node.outputs)) {
      resolve(result);
    }
  }

  getTemplate() {
    return {
      name: "Create Files",
      icon: "file",
      constructor: "node",
      data_model: {
        app_id: "",
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Default Settings",
              type: "header",
            },
            {
              type: "ghElement",
              property: "app_id",
              data_model: function (fieldModel) {
                return {
                  field_name: "Dest Application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: "ghElement",
              property: "field_id",
              onInit(settingScope, fieldModel) {
                settingScope.$watch(
                    function () {
                      return fieldModel.app_id;
                    },
                    function (newValue) {
                      settingScope.field_model.data_model.app_id = newValue;
                    }
                );
              },
              data_model(fieldModel) {
                return {
                  data_model: {
                    app_id: fieldModel.app_id,
                  },
                  field_name: "Field for file",
                  name_space: "field_id",
                  data_type: "field",
                };
              },
            },
            {
              type: "ghElement",
              property: "type",
              data_model() {
                return {
                  field_name: "File types",
                  data_type: "text_opt",
                  data_model: {
                    options: [
                      {
                        name: "base64",
                        value: "base64",
                      },
                      {
                        name: "URL",
                        value: "url",
                      },
                    ],
                  },
                };
              },
            },

          ],
        ],
      },
    ];
  }
}
