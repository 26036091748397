import Rete from "rete";
import { MainControl } from "./../controls/main_control.js";
import { ifNoConnections } from "./../controls/utilities.js";
import { items } from "./../controls/sockets.js";
import { act } from "./../../actions/actions.js";
import {
  awaitDebugger,
  DeferredPromise,
} from "../controls/automation_debugger";

export class MergeItems extends Rete.Component {
  constructor() {
    super("Merge Items");
  }

  builder(node) {
    const srcItems = new Rete.Input("srcItems", "Source items", items);
    const destItems = new Rete.Input("destItems", "Destination Items", items);
    const newItems = new Rete.Output("newItems", "New items", items);
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );
    node
      .addControl(cntr)
      .addInput(srcItems)
      .addInput(destItems)
      .addOutput(newItems);
    return node;
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    const srcItems = inputs["srcItems"][0] || [];
    const destItems = inputs["destItems"][0] || [];

    const result = gudhub.mergeItems(srcItems, destItems);
    outputs["newItems"] = result;

    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) {
      resolve(result);
    }
  }

  getTemplate() {
    return {
      name: "Merge Items",
      icon: "pile",
      constructor: "node",
      data_model: {
        app_id: "",
        fields: [],
      },
    };
  }

  getSettings() {
    return [];
  }
}
