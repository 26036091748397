import Rete from "rete";
import { MainControl } from "../controls/main_control.js";
import { ifNoConnections } from "../controls/utilities.js";
import { items, field_value } from "../controls/sockets.js";
import { awaitDebugger, DeferredPromise } from "../controls/automation_debugger";

export class QuizForm extends Rete.Component {
  constructor() {
    super("Quiz Form");
  }

  builder(node) {
    const start = new Rete.Input("start", "Start", field_value, true);
    const dynamicMessage = new Rete.Input("dynamicMessage", "Message", field_value);
    const message = new Rete.Output("message", "Message", field_value);
    node.data.promise = new DeferredPromise();
    const answers = node.data.answers || [];
    const outputs = answers.map(
      (answer, index) => new Rete.Output(answer.output_name, answer.output_name, field_value)
    );

    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );

    node.addControl(cntr)
        .addInput(start)
        .addOutput(message);

    if(node.data.dynamic_message) node.addInput(dynamicMessage);

        outputs.forEach((output) => node.addOutput(output));
    return node;
  }


  async worker(node, inputs, outputs, triggeredData, resolve, $injector) {
    const engene = triggeredData.quizEngene;
    const start =  (inputs['start'].filter(value => value)[0]) || null;
    let result = null;
    let waite = new DeferredPromise();
    let answersWithFunctions = [];

    
    //If we don't have a value on the "Start" input then we go to the next connected node
    if(!start) waite.resolve();
    



    //------------ Quiz FORM INIT ----------------//
    //If Dynamic Message turned on then we use messege from dynamic_message input
    let message = node.data.dynamic_message ? inputs['dynamicMessage'][0][0] : node.data.message;

    // Generating answers variants for the dialog
    node.data.answers.forEach(answer =>{
      answersWithFunctions.push({
        answer : answer.answer,
        output_name : answer.output_name,
        func : function(outputName, value) {
          waite.resolve();
          outputs[outputName] = [value];
          result = value;
          console.log("FUNCTION", value);
        }
      });
    });
    
    //We pass this function to stop quiz without unswering on questions
    let resolveForm = function(){
      waite.resolve();
    }


    //----- WAITE -----/
    //In order to stop execution of the nodes tree we use async/awaite construction
    if(start){
      engene.render(message, answersWithFunctions, resolveForm);
      await waite.get;
    }
    

    console.log("result",inputs, node.data.message);

    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) {
      resolve(result);
    }
  }

  getTemplate() {
    return {
      name: "Quiz Form",
      icon: "circular",
      constructor: "node",
      data_model: {
        dynamic_message: false,
        message: "",
        answers: [],
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Default Settings",
              type: "header",
              class: "option-column_500px"
            },
            {
              type: 'ghElement',
              property: 'dynamic_message',
              data_model: function () {
                  return {
                      field_name: 'Dynamic Message ',
                      name_space: 'dynamic_message',                                                                                    
                      data_type: 'boolean'
                  };
              }
            },
            {
              type: "ghElement",
              property: "message",
              showIf: '!dynamic_message',
              data_model(fieldModel) {
                return {
                  field_name: "Message",
                  data_type: "text",
                  data_model: {
                  },
                };
              },
            },
          ],
          [
            {
              title: "Answers",
              type: "header",
              class: "option-column_750px"
            },
            {
              type: "html",
              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "answer",
                      type: "text",
                      prop_name: "Text",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "output_name",
                      type: "text",
                      prop_name: "Output Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    }
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.answers" pattern="field_model.patterns" ></gh-option-table>',
            },
          ],
          
        ],
      }
    ];
  }
}
