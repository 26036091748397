import Rete from "rete";
import {MainControl} from "../controls/main_control.js"
import {items, field_value} from "../controls/sockets.js"
import {ifNoConnections} from "../controls/utilities.js"



export class PopulateElement extends Rete.Component {
  constructor(){
      super("Populate Element");
  }


  builder(node) {
      const itemOptions = ['inpItems',"Items", items];
      const valueOptions = ['inpValue',"Value", field_value]
      var inpItems = new Rete.Input(...(node.data.is_value_input ? valueOptions : itemOptions));
      var cntr = new MainControl( this.editor, 'text', false, node, this.getSettings(), this.getTemplate() );

      return node
          .addControl(cntr)
          .addInput(inpItems)  
  }


  async worker(node, inputs, outputs, triggeredData, resolve) {
      let model = node.data.model;
      let app_id = node.data.app_id;
      let keep_whole_data = node.data.keep_whole_data;
      let inputItems = inputs['inpItems'] && inputs['inpItems'][0] || [];
      let inpValue = inputs['inpValue'] && inputs['inpValue'][0] || [];

      //-- Populating items with data
      if(inputItems.length){
        resolve(node.data.output_value);
      }else if(inpValue.length){
          resolve(inpValue[0])
      }
  }

  getTemplate() {
    return {
      name: 'Populate Element',
      icon: 'table',
      constructor: 'node',
      data_model: {
        output_value: null
      }
    }
  }


  getSettings(){
    return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
              {
                title: 'Settings',
                type: 'header'
              },
                {
                    type: "ghElement",
                    property: "is_value_input",
                    data_model: function (fieldModel) {
                        return {
                            field_name: "Value input",
                            data_type: "boolean",
                        };
                    },
                },
              {
                type: 'ghElement',
                property: 'output_value',
                class: 'option-column_500px',
                showIf: '!is_value_input',
                onInit: function (settingScope) {
                  settingScope.field_model.data_model.app_id = settingScope.appId;
                  settingScope.field_model.data_model.field_id = settingScope.elementId;
                },
                data_model: function () {
                    return {
                        field_name: 'Output Value',
                        name_space: 'output_value',                                                                                    
                        data_type: 'element_ref',
                        data_model: {
                          app_id: '',
                          field_id: '',
                        }
                    };
                }
            }],
            ]
      }]
  }
}