
angular.module('filterItemModule', [])

.service('filterItem', ['$q', '$injector', 'GHConstructor', 'storage', 'PipeService',  function($q, $injector, GHConstructor, storage, PipeService) {


  // Return search option by select type
  function setSearchOption(optionType, optionList) {
    return optionList.find(function (option) {
      return option.id == optionType;
    }) || angular.copy(optionList[0]);
  }



  //========================================== CHECK IS DATA HAS FILTER ==============================================//
  /*--------------------------------------------------------------------------------------------------------------------
   |  Params:
   |    'dataType' - String (require). Data type.
   |  Return dataFiltering object.
   |------------------------------------------------------------------------------------------------------------------*/

  // Return dataFiltering object.
  function getDataFilteringObj(dataType) {
    var deferred = $q.defer();

    GHConstructor.getInstance(dataType).then(function (data) {
      if (angular.isDefined(data.filter)){
        deferred.resolve(data);
      }
      deferred.reject();
    }, function () {
      deferred.reject();
    }
    );
    return deferred.promise;
  }


  //==================================== GET FILTER MODEL FOR ADVANCED FILTER =========================================//
  /*--------------------------------------------------------------------------------------------------------------------
   |  Needs to load data before data type ot load yet
   |  Params:
   |    'field' - Object of field (require).
   |      {
   |        .......,
   |        field_id: ''
   |        data_type: ''
   |        ........
   |      }
   |    'searchTypeFilter' - Not required. If it set, returns filter object by selected type: 'equal', 'value', .....
   |                        Default: first search option in search options list
   |
   |  Return filter model. Also needs from other modules
   |------------------------------------------------------------------------------------------------------------------*/

  this.getFilter = function(field, searchTypeFilter) {
    var deferred = $q.defer();

    var filter = {
      field_id: field.field_id,
      data_type: field.data_type,
      valuesArray: []
    };

    getDataFilteringObj(field.data_type).then(async function (data) {
      var searchOptions = data.filter.getSearchOptions(field);
      if(searchOptions && typeof searchOptions.then === 'function'){
        searchOptions = await searchOptions;
      }
      if(searchTypeFilter){
        searchOptions = searchOptions.filter(function (option) {
          return option.id == searchTypeFilter;
        });
      }
      if(searchOptions.length){
        deferred.resolve(angular.extend(filter, {search_type: searchTypeFilter || searchOptions[0].id}));
      }
      deferred.reject();
    }, function () {
      deferred.reject();
    });

    return deferred.promise;
  };




  //========================================== GET FILTER FOR GH-FILTER ==============================================//
  /*--------------------------------------------------------------------------------------------------------------------
   |  Needs to load data before data type ot load yet
   |  Params:
   |    'field' - Object of field (require).
   |      {
   |        .......,
   |        data_type: ''
   |        ........
   |      }
   |    'defaultSearchOption' - Not required. If it set, returns search option by type ('equal', 'value', .....)  in
   |                        selected_search_option.
   |                        Default: first option in search options list
   |
   |------------------------------------------------------------------------------------------------------------------*/
  this.getNewFilter = function(field, defaultSearchOption) {

    var deferred = $q.defer();

    var filter = {
      dropdown_values: [], /*-- here we keep list of dropdown options, if filter's input has ones,*/
      search_options: [], /*-- List of search options of Filter, is used for dropdown list when you click on search options. It's fills from "getFilterOptions" method from filters_options_tempates.js*/
      selected_search_option: null /*-- Here we save selected search object*/
    };

    getDataFilteringObj(field.data_type).then(async function (data) {
      filter.search_options = data.filter.getSearchOptions(field);
      /* check for dropdown values for current datatype*/
      if(filter.search_options && typeof filter.search_options.then === 'function'){
        filter.search_options = await filter.search_options;
      }
      
      if (field.data_model && field.data_model.options) {
        filter.dropdown_values = field.data_model.options;
      } else {
        filter.dropdown_values = data.filter.getDropdownValues();
      }

      // Set default search option type
      filter.selected_search_option =
        defaultSearchOption ? setSearchOption(defaultSearchOption, filter.search_options) : angular.copy(filter.search_options[0]);

      deferred.resolve(filter);

    }, function () {
      deferred.reject();
    });

    return deferred.promise;
  };

  
}])