angular.module('tableServiceModule', [])

.service('TableService', ['$rootScope', '$q', '$injector', '$filter', 'ActionService', 'PipeService', 'GHConstructor', function($rootScope, $q, $injector, $filter, ActionService, PipeService, GHConstructor) {

  // ---------------------- Return true/false item select ----------------------
  this.isItemSelected = function(appId, itemId) {
    var array = ActionService.getSelectedTableItems(appId);
    if (array !== null) {
      array = array.items.map(function(obj) {
        return obj.item_id;
      });
      return array.indexOf(itemId) != -1;
    } else {
      return false;
    }
  };




  // ---------------------------- Select item ----------------------------------
  this.selectItem = function(event, appId, item) {
    event.preventDefault();
    event.stopPropagation();
    ActionService.selectedTableItems(appId, item);
  };

  // ---------------------- Merge and filter field list ------------------------
  this.mergeFieldLists = function(fieldsToView, fieldList){
	  var fieldsToViewCopy = angular.copy(fieldsToView);
    var fieldListCopy = angular.copy(fieldList);


    var existingFieldsIds = fieldListCopy.map(function (field) {
      return field.field_id;
    });

    if(!fieldListCopy.length){
      return [];
    }

    return fieldListCopy.reduce(function(viewArray, currentField){

      var positionInViewArray = null;
      var props = viewArray.find(function(view, index){
        if(currentField.field_id == view.field_id){
          positionInViewArray = index;
          return true;
        }
        return false;
      });

      if(!props){

        var defaultIntrpr = currentField.data_model && currentField.data_model.interpretation ? currentField.data_model.interpretation.find(function (intrpr) {
          return intrpr.src == 'table';
        }) || {settings:{ show_field: 1}} : {settings:{ show_field: 1}};

        if(defaultIntrpr.settings.show_field){
          viewArray.push(angular.merge({show: 1}, defaultIntrpr, currentField));
        }

      } else{
        angular.merge(viewArray[positionInViewArray], currentField);
      }

      return viewArray;
    }, fieldsToViewCopy).filter(function (field) {
      return field.show && existingFieldsIds.indexOf(field.field_id) != -1;
    });
  };





  // ---------------------- Create field list to view in table settings ------------------------
  this.createFieldsListToView = function(appId, tableSettingsFieldListToView){
    
    var deferred = $q.defer();

    var scope = $rootScope.$new();
    var columnsToView = [];

    PipeService.on('gh_models_get', {app_id: appId}, function modelPipe(event, fieldList) {
      
      // this array needs to check if field delete, and when delete if from colums to view
      var actualFieldIds = fieldList.map(function (field) {
        return +field.field_id;
      });
      // ---------------------------- Merge two field_list ------------------------------------------
      if(tableSettingsFieldListToView.length !== 0){
        // Delate field from tablelist then not exist in list from pipe
        var correctFieldList = [];
        tableSettingsFieldListToView.forEach(function(elementFromTable, index){  
          var fieldOnPipe = fieldList.find(function(element){
            return elementFromTable.field_id == element.field_id;
          })
          
          if(fieldOnPipe){
            // Change list to correct view for table
            var show_field =  fieldOnPipe.data_model.interpretation.find(function (intrpr){
              return intrpr.src == 'table';
            })
            if(!show_field){
              var show_field = {settings:{show_field: 1}}
            }
            correctFieldList.push({
              field_id: fieldOnPipe.field_id,
              show: elementFromTable.show,
              width: elementFromTable.width ? elementFromTable.width : "150px"
            })
          }
          if(index+1 == tableSettingsFieldListToView.length){
            checkFiledFromPipe(correctFieldList)
          }

        });  
      }else{
        checkFiledFromPipe(tableSettingsFieldListToView);
      }
      // Add field to table list then exist in list from pipe
      function checkFiledFromPipe (correctFieldList){
        var counter = 0
        var promises = [];
        fieldList.forEach(function(elemetFromPipe, index) {
          var correctFiel = correctFieldList.find(function(elemet){
            return elemetFromPipe.field_id == elemet.field_id;
          })
          
          if(!correctFiel){
            GHConstructor.getInstance(elemetFromPipe.data_type).then(function (data) {
                var template = data.getTemplate()
                var show_field =  template.model.data_model.interpretation.find(function (intrpr){
                  return intrpr.src == 'table';
                })
                if(!show_field){
                  show_field = {settings:{show_field: 1}}
                }
                correctFieldList.push({
                  field_id: elemetFromPipe.field_id,
                  show: show_field.settings.show_field,
                });

                if(counter == fieldList.length - 1){
                  deferred.resolve(correctFieldList);
                }
                counter++       
              }, function errorCallback(result) {
                counter++ 
             })
          }else{

            if(counter == fieldList.length - 1){
              deferred.resolve(correctFieldList);
            }
            counter++ 
          }
       
        });
      }

      PipeService.destroy('gh_models_get', {app_id: appId}, modelPipe);
    }, scope).emit('gh_models_get', {}, {app_id: appId});

    return deferred.promise;

  };


  // ---------------------- Create field list to view in table settings ------------------------
  this.createFieldsListToViewWithDataType = function(appId, tableSettingsFieldListToView){

    var deferred = $q.defer();

    var scope = $rootScope.$new();
    var columnsToView = [];
    
    PipeService.on('gh_models_get', {app_id: appId}, function (event, fieldList) {

      // this array needs to check if field delete, and when delete if from colums to view
      var actualFieldIds = fieldList.map(function (field) {
        return +field.field_id;
      });

      // ---------------------------- Merge two field_list ------------------------------------------
      columnsToView = fieldList.reduce(function (viewColumsList, currentField) {

        var fieldPosition = null;
        var field = viewColumsList.find(function (view, index) {
          if(currentField.field_id == view.field_id){
            fieldPosition = index;
            return true;
          }
          return false;
        });

        if (!field) {
          // Get default view settings from defaulr data_model to current 'table' interpretation
          var defaultIntepretation =
            ($injector.get(currentField.data_type)).getTemplate().model.data_model.interpretation.find(function (intrpr) {
              return intrpr.src == 'table';
            }) || {settings:{show_field: 1}};

          viewColumsList.push({
            field_id: currentField.field_id,
            data_type: currentField.data_type,
            show: defaultIntepretation.settings.show_field
          });
        }

        return viewColumsList;
      }, tableSettingsFieldListToView).filter(function (field) {
        return actualFieldIds.indexOf(field.field_id) != -1;
      });

      // Delete scope
      //scope.$destroy();

      deferred.resolve(columnsToView);

    }, scope).emit('gh_models_get', {}, {app_id: appId});

    return deferred.promise;

  };

  return this;
}]);
