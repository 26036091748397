import Rete from "rete";
import {MainControl} from "./../controls/main_control.js"
import {ifNoConnections} from "./../controls/utilities.js"
import {items, field_value} from "./../controls/sockets.js"
import {act} from "./../../actions/actions.js"
import {awaitDebugger, DeferredPromise} from "../controls/automation_debugger";






export class Filter extends Rete.Component {
    constructor(){
        super("Filter");
    }

    builder(node) {
        var outItems = new Rete.Output('outItems', "Items", items);
        var outQuantity = new Rete.Output('outQuantity', "Quantity", field_value);
        const inputFields = node.data.fields || [];
        const inputs = inputFields.map(
            (field) => new Rete.Input(field.field_id, field.field_name, field_value)
        ); 
        node.data.promise = new DeferredPromise();
        var inpItems = new Rete.Input('inpItems',"Items", items);
        var cntr = new MainControl( this.editor, 'text', false, node, this.getSettings(), this.getTemplate(),node.data.promise );
        
        inputs.forEach((input) => node.addInput(input));
        return node
            .addControl(cntr)
            .addInput(inpItems)
            .addOutput(outItems)
            .addOutput(outQuantity);

    }

    async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
        let app_id = node.data.app_id;
        const fieldsId = node.data.fields || [];
        let filters_list = JSON.parse(JSON.stringify(node.data.filters_list));
        let filter_variable = node.data.filter_variable 
        let items = inputs['inpItems'][0];
        fieldsId.forEach((field) => {
            const values = inputs[field.field_id] && inputs[field.field_id][0] || ['undefined'];
                const filter = {
                    ...field.filters_list,
                    valuesArray: values
                };
                filters_list.push(filter)
        });
        let filteredItems = [];
        //-- Filltering
        if(filter_variable) {
            for (const responseItem of triggeredData.response || []) {
                const modifiedFiltersList = await gudhub.prefilter(filters_list, triggeredData.app_id, responseItem.item_id);
                const newItems = gudhub.filter(items, [...modifiedFiltersList, ...filters_list]);
                filteredItems.push(...newItems)
                outputs['outItems'] = newItems;
                outputs['outQuantity'] = [newItems.length];
            }
        }else {
            filteredItems = gudhub.filter(items, filters_list);

            outputs['outItems'] = filteredItems;
            outputs['outQuantity'] = [filteredItems.length];
        }
        //-- checking if there are output connections
        //-- If there no outputs then we stop proccesing
        await awaitDebugger(node, inputs, outputs);
        if( ifNoConnections(node.outputs) )
            resolve(filteredItems);

    }


    getTemplate() {
        return {
            name: 'Filter',
            icon: 'filter',
            constructor: 'node',
            data_model: {
                app_id: '',
                filters_list: []
            }
        }
    }


    getSettings(){
        return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
                {
                    title: 'Default Settings',
                    type: 'header'
                },
                {
                    type: 'ghElement',
                    property: 'app_id',
                    data_model: function (fieldModel) {
                        return {
                            field_name: 'Application',
                            data_type: 'app',
                            data_model: {
                                current_app: false,
                                interpretation: [{
                                    src: 'form',
                                    id: 'with_text',
                                    settings: {
                                        editable: 1,
                                        show_field_name: 1,
                                        show_field: 1
                                    }
                                }]
                            }
                        };
                    }
                },{
                    type: 'ghElement',
                    property: 'filter_variable',
                    data_model: function () {
                        return {
                            field_name: 'Filter Variable',
                            name_space: 'filter_variable',
                            data_type: 'boolean',
                        };
                    },
                },{
                    type: 'html',
                    onInit: function (settingScope,) {
                        settingScope.$watch(function () {
                            return settingScope.fieldModel.app_id;
                        }, function(newValue) {
                            settingScope.field_model.recipient.app_id = newValue;
                        });
                    },
                    data_model: function (fieldModel) {
                        return {
                            recipient:{
                                app_id: fieldModel.app_id
                            }
                        };
                    },
                    control:
                        '<gh-filter gh-filter-data-model="field_model" filter-list="fieldModel.filters_list" gh-mode="variable"></gh-filter>'
                }],
                [
                    {
                        title: "Value Inputs",
                        type: "header",
                    },
                    {
                        type: "html",
                        class: "option-column_750px",

                        data_model: function (fieldModel) {
                            return {
                                patterns: [
                                    {
                                        property: "field_id",
                                        type: "field",
                                        prop_name: "Field",
                                        data_model: function (option) {
                                            return {
                                                app_id: fieldModel.app_id,
                                            };
                                        },
                                        display: true,
                                    },
                                    {
                                        property: "field_name",
                                        type: "text",
                                        prop_name: "Field Name",
                                        data_model: function (option) {
                                            return {};
                                        },
                                        display: true,
                                    },
                                    {
                                        property: "filters_list",
                                        type: "html",
                                        prop_name: "Search Type",
                                        data_model: function (option) {
                                            return {};
                                        },
                                        display: true,
                                        control: `<gh-filter-search gh-model="option[pattern.property]" field-id="option.field_id" app-id="${fieldModel.app_id}"/>`
                                    },
                                ],
                            };
                        },
                        control:
                            '<gh-option-table items="fieldModel.fields" pattern="field_model.patterns" ></gh-option-table>',
                    },
                ]]
        }]
    }
}
