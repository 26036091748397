import Rete from "rete";
import { MainControl } from "../controls/main_control.js";
import { object, field_value } from "../controls/sockets.js";
import { ifNoConnections } from "../controls/utilities.js";

export class JSONScheme extends Rete.Component {
  constructor() {
    super("JSON Scheme");
  }

  builder(node) {
    const inputsData = node.data.inputs || [];
    const inputs = inputsData.map(
      (input) =>
        new Rete.Input(input.key.toString(), input.input_name, field_value)
    );
    const output = new Rete.Output("outObject", "Object", object);
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate()
    );
    inputs.forEach((input) => node.addInput(input));
    return node.addControl(cntr).addOutput(output);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, reject) {}

  getTemplate() {
    return {
      name: "JSON Scheme",
      icon: "json",
      constructor: "node",
      data_model: {
        scheme: [],
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Scheme Settings",
              type: "header",
            },
            {
              type: "ghElement",
              property: "data_model.scheme",
              onInit: function (settingScope) {
                settingScope.$watch(
                  function () {
                    return settingScope.fieldModel.inputs;
                  },
                  function (newValue = []) {
                    settingScope.field_model.data_model.filterDropdownOptions = newValue.map(
                      ({ input_name }) => ({
                        name: input_name,
                        value: input_name,
                      })
                    );
                  },
                  true
                );
              },
              data_model(fieldModel) {
                const options = (fieldModel.inputs || []).map(
                  ({ input_name }) => ({
                    name: input_name,
                    value: input_name,
                  })
                );
                return {
                  field_name: "Scheme",
                  data_type: "jsonConstructor",
                  data_model: {
                    scheme: fieldModel.scheme,
                    filterDropdownOptions: options,
                    options,
                    interpretation: [
                      {
                        src: "form",
                        id: "editor",
                        settings: {
                          editable: 1,
                          show_field_name: 0,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
          ],
          [
            {
              title: "Inputs Variables",
              type: "header",
            },
            {
              type: "html",
              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "input_name",
                      type: "text",
                      prop_name: "Input Socket Name",
                      data_model: function (option) {
                        option.ghAppId = fieldModel.app_id;
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "key",
                      prop_name: "key",
                      type: "number",
                      data_model: function (option) {
                        return {};
                      },
                      getMaxValue: function () {
                        fieldModel.max_value = fieldModel.max_value || 1;
                        return fieldModel.max_value;
                      },
                      setMaxValue: function (maxValue) {
                        fieldModel.max_value = maxValue;
                      },

                      display: false,
                      generate_dynamic: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.inputs" pattern="field_model.patterns"></gh-option-table>',
            },
          ],
        ],
      },
    ];
  }
}
