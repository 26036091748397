import Rete from "rete";
import {MainControl} from "./../controls/main_control.js"
import {items, field_value} from "./../controls/sockets.js"
import {ifNoConnections} from "./../controls/utilities.js"
import {act} from "./../../actions/actions.js"
import {awaitDebugger, DeferredPromise} from "../controls/automation_debugger";





export class GetItemByItemRef extends Rete.Component {

  constructor(){
      super("Get Item By Item Ref");
  }

  builder(node) {
    var outItems = new Rete.Output('outItems', "Items", items);
    var inpItems = new Rete.Input('inpItems',"Items", items, true);
    var inpItemRf = new Rete.Input("inpItemRf", "Item ID", field_value, true);
    node.data.promise = new DeferredPromise();
    var cntr = new MainControl( this.editor, 'text', false, node, this.getSettings(), this.getTemplate(),  node.data.promise );

    return node
        .addControl(cntr)
        .addInput(inpItems)
        .addInput(inpItemRf)
        .addOutput(outItems);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    let field_id = node.data.item_ref_field_id;
    let receivedItems = [];
    
    //-- Receiving items from item input
    for (const inputItems of inputs["inpItems"]) {
      if(inputItems){
        //-- Getting item by item reference value
        receivedItems = await act.itemGetByItemRef(
            inputItems,
            field_id,
            gudhub,
            'items'
        );
        outputs["outItems"] = receivedItems;
      }
    }

    //-- Receiving items from field_value input
    for (const inputItems of inputs["inpItemRf"]) {
      if(inputItems){
        //-- Getting item by item reference value
        receivedItems = await act.itemGetByItemRef(
            inputItems,
            field_id,
            gudhub,
            'values'
        );
        outputs["outItems"] = receivedItems;
      }
    }
    //-- checking if there are output connections
    //-- If there no outputs then we return value
      await awaitDebugger(node, inputs, outputs);
    if( ifNoConnections(node.outputs) )
      resolve(receivedItems);
  }

  getTemplate() {
    return {
      name: 'Get Item By Item Ref',
      icon: 'back_btn',
      constructor: 'node',
      data_model: { 
        item_ref_app_id: '',
        item_ref_field_id: ''
      }
    }
  }

  getSettings(){
    return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
              {
                title: 'Default Settings',
                type: 'header'
              },{
                type: 'ghElement',
                property: 'item_ref_app_id',
                data_model: function (fieldModel) {
                    return {
                        field_name: 'Application',
                        data_type: 'app',
                        data_model: {
                            current_app: false,
                            interpretation: [{
                                src: 'form',
                                id: 'with_text',
                                settings: {
                                    editable: 1,
                                    show_field_name: 1,
                                    show_field: 1
                                }
                            }]
                        }
                    };
                }
            },{
              type: 'ghElement',
              property: 'item_ref_field_id',
              onInit: function (settingScope) {
                settingScope.$watch(function () {
                  return settingScope.fieldModel.item_ref_app_id;
                }, function(newValue) {
                  settingScope.field_model.data_model.app_id = newValue
                });
              },
              data_model: function (fieldModel, appId) {
								return {
									data_model: {
										app_id: fieldModel.item_ref_app_id
									},
									field_name: 'Field',
									name_space: 'Field',
									data_type: 'field'
								};
							},
            }]]
      }]

  }
}