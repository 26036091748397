import { MainControl } from "./../controls/main_control.js";
import Rete from "rete";
import { field_value } from "./../controls/sockets.js";
import { ifNoConnections } from "./../controls/utilities.js";
import {awaitDebugger, DeferredPromise} from "../controls/automation_debugger";

export class Calculator extends Rete.Component {
  constructor() {
    super("Calculator");
  }

  builder(node) {
    const inputs = node.data.inputs || [];
    const outItems = new Rete.Output("outValue", "Value", field_value);
    const inpSockets = inputs.map(
        (input) =>
            new Rete.Input(input.key.toString(), input.field_name, field_value)
    );
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
        this.editor,
        "text",
        false,
        node,
        this.getSettings(),
        this.getTemplate(),
        node.data.promise
    );
    inpSockets.forEach((inpSocket) => node.addInput(inpSocket));
    return node.addControl(cntr).addOutput(outItems);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    const calculations = node.data.calculations || "";
    let results = [];
  
    const calcData = calculations
      .split(",")
      .map((value) => {
        if (/^[a-z]+$/gi.test(value)) {
          if (inputs[value][0] && inputs[value][0][0]) {
            return inputs[value][0][0];
          } else {
            return "0";
          }
        } else if (/^\d+/g.test(value)) {
          return parseFloat(value);
        }
        return value;
      })
      .join(" ");

    try {
      results = [eval(calcData)];
      outputs.outValue = results;
    } catch (err) {
      console.error("Calc Node Expression: ", calcData);
      console.error(err.message);
      outputs.outValue = results;
    }

    outputs.outValue = results;

    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) resolve(results);
  }

  getTemplate() {
    return {
      name: "Calculator",
      icon: "circular",
      constructor: "node",
      data_model: {
        app_id: "",
      },
    };
  }

  getSettings() {
    const operators = [
      {
        name: "+",
        value: "+",
      },
      {
        name: "-",
        value: "-",
      },
      {
        name: "/",
        value: "/",
      },
      {
        name: "*",
        value: "*",
      },
      {
        name: "^",
        value: "^",
      },
      {
        name: "(",
        value: "(",
      },
      {
        name: ")",
        value: ")",
      },
    ];
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Default Settings",
              type: "header",
            },
            {
              type: "html",
              class: "option-column_500px",
              data_model(fieldModel) {
                return {
                  patterns: [
                    {
                      property: "field_name",
                      type: "text",
                      prop_name: "Field Name",
                      data_model(option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "key",
                      prop_name: "key",
                      type: "number",
                      data_model(option) {
                        return {};
                      },
                      getMaxValue() {
                        fieldModel.max_value = fieldModel.max_value || "A";
                        return fieldModel.max_value;
                      },
                      setMaxValue(maxValue) {
                        fieldModel.max_value = maxValue;
                      },

                      display: false,
                      generate_dynamic: true,
                      dynamic_type: "letter",
                    },
                  ],
                };
              },
              control:
                  '<gh-option-table items="fieldModel.inputs" pattern="field_model.patterns"></gh-option-table>',
            },
          ],
          [
            {
              title: "Calculation",
              type: "header",
            },
            {
              type: "ghElement",
              property: "additional_values",
              data_model(fieldModel) {
                return {
                  field_name: "Additional values",
                  data_type: "text",
                  data_model: {
                    multiple_value: true,
                  },
                };
              },
            },
            {
              type: "ghElement",
              property: "calculations",
              onInit(settingScope, fieldModel) {
                settingScope.$watch(
                    function () {
                      return fieldModel;
                    },
                    function (newValue) {
                      const inputs = newValue.inputs || [];
                      const options = inputs.map((input) => ({
                        name: input.field_name,
                        value: input.key.toString(),
                      }));
                      let addValues = [];
                      if (fieldModel.additional_values) {
                        addValues = fieldModel.additional_values
                            .split(",")
                            .map((value) => ({
                              name: value,
                              value,
                            }));
                      }
                      settingScope.field_model.data_model.options.length = 0;
                      settingScope.field_model.data_model.options.push(
                          ...options,
                          ...addValues,
                          ...operators
                      );
                    },
                    true
                );
              },
              data_model(fieldModel) {
                const inputs = fieldModel.inputs || [];
                const options = inputs.map((input) => ({
                  name: input.field_name,
                  value: input.key,
                }));
                return {
                  field_name: "Calculation",
                  data_type: "text_opt",
                  data_model: {
                    multiple_value: true,
                    multiple_selection: true,
                    options: options.concat(operators),
                  },
                };
              },
            },
          ],
        ],
      },
    ];
  }
}
