


angular.module('ghCalculatorModule', [])

.directive('ghCalculator', ['calculatorService', '$mdDialog', function(calculatorService, $mdDialog) {
  var directive = {};

  directive.scope = {
    expression: '=',
    arguments: '=',
    ghAppId: '@'
  };

  directive.controller = ['$scope', function($scope) {
    
    Object.defineProperty($scope, 'expressionProxy', {
      get: function() {
        return calculatorService.insertArgs(this.expression, this.arguments);
      },
      set: function(newValue) {
        this.arguments = calculatorService.getFnArgs(newValue);
        this.expression = calculatorService.removeArgs(newValue);
        calculatorService.convertToUI(calculatorService.insertArgs(this.expression, this.arguments)).then(function(res) {
          $scope.modifiedExpression = res;
        });
      }
    });

    calculatorService.convertToUI(calculatorService.insertArgs($scope.expression, $scope.arguments)).then(function(res) {
      $scope.modifiedExpression = res;
    });

    $scope.deleteLastSymbol = function() {
      if (/[A-Z_]+\([^+\-\/\*]*\)$/.test(calculatorService.insertArgs($scope.expression, $scope.arguments))) {
        $scope.expressionProxy = $scope.expressionProxy.replace(/[A-Z_]+\([^+\-\/\*]*\)$/, '');
      } else {
        $scope.expressionProxy = $scope.expressionProxy.slice(0, $scope.expressionProxy.length - 1);
      }
    };

    $scope.clear = function() {
      $scope.expressionProxy = '';
    };

    $scope.showFieldDialog = function() {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'app', 'excludeField', function($scope, $mdDialog, app, excludeField) {
          $scope.app_id = app;
          $scope.excludeField = excludeField;
          $scope.addField = function() {
            if ($scope.fieldToInsert) {
              $mdDialog.hide('FIELD(' + angular.toJson({field_id: $scope.fieldToInsert, app_id: app, interpretation_type: $scope.interpretationType}) + ')');
            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/field_calc.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {app: $scope.$parent.appId, excludeField: $scope.$parent.fieldModel.field_id}
      })
      .then(function(response) {
          $scope.expressionProxy += response || '';
      });
    };
    $scope.showConditionDialog = function(){
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'app', 'excludeField', function($scope, $mdDialog, app, excludeField) {
          $scope.condition = {};
          $scope.modeCondition = 1;
          $scope.app_id = app;
          
          $scope.condition.items = [];
          $scope.decorator = {
            field_value: 1,
            data_model:
            {
              options: [
                {
                  name: 'Field If Value',
                  value: 0
                }, {
                  name: 'Value',
                  value: 1
                }
              ],
              interpretation: [{
                src: 'form',
                id: 'default',
                settings:{
                  editable: 1,
                  show_field_name: 0,
                  show_field: 1
                }
              }]

            },
            data_type: 'text_opt'
          }
          $scope.defaultValue = 1;
          $scope.$watch('modeCondition', function(n){
            if(n == 1){
              $scope.condition.patterns=[{
                property: 'value',
                prop_name: 'value',
                type: 'number',
                data_model:function(option){
                  return {};
                },
                display: true,
              },{
                property: 'filters_list',
                prop_name: 'Conditions',
                type: 'filter_table',
                display: true,
                data_model:function(option, scope) {
                  scope.appId = app;
                  
                  option.filters_list ? scope.filters_list = option.filters_list : scope.filters_list = option.filters_list = [];
                  return {
                      mode: 'variable'
                  }
                },
              }]
            }
            if(n == 0){
              $scope.condition.patterns=[{
                property: 'value',
                prop_name: 'Field value',
                type: 'field',
                data_model:function(option){
                  return {
                      app_id: $scope.app_id
                  };
                },
                display: true,
              },{
                property: 'filters_list',
                prop_name: 'Conditions',
                type: 'filter_table',
                display: true,
                data_model:function(option, scope) {
                  scope.appId = app;
                  
                  option.filters_list ? scope.filters_list = option.filters_list : scope.filters_list = option.filters_list = [];
                  return {
                      mode: 'variable'
                  }
                },
              }]
            }
          })
        
      
          $scope.excludeField = excludeField;
          $scope.addIf = function() {
              let arg = {
                app_id: app,
                defaultValue: $scope.defaultValue,
                items: $scope.condition.items,
                mode: $scope.modeCondition
              }
              $mdDialog.hide('IF(' + angular.toJson(arg) + ')');
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/condition_calc.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {app: $scope.$parent.appId, excludeField: $scope.$parent.fieldModel.field_id}
      })
      .then(function(response) {
          $scope.expressionProxy += response || '';
      });
    };

    $scope.insertItemsCount = function() {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'excludeField', function($scope, $mdDialog, excludeField) {
          $scope.excludeField = excludeField;
          $scope.sumFilters = [];
          $scope.itselfFilter = {active: false, field_id: []};

          $scope.filterDataModel = {
            recipient: {
              app_id: null
            }
          };

          $scope.field = {data_model: {app_id: null}};

          $scope.$watch('appToInsert', function(n, o) {
            if (n != o) {
              $scope.filterDataModel.recipient.app_id = n;
              $scope.field.data_model.app_id = n;
            }
          });

          $scope.addCount = function() {
            if ($scope.appToInsert) {
              var arg = {
                app_id: $scope.appToInsert,
                sum_filters: $scope.sumFilters,
                itself_filter: $scope.itselfFilter
              };
              $mdDialog.hide('ITEMSCOUNT(' + angular.toJson(arg) + ')');
            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/count_calc.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {excludeField: $scope.$parent.fieldModel.field_id}
      })
        .then(function(response) {
          $scope.expressionProxy += response || '';
        });
    };
      
    $scope.showSumDialog = function() {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'excludeField', function($scope, $mdDialog, excludeField) {
          $scope.excludeField = excludeField;
          $scope.sumFilters = [];
          $scope.itselfFilter = {active: false, field_id: []};

          $scope.filterDataModel = {
            recipient: {
              app_id: null
            }
          };

          $scope.field = {data_model: {app_id: null}};

          $scope.$watch('appToInsert', function(n, o) {
            if (n != o) {
              $scope.filterDataModel.recipient.app_id = n;
              $scope.field.data_model.app_id = n;
            }
          });



          $scope.addSum = function() {
            if ($scope.fieldToInsert) {
              var arg = {
                field_id: $scope.fieldToInsert,
                app_id: $scope.appToInsert,
                sum_filters: $scope.sumFilters,
                itself_filter: $scope.itselfFilter
              };
              $mdDialog.hide('SUM(' + angular.toJson(arg) + ')');
            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/sum_calc.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {excludeField: $scope.$parent.fieldModel.field_id}
      })
      .then(function(response) {
          $scope.expressionProxy += response || '';
      });
    };

  }];

  directive.templateUrl = 'gui/calculator_templates/calculator.html';


  directive.link = function(scope, element, attrs) {
    element.on('click', function(e) {
      e.preventDefault();
      if (e.target.getAttribute('value')) {
        var sym = e.target.getAttribute('value');
        scope.$apply(function() {
          scope.expressionProxy = angular.isDefined(scope.expressionProxy) ? scope.expressionProxy + sym : sym;
        });
      }
    });

  };
  return directive;
}])


.directive('ghStringjoiner', [ 'calculatorService', '$mdDialog', function(calculatorService, $mdDialog) {
  var directive = {};

  directive.scope = {
    expression: '=',
    arguments: '='
  };

  directive.controller = ['$scope', function($scope) {
    if (angular.isUndefined($scope.expression)) $scope.expression = '';

    Object.defineProperty($scope, 'expressionProxy', {
      get: function() {
        return calculatorService.insertArgs(this.expression, this.arguments);
      },
      set: function(newValue) {
        this.arguments = calculatorService.getFnArgs(newValue);
        this.expression = calculatorService.removeArgs(newValue);
        calculatorService.convertToUI(calculatorService.insertArgs(this.expression, this.arguments)).then(function(res) {
          $scope.modifiedExpression = res;
        });
      }
    });

    calculatorService.convertToUI(calculatorService.insertArgs($scope.expression, $scope.arguments)).then(function(res) {
      $scope.modifiedExpression = res;
    });

    $scope.showFieldStringDialog = function() {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'app', function($scope, $mdDialog, app) {

          $scope.settings = [{display: 'first_characters', count: 2, name: 'first characters'}, {display: 'each_word_first_character', name: 'first character of each word'}, {display: 'full_value', name: 'full value'}];
          $scope.fieldSettings = $scope.settings[0];
          $scope.app_id = app;

          $scope.addField = function() {
            if ($scope.fieldToInsert) {
              var arg = {
                field_settings: $scope.fieldSettings,
                field_id: $scope.fieldToInsert,
                app_id: $scope.app_id
              };
              $mdDialog.hide('FIELD_STRING(' + angular.toJson(arg) + ')');
            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/field_string_joiner.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {app: $scope.$parent.appId}
      })
      .then(function(response) {
          $scope.expressionProxy += response || '';
      });
    };

    $scope.showSelectingIdDialog = function() {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'app', function($scope, $mdDialog, app) {
          $scope.settings = [{value: 'Index number of item', index: 0}, {value: 'Current year', index: 1}, {value: 'Current month', index: 2}, {value: 'Current day of month', index: 3}];

          $scope.addValue = function () {
            if ($scope.selectedType) {
              switch($scope.selectedType.value) {
                case 'Index number of item':
                  $mdDialog.hide('ITEM_INDEX(' + angular.toJson({type: $scope.selectedType.value, min_digits_count: $scope.min_digits_count || ''}) + ')');
                  break;
                default:
                  $mdDialog.hide('ITEM_INDEX(' + angular.toJson({type: $scope.selectedType.value}) + ')');
              }

            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/id_string_joiner.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {app: $scope.$parent.appId}
      })
        .then(function(response) {
          $scope.expressionProxy += response || '';
        });
    };

    $scope.showWordDialog = function() {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', function($scope, $mdDialog) {

          $scope.addWord = function() {
            if ($scope.word !== '') {
              $mdDialog.hide('WORD(' + angular.toJson({word: $scope.word}) + ')');
            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/word_string_joiner.html',
        clickOutsideToClose:true,
        fullscreen: false
      })
      .then(function(response) {
          $scope.expressionProxy += response || '';
      });
    };

    $scope.deleteLastSymbol = function() {
      if (/[A-Z_]+\([^+\-\/\*]*\)$/.test(calculatorService.insertArgs($scope.expression, $scope.arguments))) {
        $scope.expressionProxy = $scope.expressionProxy.replace(/[A-Z_]+\([^+\-\/\*]*\)$/, '');
      } else {
        $scope.expressionProxy = $scope.expressionProxy.slice(0, $scope.expressionProxy.length - 1);
      }
    };

    $scope.clear = function() {
      $scope.expressionProxy = '';
    };

    $scope.addPlusChar = function() {
      $scope.expressionProxy += '+';
    };
  }];

  directive.templateUrl = 'gui/calculator_templates/stringJoin.html';
  return directive;
}])

.service('calculatorService', ['$q', 'appDataProcesingService', function($q, appDataProcesingService) {
  var fnParseRegExp = /[A-Z_]+\(.*?\)/g, fnWithoutArgRegExp = /[A-Z_]+\(\)/g, self = this;


  this.insertArgs = function(expr, args) {
    angular.forEach(expr.match(fnWithoutArgRegExp), function(matchedFn, index) {
      expr = expr.replace(matchedFn, matchedFn.match(/[A-Z_]+/)[0] + '(' + angular.toJson(args[index]) + ')');
    });
    return expr;
  };

  this.removeArgs = function(expr) {
    angular.forEach(expr.match(fnParseRegExp), function(matchedFn, index) {
      expr = expr.replace(matchedFn, matchedFn.match(/[A-Z_]+/)[0] + '()');
    });
    return expr;
  };

  this.getFnArgs = function(expr) {
    var result = [];
    angular.forEach(expr.match(fnParseRegExp), function(matchedFn, index) {
      result.push(angular.fromJson(matchedFn.match(/\((.*)\)/)[1]));
    });
    return result;
  };


  this.convertToUI = function(expr) {
    if (angular.isUndefined(expr)) {
      return $q.when('');
    }

    var promises = [], promise = $q.defer();

    angular.forEach(expr.match(fnParseRegExp), function(matchedFn) {
      var deferred = $q.defer();
      promises.push(deferred.promise);
      self.processFn(matchedFn).then(function(res) {
        expr = expr.replace(matchedFn, res);
        deferred.resolve();
      });
    });

    $q.all(promises).then(function() {
      promise.resolve(expr);
    });

    return promise.promise;
  };

  this.processFn = function(str) {
    var fnName = str.match(/[A-Z_]+/)[0];
    var parsed =  angular.fromJson(str.match(/\((.*)\)/)[1]);

    switch (fnName) {
      case 'FIELD':
      case 'SUM':
      case 'FIELD_STRING':
        var promise = $q.defer();
        appDataProcesingService.getApp(parsed.app_id).then(function(res) {
          if(res) {
            angular.forEach(res.field_list, function (field) {
              if (field.field_id == parsed.field_id) {
                switch (fnName) {
                  case 'FIELD':
                    promise.resolve('FIELD(' + field.name_space + ')');
                    break;
                  case 'SUM':
                    promise.resolve('SUM(' + field.name_space + ', ' + res.app_name + ')');
                    break;
                  case 'FIELD_STRING':
                    promise.resolve('FIELD_STRING(' + field.name_space + ', ' + parsed.field_settings.name + ')');
                    break;
                  case 'ITEMSCOUNT':
                    promise.resolve('ITEMSCOUNT(' + res.app_name + ')');
                }
              }
            });
          }
        });
        return promise.promise;
      case 'IF': 
        return $q.when('IF(filter)');
      case 'ITEMSCOUNT':
        return appDataProcesingService.getApp(parsed.app_id).then(function (res) {
          if(res) {
            return 'ITEMSCOUNT(' + res.app_name + ')';
          }
        });
      case 'WORD':
        return $q.when(parsed.word);
      case 'ITEM_INDEX':
        return $q.when('ITEM_INDEX(' + parsed.type + ')');
    }
  };

}])

.directive('ghCalculation', ['PipeService', 'calculation', '$timeout', function(PipeService, calculation, $timeout) {
  var directive = {};

  directive.scope = {
    fieldModel: '=?',
    isGenerateOnce: '=?',
    isPersistentValue: '=?',
    expression: '=',
    arguments: '=',
    precision: '=?',
    updateButton: '=?',
    callbacks: '=?',
    ghAppId: '@'
  };
  
  directive.require = '';

  directive.controller = ['$scope', '$element', '$attrs', function ($scope) {
    if (angular.isUndefined($scope.$parent.itemId)) {
      return;
    }
    $scope.editCalcValue = $scope.isGenerateOnce ? $scope.isPersistentValue && $scope.fieldModel.settings.editable : false;
    let lastItemId, lastCalculatedValue, firstLoaded = false;
     /* 
        - If you want the calculation to be generated without saving value then turn OFF " Generate Once" and "Persistent Value" switches
        - If you want the calculation to be saved value then turn OFF " Generate Once" or "Persistent Value". It's usefull when you want to search by calculator field
        - If you want prevent changing value the turn "is Generate Once" switch on.
        - you'dlike to edit calculated value then go to Style Setting and turn "Editable" On. Also make shure that Persistent Value in the Field Setting is turned ON too.
      */

    $scope.updateValue = function() {
      PipeService.on('gh_items_get', {app_id: $scope.$parent.appId}, function itemsPipe(event, items) {
        items.forEach(function (item) {
          if (item.item_id == $scope.$parent.itemId) {
            lastItemId = item.item_id;
            updateValue(lastItemId);
          }
        });

        PipeService.destroy('gh_items_get', {app_id: $scope.$parent.appId}, itemsPipe);
      }, $scope).emit('gh_items_get', {}, {app_id: $scope.$parent.appId});
    };
  
    if ($scope.isGenerateOnce) {
      $scope.$watch("$parent.itemId", (n, o) => {
        if(n != o){
          updateValue(n)
        }
      })
      if ($scope.fieldModel.field_value) {
        $scope.calculatedValue = $scope.fieldModel.field_value;
        return;
      }
    }



    /*  main function*/
    function updateValue(itemId) {
      // if (itemId != $scope.$parent.itemId) return;

      calculation.getEvaluatedExpression($scope.expression, $scope.arguments, itemId, $scope.$parent.appId).then(function (res) {
        if (itemId != $scope.$parent.itemId) return;
        try {
             $scope.calculatedValue = eval(res);
          if (($scope.precision !== undefined) && $scope.calculatedValue) {
            $scope.calculatedValue = $scope.calculatedValue.toFixed($scope.precision);
          }

          if ($scope.isPersistentValue) {
            if (!$scope.fieldModel.field_value) {
              $scope.fieldModel.field_value = $scope.calculatedValue;
            } else {
              // if (!$scope.isGenerateOnce && ($scope.fieldModel.field_value != $scope.calculatedValue)) {
              if (!$scope.isGenerateOnce) {
                $scope.fieldModel.field_value = $scope.calculatedValue; 
              }
            }
          }

          if (lastCalculatedValue != $scope.calculatedValue && !isNaN($scope.calculatedValue)) {
            PipeService.emit('gh_value_update', {
              app_id: $scope.$parent.appId,
              item_id: $scope.$parent.itemId,
              field_id: $scope.$parent.fieldId
            }, $scope.calculatedValue);
          }
          lastCalculatedValue = $scope.calculatedValue;

        } catch (e) {
          $scope.calculatedValue = 'Expression with errors';
        } finally {
          $scope.$digest();
        }
      });
    }


    PipeService.on('gh_model_update', {app_id: $scope.$parent.appId, field_id: $scope.$parent.fieldId}, function() {
      PipeService.on('gh_items_get', {app_id: $scope.$parent.appId}, function itemsPipe(event, items) {
        items.forEach(function (item) {
          if (item.item_id == $scope.$parent.itemId) {
            updateValue(item.item_id);
          }
        });
        PipeService.destroy('gh_items_get', {app_id: $scope.$parent.appId}, itemsPipe);
      }, $scope).emit('gh_items_get', {}, {app_id: $scope.$parent.appId});
    }, $scope);


    
    // subscribe for SUM changes
    $scope.arguments.forEach(function (arg) {
      if (arg.app_id && (arg.app_id != $scope.$parent.appId)) {
        PipeService.on('gh_items_update', {app_id: arg.app_id}, function() {
          updateValue(lastItemId);
        }, $scope);
      }
    });


    function itemIdChangeCb(itemId, isPersistentValue) {
      let valuePipe = function valuePipe() {
        updateValue(lastItemId);
      };

      if (lastItemId) {
        $scope.arguments.forEach(function (arg) {
            // if subscribed then unsubscribe
            PipeService.destroy('gh_value_update', {
              app_id: arg.app_id,
              item_id: lastItemId,
              field_id: arg.field_id
            }, valuePipe, $scope);
        });
      }

      lastItemId = itemId;
  if(!$scope.isGenerateOnce) {
    $scope.arguments.forEach(function (arg) {
      if (arg.mode) {
        arg.items.forEach(item => {
          switch (arg.mode) {
              // if mode is field value
            case '0':
              PipeService.on('gh_value_update', {
                app_id: arg.app_id,
                item_id: lastItemId,
                field_id: item.value
              }, valuePipe, $scope);
              break;
              // if mode is value
            case '1':
              break;
          }
          item.filters_list.forEach(filter => {
            PipeService.on('gh_value_update', {
              app_id: arg.app_id,
              item_id: lastItemId,
              field_id: filter.field_id
            }, valuePipe, $scope);
          })
        })
      } else {
        PipeService.on('gh_value_update', {
          app_id: arg.app_id,
          item_id: lastItemId,
          field_id: arg.field_id
        }, valuePipe, $scope);
      }
    });
  }

      if(isPersistentValue){
        $scope.calculatedValue = $scope.fieldModel.field_value
      } else {
        PipeService.on('gh_items_get', {app_id: $scope.$parent.appId}, function itemsPipe(event, items) {
          items.forEach(function (item) {
            if (item.item_id == itemId) {
              lastItemId = item.item_id;
              updateValue(lastItemId);
            }
          });

          PipeService.destroy('gh_items_get', {app_id: $scope.$parent.appId}, itemsPipe);
        }, $scope).emit('gh_items_get', {}, {app_id: $scope.$parent.appId});
      }
    }
        
    if($scope.isPersistentValue && $scope.fieldModel.field_value != null){
      $scope.calculatedValue = $scope.fieldModel.field_value
      itemIdChangeCb($scope.$parent.itemId, true);
    }else {
      itemIdChangeCb($scope.$parent.itemId);
    }
    $scope.callbacks.push(itemIdChangeCb);
  }];
 
  directive.template = 
    `<div><span ng-if="!editCalcValue">{{calculatedValue == null || calculatedValue == ""  ? "" :fieldModel.data_model.prefix}}{{calculatedValue}}{{calculatedValue == null || calculatedValue == ""  ? "" :fieldModel.data_model.suffix}}</span></div>
     <gh-input ng-if="editCalcValue" 
               gh-editable="fieldModel.settings.editable" 
               ng-model="fieldModel.field_value" 
               gh-data-type="number" 
               gh-field="fieldModel"
               model-update="focus blur" 
               style="margin-top: -14px;">
     </gh-input>
     <span ng-show="(+updateButton)" ng-click="updateValue()" gh-icon="update 0893d2 25px app"></span>`;

  return directive;
}])



.factory('calculation', ['$filter', '$q', 'PipeService',  function($filter, $q, PipeService) {


  function* parser(expression, args, itemId, appId) {
    let funCallRegexp = /[A-Z_]+\(.*?\)/, res, argsCopy = angular.copy(args), currArgs;
    while (res = funCallRegexp.exec(expression)) {
      yield new Promise(function(resolve, reject) {
        currArgs = argsCopy.shift();
        switch (res[0].match(/[A-Z_]+/)[0]) {

          case 'SUM':
            evalSum(currArgs, itemId, appId).then(function(evalSumRes) {
              expression = expression.slice(0, res.index) + evalSumRes + expression.slice(res.index + res[0].length);
              resolve(expression);
            });
            break;

          case 'FIELD':
            evalField({app_id: appId, item_id: itemId, field_id: currArgs.field_id, interpretation_type: currArgs.interpretation_type}).then(function(evalFieldRes) {
             // its happen when expression have commas instead dots 
             expression = expression.slice(0, res.index) + Number(evalFieldRes.toString().replace(/,/g, '.')) + expression.slice(res.index + res[0].length); 
             // expression = expression.slice(0, res.index) + Number(eval(evalFieldRes)) + expression.slice(res.index + res[0].length);
              resolve(expression);
            });
            break;

          case 'IF':
            evalIf({app_id: appId, item_id: itemId, items: currArgs.items, defaultValue: currArgs.defaultValue, mode: currArgs.mode}).then(function(evalFieldRes) {
              // its happen when expression have commas instead dots 
              expression = expression.slice(0, res.index) + Number(evalFieldRes.toString().replace(/,/g, '.')) + expression.slice(res.index + res[0].length); 
              // expression = expression.slice(0, res.index) + Number(eval(evalFieldRes)) + expression.slice(res.index + res[0].length);
              resolve(expression);
            });
            break;

          case 'FIELD_STRING':
            currArgs.item_id = itemId;
            evalFieldString(currArgs).then(function(evalFieldRes) {
              expression = expression.slice(0, res.index) + '"' + evalFieldRes + '"' + expression.slice(res.index + res[0].length);
              resolve(expression);
            });
            break;

          case 'WORD':
            expression = expression.slice(0, res.index) + '\'' + currArgs.word + '\'' + expression.slice(res.index + res[0].length);
            resolve(expression);
            break;

          case 'ITEM_INDEX':
            currArgs.item_id = itemId;
            currArgs.app_id = appId;
            switch (currArgs.type) {
              case 'Index number of item':
                PipeService.on('gh_item_get', {
                  app_id: currArgs.app_id,
                  item_id: currArgs.item_id
                }, function itemPipe(event, item = {index_number: ""}) {
                  PipeService.destroy('gh_item_get', {app_id: currArgs.app_id, item_id: currArgs.item_id}, itemPipe);
                    expression = expression.slice(0, res.index) + '"' + String((new Array(currArgs.min_digits_count || 0)).fill(0).join('').slice(item.index_number.toString().length - currArgs.min_digits_count) + item.index_number) + '"' + expression.slice(res.index + res[0].length);
                    resolve(expression);
                }).emit('gh_item_get', {}, {app_id: currArgs.app_id, item_id: currArgs.item_id});
                break;
              case 'Current year':
                expression = expression.slice(0, res.index) + '"' + ("0" + (new Date()).getFullYear()).slice(-2) + '"' + expression.slice(res.index + res[0].length);
                resolve(expression);
                break;
              case 'Current month':
                expression = expression.slice(0, res.index) + '"' + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + '"' + expression.slice(res.index + res[0].length);
                resolve(expression);
                break;
              case 'Current day of month':
                expression = expression.slice(0, res.index) + '"' + ("0" + (new Date()).getDate()).slice(-2) + '"' + expression.slice(res.index + res[0].length);
                resolve(expression);
                break;
            }
            break;
          case 'ITEMSCOUNT':
            evalItemsCount(currArgs, itemId, appId).then(function(evalFieldRes) {
              expression = expression.slice(0, res.index) + Number(evalFieldRes) + expression.slice(res.index + res[0].length);
              resolve(expression);
            });
            break;

        }
      });
    }
  }

  function evalIf(argObj){
    return new Promise(function (resolve) {
      PipeService.on('gh_item_get', {app_id: argObj.app_id, item_id: argObj.item_id}, async function itemPipe(event, item) {
        PipeService.destroy('gh_item_get', {app_id: argObj.app_id, item_id: argObj.item_id}, itemPipe);
        for(let i = 0; i < argObj.items.length; i++){
          const preparetedFilterList = await gudhub.util.prefilter(angular.copy(argObj.items[i].filters_list), argObj.app_id, argObj.app_id, argObj.item_id)
            const items = gudhub.util.filter([item], preparetedFilterList)
              // if mode field
              if(+argObj.mode){
                if(items.length > 0){
                  return resolve(argObj.items[i].value);
                }
                if(i == argObj.items.length-1){
                  return resolve(argObj.defaultValue);
                }
              }
              // if mode field
              if(!+argObj.mode){
                let modelAddress = {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.items[i].value
                };
                  PipeService.on('gh_interpreted_value_get', modelAddress, function ghValueGet(event, field_value) {
                    PipeService.destroy('gh_interpreted_value_get', modelAddress, ghValueGet);
                    if(items.length > 0){
                      return resolve(field_value);
                    }
                    if(i == argObj.items.length-1){
                      return resolve(argObj.defaultValue);
                    }
                  }).emit('gh_interpreted_value_get', {}, modelAddress);  
              }
          
        }
      }).emit('gh_item_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id});
      
    })   
  }

  function getEvaluatedExpression(expression, args, itemId, appId) {
    const gen = parser(expression, args, itemId, appId);
    let curr, res;

    return new Promise(function(resolve) {
      step();
      function step() {
        curr = gen.next();
        if (!curr.done) {
          curr.value.then(function(val) {
            res = val;
            step();
          });
        } else {
          if (typeof res != 'undefined') {
            resolve(res);
          } else {
            resolve(expression);
          }

        }
      }
    });
  }


  function evalSum(argObj, currItemId, currAppId) {
    return new Promise(function(resolve) {

      PipeService.on('gh_items_get', {app_id: argObj.app_id},async function itemsPipe(event, items) {
        PipeService.destroy('gh_items_get', {app_id: argObj.app_id}, itemsPipe);

				// when all modules for filtering was loaded
				const preparetedFilterList = await gudhub.util.prefilter(angular.copy(argObj.sum_filters), argObj.app_id, currAppId, currItemId)
                const itemsList =  gudhub.util.filter(items, preparetedFilterList)
                argObj.itself_filter.value = currAppId + '.' + currItemId;
                var filteredItems = $filter('itself')(itemsList, argObj.itself_filter),
                    result = 0;

                /* promises for SUM resolving*/
                var localPromises = [];
                angular.forEach(filteredItems, function (item) {
                    var deferred = $q.defer();

                    localPromises.push(deferred.promise);
                    evalField({app_id: argObj.app_id, item_id: item.item_id, field_id: argObj.field_id}).then(function (data) {
                        result += Number(data.toString().replace(/,/g, '.')); // its happen when expression have commas instead dots 
                        // result += Number(eval(data));
                        // result += data && Number(data[0].match(/[+-]?\d+(\.\d+)?/)[0]) || 0;
                        deferred.resolve();
                    });

                });

                $q.all(localPromises).then(function () {
                    resolve(result);
                });
						
      }).emit('gh_items_get', {}, {app_id: argObj.app_id});


    });
  }


  function evalField(argObj) {
    let interpretationType = 'gh_interpreted_value_get';//if there no interpretation_type then we use interpreted value value by default

    if(argObj.interpretation_type){
      interpretationType = argObj.interpretation_type;
      delete argObj.interpretation_type;
    }

    return new Promise(function (resolve) {
      PipeService.on(interpretationType, argObj, function itemsPipe(event, evalFieldRes) {
        PipeService.destroy(interpretationType, argObj, itemsPipe);
        resolve(evalFieldRes);
      }).emit(interpretationType, {}, argObj);
    });
  }


  function evalFieldString(argObj) {
    return evalField(argObj).then(function(evalFieldResult) {
      if (!evalFieldResult) {
        return '';
      } else {
        switch (argObj.field_settings.display) {

          case 'full_value':
            return evalFieldResult;

          case 'first_characters':
            return evalFieldResult.slice(0, argObj.field_settings.count);

          case 'each_word_first_character':
            return evalFieldResult.split(' ').map(function (item) {
              return item.substring(0, 1).toUpperCase();
            }).join('');
        }
      }
    });
  }

  function evalItemsCount(argObj, currItemId, currAppId) {
    return new Promise(function(resolve) {
      PipeService.on('gh_items_get', {app_id: argObj.app_id}, async function itemsPipe(event, items) {
				PipeService.destroy('gh_items_get', {app_id: argObj.app_id}, itemsPipe);
        const preparetedFilterList = await gudhub.util.prefilter(angular.copy(argObj.sum_filters), argObj.app_id, currAppId, currItemId)
        const itemsList = gudhub.util.filter(items, preparetedFilterList)
          argObj.itself_filter.value = currAppId + '.' + currItemId;
          resolve(($filter('itself')(itemsList, argObj.itself_filter)).length);
      }).emit('gh_items_get', {}, {app_id: argObj.app_id});
    });
  }

  return {
    getEvaluatedExpression: getEvaluatedExpression
  };

}]);
