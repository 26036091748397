angular.module('ghAuthFormModule', [
    'authorizationMod',
    'mainStorage'
])


    .directive('ghAuthForm', [ '$document', '$location', '$routeParams', '$timeout', '$compile', 'storage', 'AuthFormService', function($document, $location, $routeParams, $timeout, $compile, storage, AuthFormService) {

        return {
            restrict: 'E',
            scope: {
                ghData: '@', /* Must be: 'login', 'update', 'signup' */
            },
            controller: [ '$scope', '$element', '$attrs', function($scope, $element, $attrs) {
                $scope.auth_form = {};

                /* VARIABLE for checked change avatar */
                var change_avatar = false;
                $scope.auth_form.email = $routeParams.email;
                $scope.storage_user = angular.copy(storage.getMainStorage().user);

                $scope.$watch('storage_user.avatar_512', function(newVal, oldVal){
                  if(newVal !== oldVal){
                    change_avatar = true;
                  }
                });

                $scope.formSettings ={
                    login:{
                        title: 'Login',
                        buttonName: 'Login',
                        cancelButton: false,
                        uploadAvatar: false
                    },
                    update:{
                        title: 'Account Settings',
                        buttonName: 'Update',
                        cancelButton: true,
                        uploadAvatar: true
                    },
                    signup:{
                        title: 'Sign Up',
                        buttonName: 'Sign Up',
                        cancelButton: true,
                        uploadAvatar: false
                    }
                };

                $scope.avatar_field_model = {
                    image_size: 512,
                    avatar_size: 180,
                    uploadEvents: $scope.formSettings[$scope.ghData].uploadAvatar
                };

                $scope.back = function () {
                    $location.path('/');
                };

                $scope.destroyWindow = function () {
                    $scope.$parent.destroyWindow();
                };


                if($scope.storage_user && $scope.ghData == 'update'){
                    $scope.auth_form = {
                        email: angular.copy($scope.storage_user.username),
                        first_name: $scope.storage_user.fullname.split(' ')[0] || '',
                        last_name: $scope.storage_user.fullname.split(' ')[1] || ''
                    };
                }

                $scope.send = function () {

                    var userData = angular.copy($scope.auth_form);

                    if(change_avatar){
                      userData.avatar = $scope.storage_user.avatar_512.replace(/^data:image\/(gif|jpg|jpeg|tiff|png);base64,/, "");
                    }

                    if($scope.authForm.$valid){
                        AuthFormService[$scope.ghData](userData).then(function() {

                        }, function(err) {
                            $scope.errMsg = err;
                            $timeout(function() {
                                $scope.errMsg = "";
                            }, 1500);

                        });
                    }
                };

            }],
            link: function(scope, element, attrs) {
               var template =
            '<div layout-fill flex="100" style="background-color:white;z-index:1000;position: relative; overflow: hidden;" class="gh-layout-fill">' +
            /* /* ----------------------------------- HEADER LINE ------------------------------------------- */
            '   <div layout="row" layout-align="end center" class="toolbar" ng-show="ghData != \'update\'">'+
            '       <button type="button" ng-click="back()" class="cross-icon closeBtn" ><div class="" gh-icon="cross 0893d2 0px normal"></div></button>' +
            '   </div>'+
            /* ---------------------------------------------------------------------------------------------- */
            /* ---------------------------------------- LOGO ------------------------------------------------ */
            '   <div layout="column" layout-align="start center" layout-fill>'+
            /* --------------------------------------- Title ----------------------------------------------- */
            '       <div layout="row" layout-align="center center" class="title">{{formSettings[ghData].title}}</div>'+
                      /* --------------------------------------- Gudhub ----------------------------------------------- */
            '       <div class="gudhub-logo" ng-show="ghData == \'signup\'" gh-icon="gudhub_logo edf2f7 0px auto normal"></div>'+
            /* --------------------------------------- Avatar ----------------------------------------------- */
            '       <gh-avatar ng-show="ghData != \'signup\'" gh-model="storage_user.avatar_512" field-data-model="avatar_field_model"></gh-avatar>'+
            /* ---------------------------------------------------------------------------------------------- */


            /* ------------------------------------- FIERST NAME -------------------------------------------- */
            '       <form name="authForm" novalidate class="gh-form_login">'+
            /*      Lifehack input type="password" display: none for Chrome and oter broser disable avtofill/avtocomplete*/
            /*'       <input style="display:none;" type="password" name="password" />'+ */
            '       <div layout="row" layout-align="center center" class="err">{{errMsg}}</div>'+


            '<div ng-if="ghData != \'login\'" layout="row" flex="100" layout-xs="column" layout-sm="column" layout-align="start start">'+

            '       <div class="field first_name" flex-sm="100" flex-xs="100"  flex="50"  layout="row" layout-wrap  ng-class="{ \'error\' : authForm.first_name.$invalid && authForm.first_name.$dirty && !focusFirstName, \'focus\' : focusFirstName }">'+
            '           <div flex-xs="20" flex="40" class="field-name">First Name</div>'+
            '           <div flex-xs="80" flex="60"><input class="field-duration-input" name="first_name" ng-model="auth_form.first_name" ng-focus="focusFirstName=true" ng-blur="focusFirstName=false" ng-pattern="/^[a-zA-Z0-9\\s.&]+$/" autocomplete="off" ng-minlength="3" required /></div>'+
            '           <div flex-offset-gt-xs="40" flex-offset="0" flex="100" ng-show="authForm.first_name.$invalid && authForm.first_name.$dirty && !focusFirstName" class="help-block">'+
            '               <p ng-show="authForm.first_name.$error.pattern">Enter a Last name. Only contains charaters</p>'+
            '               <p ng-show="authForm.first_name.$error.minlength || !authForm.first_name.length">Min length 3 charaters</p>'+
            '           </div>'+
            '       </div>'+
            /* ---------------------------------------------------------------------------------------------- */
            /* ------------------------------------- LAST NAME -------------------------------------------- */
            '       <div class="field last_name" flex="50" flex-sm="100" flex-xs="100" layout="row" layout-wrap ng-class="{ \'error\' : authForm.last_name.$invalid && authForm.last_name.$dirty && !focusLastName, \'focus\' : focusLastName }">'+
            '           <div flex-xs="20" flex="40" class="field-name">Last Name</div>'+
            '           <div flex-xs="80" flex="60" ><input class="field-duration-input" name="last_name" ng-model="auth_form.last_name" ng-focus="focusLastName=true" ng-blur="focusLastName=false" ng-pattern="/^[a-zA-Z0-9\\s.&]+$/" autocomplete="off" ng-minlength="3" required/></div>'+
            '           <div flex-offset-gt-xs="40" flex-offset="0" flex="100" ng-show="authForm.last_name.$invalid && authForm.last_name.$dirty && !focusLastName" class="help-block">'+
            '               <p ng-show="authForm.last_name.$error.pattern">Enter a Last name. Only contains charaters</p>'+
            '               <p ng-show="authForm.last_name.$error.minlength || !authForm.last_name.length">Min length 3 charaters</p>'+
            '           </div>'+
            '       </div>'+
            '</div>'+


            /* ---------------------------------------------------------------------------------------------- */

                AuthFormService.emailHTML(scope.ghData) +
                AuthFormService.passwordHTML(scope.ghData) +

            /* ------------------------------------- Button ------------------------------------------- */
            '       <div layout="row" layout-align="center center" class="group-button">'+
            '           <button  type="submit" ng-click="send()" class="btn btn-blue">{{formSettings[ghData].buttonName}}</button>'+
            '           <button  ng-if="formSettings[ghData].cancelButton" type="submit" ng-click="'+ ( scope.ghData == 'update' ? 'destroyWindow()':'back()') +'" class="btn btn-blue-reverse">Cancel</button>'+
            '       </div>'+
            /* --------------------------------------------------------------------------------------- */

            '       </form>'+
            '   </div>'+
            '</div>';

            element.append($compile(angular.element(template))(scope));

            }


        };
    }])

    .directive('confirm', function() {
        return {
            require: "ngModel",
            scope: {
                otherModelValue: "=confirm"
            },
            link: function(scope, element, attributes, ngModel) {

                var auto_fill_value = scope.otherModelValue;
                /* Set avtofill value to confirm input*/
                ngModel.$setViewValue(auto_fill_value);

                ngModel.$validators.confirm = function(modelValue) {
                    if(modelValue == auto_fill_value){
                       ngModel.$setPristine();
                    }
                    return modelValue == scope.otherModelValue;
                };

                scope.$watch("otherModelValue", function() {
                    ngModel.$validate();
                });
            }
        };
    })


    .service('AuthFormService', [ '$window', '$q', '$location', 'authApi', 'authService', 'storage', '$rootScope', function( $window, $q, $location, authApi, authService, storage,$rootScope ){

        /* Return password DOM html*/
        this.passwordHTML = function (mode){
            var template =
            /* ----------------------------------------- PASSWORD ------------------------------------------- */
            /* ----------------------------------------- password ------------------------------------------- */

            '       <div class="field login gh-field_email"layout-align="start start" layout-wrap ng-class="{ \'error\' : authForm.password.$invalid && authForm.password.$dirty && !focusPassword, \'focus\' : focusPassword }">'+
            '           <div flex class="field-name">Password</div>'+
            '           <div flex-xs="80" flex-sm="80" flex="80"  class="gh-field_input"><input type="password" name="password" ng-model="auth_form.password" ng-focus="focusPassword=true" ng-blur="focusPassword=false" ng-pattern="/^[a-zA-Z0-9]+$/" ng-minlength="6" ng-maxlength="20" required /></div>'+
            '           <div ng-show="authForm.password.$valid && authForm.confirm_password.$valid && auth_form.password.length" class="cheked" gh-icon="checked 00cc66 32px"></div>'+
            '           <div flex-offset-gt-xs="20" flex-offset="0" flex="100" ng-show="authForm.password.$invalid && authForm.password.$dirty && !focusPassword" class="help-block">'+
            '               <p ng-show="authForm.password.$error.pattern">Enter a valid password. Must contain only charaters and numbers</p>'+
                            ( mode == 'login' ? '': '<p ng-show="authForm.password.$error.minlength">Min length 6 charaters</p><p ng-show="authForm.password.$error.maxlength">Max length 20 charaters</p>') +
            '           </div>'+
            '       </div>'+
            /* ------------------------------------- confirm password -------------------------------------- */
                    ( mode == 'login' ? '': '<div class="field" layout="row" ng-if="authForm.password.$dirty" ng-show="auth_form.password.length && !authForm.confirm_password.$valid" layout-align="start start" layout-wrap ng-class="{ \'error\' : authForm.confirm_password.$invalid}">'+
            '           <div flex="20" class="field-name confirm">Confirm Password</div>'+
            '           <div flex-gt-xs="80" flex="80"><input type="password" name="confirm_password" ng-focus="focusPassword=true" ng-blur="focusPassword=false" ng-model="auth_form.confirm_password" confirm="auth_form.password" /></div>'+
            '           <div flex-offset-gt-xs="20" flex-offset="0" flex="100" class="help-block">'+
            '               <p>Password are not confirmed!</p>'+
            '           </div>'+
            '       </div>')+

            /* ------------------------------------- old password---------------------------------------- */
                    ( mode == 'update' ? '<div ng-if="!authForm.confirm_password.$pristine" ng-show="authForm.confirm_password.$valid && authForm.password.$valid && authForm.password.$dirty" class="field login" layout="row" layout-align="start start" layout-wrap >'+
            '           <div flex class="field-name">Old Password</div>'+
            '           <div flex-gt-xs="80" flex="100"><input autocomplete="off" type="password" name="old_password" ng-model="auth_form.old_password" ng-pattern="/^[a-zA-Z0-9]+$/" ng-minlength="6" ng-maxlength="20" required /></div>'+
            '       </div>' : '');

            return template;
        };

         /* Return email DOM html*/
        this.emailHTML = function (mode){
            var template =
            /* ------------------------------------------- EMAIL -------------------------------------------- */
            /* ------------------------------------------- email -------------------------------------------- */
            '       <div class="field email gh-field_email" layout-align="start start" layout-wrap ng-class="{ \'error\' : authForm.email.$invalid && authForm.email.$dirty && !focusEmail'+ ( mode == 'login' ? '':'') +', \'focus\' : focusEmail }">'+
            '           <div  class="field-name">Email</div>'+
            '           <div  class="gh-field_input"><input type="text" name="email" ng-focus="focusEmail=true" ng-blur="focusEmail=false" ng-model="auth_form.email" ng-pattern="/^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$/" required/></div>'+
            '           <div ng-show="authForm.email.$valid && authForm.confirm_email.$valid" class="cheked" gh-icon="checked 00cc66 32px"></div>'+
            '           <div flex-offset-gt-xs="20" flex-offset="0" flex="100" ng-show="authForm.email.$invalid && authForm.email.$dirty && !focusEmail" class="help-block">'+
            '               <p ng-show="authForm.email.$error.pattern">Enter a valid email.</p>'+
            '           </div>'+
            '       </div>'+
            /* --------------------------------------- confirm email ---------------------------------------- */
                    ( mode == 'login' ? '': '<div class="field" layout="row" layout-align="start start" layout-wrap ng-show="!authForm.confirm_email.$valid && auth_form.email.length" layout-align="start start" layout-wrap ng-class="{ \'error\' : authForm.confirm_email.$invalid}">'+
            '           <div flex class="field-name confirm">Confirm Email</div>'+
            '           <div flex-gt-xs="80" flex="80"><input name="confirm_email" ng-focus="focusEmail=true" ng-blur="focusEmail=false" ng-model="auth_form.confirm_email" confirm="auth_form.email"/></div>'+
            '           <div flex-offset-gt-xs="20" flex-offset="0" flex="100" class="help-block">'+
            '               <p>Email are not confirmed!</p>'+
            '           </div>'+
            '       </div>');

            return template;
        };


        this.signup = function( data ) {
            var deferred = $q.defer();
            var dataToSend = {
                fullname: data.first_name + ' ' + data.last_name,
                password: data.password,
                username: data.email
            };

            authApi.signup(dataToSend).then(function(res) {
                storage.updateUser(res);
                $window.localStorage.auth_key = res.auth_key;
                $location.path('/apps');
                $rootScope.$apply();
            }, function(e) {
                deferred.reject(e.data + ". Please try again...");
            });

            return deferred.promise;
        };

        this.login = function(data) {
            var deferred = $q.defer();
            var dataToSend = {
                password: data.password,
                username: data.email
            };

            authApi.login(dataToSend).then(function(res) {
                storage.updateUser(res);
                $window.localStorage.auth_key = res.auth_key;
                $location.path('/apps');
                $rootScope.$apply();
            }, function(e) {
                deferred.reject(e.data + ". Please try again...");
            });

            return deferred.promise;
        };

        this.update = function (data) {

            var deferred = $q.defer();
            var dataToSend = {
                    fullname: data.first_name + ' ' + data.last_name,
                    password: data.password,
                    username: data.email,
                    old_password: data.old_password || data.password
            };

            if(data.avatar){
              dataToSend.avatar = data.avatar;
            }

            authService.updateUser(dataToSend).then(function () {
                 deferred.resolve('Ok!');
            }, function(e) {
                deferred.reject(e.data + ". Please try again...");
            });

            return deferred.promise;
        };

        return this;





    }]);
