import { MainControl } from "./../controls/main_control.js";
import Rete from "rete";
import { items, field_value } from "./../controls/sockets.js";
import { ifNoConnections } from "./../controls/utilities.js";
import { act } from "./../../actions/actions.js";

export class FaceDetector extends Rete.Component {
  constructor() {
    super("Face Detector");
  }

  builder(node) {
    const detectedItems = new Rete.Output("detectedItems", "Detected", items);

    const inpImages = new Rete.Input("inpImages", "Images id", field_value, true);
    const cntr = new MainControl(
        this.editor,
        "text",
        false,
        node,
        this.getSettings(),
        this.getTemplate()
    );
    if(node.data.isTraining) {
       const itemRef = new Rete.Input("itemRef","item ref",field_value);
       node.addInput(itemRef);
    }else {
      const notDetected = new Rete.Output("notDetectedItems", "Not Detected", items);
      node.addOutput(notDetected);
    }
    return node
        .addControl(cntr)
        .addInput(inpImages)
        .addOutput(detectedItems);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    const app_id = node.data.app_id;
    const recognize_app_id = node.data.recognize_app_id;
    const trained_data_field_id = node.data.field_id;
    const isTraining = node.data.isTraining;

    const inputImagesId = inputs.inpImages[0] || [];
    const itemRef = inputs.itemRef && inputs.itemRef[0];

    const imagesFilesId = inputImagesId
        .map((imageIds) => (imageIds ? imageIds.split(",") : []))
        .flat();
    console.log('imagesFilesId', imagesFilesId)
    let result = [];
    if (isTraining && itemRef) {
      await act.trainingModel(
          itemRef[0],
          gudhub,
          imagesFilesId,
          trained_data_field_id
      );
    } else {
      result = await act.detectFace(
          gudhub,
          app_id,
          imagesFilesId,
          trained_data_field_id,
          recognize_app_id
      );
    }
    outputs["outItems"] = result;

    //-- checking if there are output connections
    //-- If there no outputs then we return value
    if (ifNoConnections(node.outputs)) resolve(result);
  }

  getTemplate() {
    return {
      name: "Face Detector",
      icon: "circular",
      constructor: "node",
      data_model: {
        app_id: "",
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Default Settings",
              type: "header",
            },
            {
              type: "ghElement",
              property: "app_id",
              data_model(fieldModel) {
                return {
                  field_name: "Application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: "ghElement",
              property: "field_id",
              onInit(settingScope, fieldModel) {
                settingScope.$watch(
                    function () {
                      return fieldModel.app_id;
                    },
                    function (newValue) {
                      settingScope.field_model.data_model.app_id = newValue;
                    }
                );
              },
              data_model(fieldModel) {
                return {
                  data_model: {
                    app_id: fieldModel.app_id,
                  },
                  field_name: "Field for Trained Data",
                  name_space: "field_trained_data",
                  data_type: "field",
                };
              },
            },
            {
              type: "ghElement",
              property: "isMultiple",
              data_model() {
                return {
                  data_type: "boolean",
                  field_name: "Single/Multiple Recognition",
                  name_space: "single_multiple",
                };
              },
            },
            {
              type: "ghElement",
              property: "isTraining",
              data_model() {
                return {
                  data_type: "boolean",
                  field_name: "Training Mode",
                  name_space: "training_mode",
                };
              },
            },
            {
              type: "ghElement",
              showIf:'!isTraining',
              property: "recognize_app_id",
              data_model(fieldModel) {
                return {
                  field_name: "Application For Recognize",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
          ],
        ],
      },
    ];
  }
}
