import Rete from "rete";
import { MainControl } from "../controls/main_control.js";
import { ifNoConnections } from "../controls/utilities.js";
import { items, field_value } from "../controls/sockets.js";
import {
  awaitDebugger,
  DeferredPromise,
} from "../controls/automation_debugger";

export class Constants extends Rete.Component {
  constructor() {
    super("Constants");
  }

  builder(node) {
    const outputsFields = node.data.fields || [];
    const outputs = outputsFields.map(
      (field) => new Rete.Output(field.field_name, field.field_name, field_value)
    );
    const inpPush = new Rete.Input("inpPush", "PUSH", field_value);

    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );
    node
      .addControl(cntr)
    outputs.forEach((output) => node.addOutput(output));

    if(+node.data.push){
      node.addInput(inpPush);
    }

    return node;
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    let sendData = +node.data.push ? inputs["inpPush"][0] ? inputs["inpPush"][0][0] : false  : true;

    if(sendData)
    for (const field of node.data.fields) {
        outputs[field.field_name] = [field.constant_value.constant.value];
    }

    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) resolve(results);
  }

  getTemplate() {
    return {
      name: "Constants",
      icon: "plus",
      constructor: "node",
      data_model: {
        push: "0",
        fields: []
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Value Outputs",
              type: "header",
            },
            {
              type: "ghElement",
              property: "push",
              data_model() {
                return {
                  field_name: "Send Constant By",
                  name_space: "send_constant_by",
                  data_type: "radio_button",
                  data_model: {
                    options: [
                      {
                        name: "PULL",
                        value: "0",
                        color: "#05b52c",
                      },
                      {
                        name: "PUSH",
                        value: "1",
                        color: "#ffa200",
                      }
                    ],
                    interpretation: [
                      {
                        src: "form",
                        id: "current_one",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            }
          ],
          [
            {
              title: "Value Outputs",
              type: "header",
            },
            {
              type: "html",
              class: ".option-column_750px",
              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "field_name",
                      type: "text",
                      prop_name: "Constant Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "constant_value",
                      prop_name: "Constant Value",
                      type: "additional_settings",
                      display: true,
                      data_model: function (option, scope) {
                        return {
                          appId: option.app_id,
                          elementId: option.field_id,
                          settings: [
                            {
                              title: "Constant",
                              type: "general_setting",
                              icon: "configuration",
                              columns_list: [
                                [
                                  {
                                    title: "Constant Value",
                                    type: "header",
                                  },
                                  {
                                    type: 'ghElement',
                                    class: 'option-column_full_size',
                                    property: 'constant.value',
                                    data_model: function () {
                                      return {
                                        data_model:{
                                          code_mode: 'htmlmixed',
                                          save_file: false,
                                          interpretation:[
                                            {
                                              src: 'form',
                                              settings:{
                                                show_field_name: 0
                                              }
                                            }
                                          ],
                                        },
                                        field_name: 'Code Editor',
                                        name_space: 'code_editor',
                                        data_type: 'code_editor'
                                      };
                                    },
                                  },
                                  
                                ],


                              ],
                            },
                          ],
                        };
                      },
                    }
                    
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.fields" pattern="field_model.patterns"></gh-option-table>',
            },
          ],
        ],
      },
    ];
  }
}
