import './gh_automation.scss';
import 'regenerator-runtime/runtime';//rete uses globals from here
import Rete from "rete";
import ConnectionPlugin from 'rete-connection-plugin';
import VueRenderPlugin from 'rete-vue-render-plugin';
import {Modules} from './automation_modules.js';


angular.module('ghAutomationModule', [
])

  .directive('ghAutomation', [function() {

    return{
      restrict: 'E',

      scope: {
        ghModel: '=', // Checked value
        nodesModel: '=',//!!!!!!!!!!! DO NOT FORGER TO ADD COMPONETS IN DIRERECTIVE ATRIBUTE !!!!!!!!!!//
        appId: '=',
        elementId: '=',
        trigger: '=',
        init: '=?',
      },

      template:`<div ng-repeat="component in components | filter:filter_ars" class="add-node-button" ng-click="addNode(component)">{{component.name}}</div>
                <div id="gh-automation-render"></div>`,

      controller: [ '$scope', '$element', '$attrs', 'GhDialog', '$compile', '$timeout',  function($scope, $element, $attrs, GhDialog, $compile, $timeout) {
        $scope.settingsModels = {};//Here we seting models for rendering modules
        $scope.filter_ars = {'constructor':'node'}

        console.log('work');
        $scope.init = async function(){
          //-- we use timeout to make querySelector work, link function doesn't work here becouse it requires aditional hacks with scope update
          $scope.components = []; // List of availabe components  
          const container = $element[0].querySelector('#gh-automation-render');
          const editor = new Rete.NodeEditor('trigger@0.1.0', container);
          const engine = new Rete.Engine('trigger@0.1.0');
          console.log($scope);
          //---- Components Initialization ---//
          $scope.nodesModel.include_nodes.forEach(node =>{
            let component = new Modules[node]();
            console.log(node, Modules);
            editor.register(component); //---- Registering components

            $scope.components.push( {
                      name: component.getTemplate().name,
                      icon: component.getTemplate().icon,
                      constructor: component.getTemplate().constructor,
                      data_model: component.getTemplate().data_model,
                      instance: component
                });
          });



          //---- Ading Plugins ---//
          const JsRenderPlugin = {
            install(editor, params = {}) {
              editor.on("rendercontrol", ({ el, control }) => {
                if (control.render && control.render !== "js") return;
                control.handler(el, editor, $scope, $compile);
              });
            }
          };
          editor.use(ConnectionPlugin);
          editor.use(VueRenderPlugin);
          editor.use(JsRenderPlugin);//-- For rendering gh-elements inside nodes




          //----- Adding new Node  -----//
          $scope.addNode = async (component) => {
            var newNode = await component.instance.createNode( component.instance.getTemplate().data_model );
            newNode.position = [80, 200];
            editor.addNode(newNode);
            await engine.process(editor.toJSON());
          };

          $scope.init = () => {
              editor.fromJSON($scope.ghModel).then(() => {
                  editor.view.resize();
              });
          }


          //----- Delete Node  -----//
          $scope.deleteNode = async (nodeId) => {
            let nodeToRemove = editor.nodes.find(node => node.id == nodeId);
            editor.removeNode( nodeToRemove );
            engine.process(editor.toJSON());
          };




          //----- Init Editor -----//
            $timeout($scope.init, 0)
            
          //----- Listening for Events to update the tree -----//
          editor.on('process nodecreated noderemoved connectioncreated connectionremoved', async () => {
              await engine.abort();
              await engine.process(editor.toJSON());
              $scope.ghModel = editor.toJSON();
              console.log(JSON.stringify(editor.toJSON()));
              //console.log(editor.toJSON());
              $scope.$apply();
          });
        }
            $scope.init();

      }]


    };

  }]);
