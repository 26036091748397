import Rete from "rete";
import {NoEditControl} from "../controls/no_edit_control.js"
import { field_value, items } from "../controls/sockets.js";
import {ifNoConnections} from "../controls/utilities.js"





export class GhElementNode extends Rete.Component {
  constructor(){
      super("Gh Element Node");
  }

  builder(node) {
      var updatedItems = new Rete.Output('updatedItems', "Updated Items", items, true);
      var newValue = new Rete.Output('newValue', "New Value", field_value, true);
      var oldValue = new Rete.Output('oldValue', "Old Value", field_value, true);
      var cntr = new NoEditControl( this.editor, 'text', false, this.getTemplate() );

      return node
          .addControl(cntr)
          .addOutput(updatedItems)
          .addOutput(newValue)
          .addOutput(oldValue);
          
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    outputs['updatedItems'] = triggeredData.updated_item;
    outputs['newValue'] = triggeredData.new_value;
    outputs['oldValue'] = triggeredData.old_value;


    if( ifNoConnections(node.outputs) )
      resolve(null);    
  }

  
  getTemplate() {
    return {
      name: 'Gh Element Node',
      icon: 'remote_add',
      constructor: 'trigger', //-- if constructor is "node" then we can add it 
      data_model: {}
    }
  }


  getSettings(){
    return [];
  }
}