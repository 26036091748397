require("./../config.js");

angular
  .module("ModulesListModule", ["config"])

  /*=======================================================================================================*/
  /*===============================================   MODULES STORAGE   ======================================*/
  /*=======================================================================================================*/
  .service("ModulesList", [
    "cnfg",
    function(cnfg) {
      this.getModulesList = function() {
        return [
          {
            name: "text",
            url: cnfg.async_modules_path + "text_data.js"
          },
          {
            name: "text_opt",
            url: cnfg.async_modules_path + "text_options_data.js"
          },
          {
            name: "number",
            url: cnfg.async_modules_path + "number_data.js"
          },
          {
            name: "task_board",
            url: cnfg.async_modules_path + "task_board_data.js"
          },
          {
            name: "visualizer",
            url: cnfg.async_modules_path + "visualizer_data.js"
          },
          {
            name: "enterprice_visualizer",
            url: cnfg.async_modules_path + "enterprice_visualizer_data.js"
          },
          {
            name: "email",
            url: cnfg.async_modules_path + "email_data.js"
          },
          {
            name: "date",
            url: cnfg.async_modules_path + "date_data.js"
          },
          {
            name: "radio_button",
            url: cnfg.async_modules_path + "radio_button_data.js"
          },
          {
            name: "radio_icon",
            url: cnfg.async_modules_path + "radio_icon_data.js"
          },
          {
            name: "twilio_phone",
            url: cnfg.async_modules_path + "twilio_phone_data.js"
          },
          {
            name: "twilio_autodialer",
            url: cnfg.async_modules_path + "twillio_autodialer_data.js"
          },
          {
            name: "color",
            url: cnfg.async_modules_path + "color_data.js"
          },
          {
            name: "charts",
            url: cnfg.async_modules_path + "charts_data.js"
          },
          {
            name: "add_items_from_template",
            url: cnfg.async_modules_path + "add_items_from_template_data.js"
          },
          {
            name: "item_ref",
            url: cnfg.async_modules_path + "itemRef_data.js"
          },
          {
            name: "data_ref",
            url: cnfg.async_modules_path + "data_ref_data.js"
          },
          {
            name: "table",
            url: cnfg.async_modules_path + "table_data.js"
          },
          {
            name: "tile",
            url: cnfg.async_modules_path + "tile_data.js"
          },
          {
            name: "file",
            url: cnfg.async_modules_path + "file_data.js"
          },
          {
            name: "image",
            url: cnfg.async_modules_path + "image_data.js"
          },
          {
            name: "text_editor",
            url: cnfg.async_modules_path + "text_editor_data.js"
          },
          {
            name: "tinymse",
            url: cnfg.async_modules_path + "tinymse_data.js"
          },
          {
            name: "duration",
            url: cnfg.async_modules_path + "duration_data.js"
          },
          {
            name: "user",
            url: cnfg.async_modules_path + "user_data.js"
          },
          {
            name: "app",
            url: cnfg.async_modules_path + "application_data.js"
          },
          {
            name: "field",
            url: cnfg.async_modules_path + "field_data.js"
          },
          {
            name: "available",
            url: cnfg.async_modules_path + "available_data.js"
          },
          {
            name: "view_list",
            url: cnfg.async_modules_path + "view_list_data.js"
          },
          {
            name: "calculator",
            url: cnfg.async_modules_path + "calculator_data.js"
          },
          {
            name: "calendar",
            url: cnfg.async_modules_path + "calendar_data.js"
          },
          {
            name: "string_join",
            url: cnfg.async_modules_path + "string_joiner_data.js"
          },
          {
            name: "tag",
            url: cnfg.async_modules_path + "tag_data.js"
          },
          {
            name: "signature",
            url: cnfg.async_modules_path + "signature_data.js"
          },
          {
            name: "schedule",
            url: cnfg.async_modules_path + "schedule_data.js"
          },
          {
            name: "auto_schedule",
            url: cnfg.async_modules_path + "auto_schedule_data.js"
          },
          {
            name: "sendEmail",
            url: cnfg.async_modules_path + "send_email_data.js"
          },
          {
            name: "boolean",
            url: cnfg.async_modules_path + "boolean_data.js"
          },
          {
            name: "product_gallery",
            url: cnfg.async_modules_path + "product_gallery_data.js"
          },
          {
            name: "online_inventory",
            url: cnfg.async_modules_path + "online_inventory_data.js"
          },
          {
            name: "3d_edges",
            url: cnfg.async_modules_path + "3d_edges_data.js"
          },
          {
            name: "color_list",
            url: cnfg.async_modules_path + "color_list_data.js"
          },
          {
            name: "doc_template",
            url: cnfg.async_modules_path + "doc_template_data.js"
          },
          {
            name: "go_to_link",
            url: cnfg.async_modules_path + "go_to_link_data.js"
          },
          {
            name: "go_to_view",
            url: cnfg.async_modules_path + "go_to_view_data.js"
          },
          {
            name: "range",
            url: cnfg.async_modules_path + "range_data.js"
          },
          {
            name: "barcode",
            url: cnfg.async_modules_path + "barcode_data.js"
          },
          {
            name: "item_remote_add",
            url: cnfg.async_modules_path + "item_remote_add_data.js"
          },
          {
            name: "item_remote_update",
            url: cnfg.async_modules_path + "item_remote_update_data.js"
          },
          {
            name: "timeline",
            url: cnfg.async_modules_path + "timeline_data.js"
          },
          {
            name: "delete_item",
            url: cnfg.async_modules_path + "delete_action.js"
          },
          {
            name: "print_doc",
            url: cnfg.async_modules_path + "print_doc_action.js"
          },
          {
            name: "open_item",
            url: cnfg.async_modules_path + "open_item_action.js"
          },
          {
            name: "edit_template",
            url: cnfg.async_modules_path + "edit_template_action.js"
          },
          {
            name: "open_app",
            url: cnfg.async_modules_path + "open_app_action.js"
          },
          {
            name: "user_settings",
            url: cnfg.async_modules_path + "user_settings_action.js"
          },
          {
            name: "app_sharing",
            url: cnfg.async_modules_path + "sharing_action.js"
          },
          {
            name: "app_constructor",
            url: cnfg.async_modules_path + "app_constructor_action.js"
          },
          {
            name: "export_csv",
            url: cnfg.async_modules_path + "export_csv.js"
          },
          {
            name: "import_csv",
            url: cnfg.async_modules_path + "import_csv.js"
          },
          {
            name: "add_items",
            url: cnfg.async_modules_path + "add_items_action.js"
          },
          {
            name: "update_items",
            url: cnfg.async_modules_path + "update_items_action.js"
          },
          {
            name: "install_app",
            url: cnfg.async_modules_path + "install_app_action.js"
          },
          {
            name: "search_action",
            url: cnfg.async_modules_path + "search_action.js"
          },
          {
            name: "filter_table",
            url: cnfg.async_modules_path + "filter_table_action.js"
          },
          {
            name: "slider",
            url: cnfg.async_modules_path + "slider_data.js"
          },
          {
            name: "clone_item",
            url: cnfg.async_modules_path + "clone_item_action.js"
          },
          {
            name: "close",
            url: cnfg.async_modules_path + "close_action.js"
          },
          {
            name: "phone",
            url: cnfg.async_modules_path + "phone_data.js"
          },
          {
            name: "link",
            url: cnfg.async_modules_path + "link_data.js"
          },
          {
            name: "sheduling",
            url: cnfg.async_modules_path + "sheduling_data.js"
          },
          {
            name: "qrcode",
            url: cnfg.async_modules_path + "qrcode_data.js"
          },
          {
            name: "graph2d",
            url: cnfg.async_modules_path + "graph2d_data.js"
          },
          {
            name: "quote_tool",
            url: cnfg.async_modules_path + "quote_tool_data.js"
          },
          {
            name: "cards",
            url: cnfg.async_modules_path + "cards_data.js"
          },
          {
            name: "jsonConstructor",
            url: cnfg.async_modules_path + "json_constructor_data.js"
          },
          {
            name: "button",
            url: cnfg.async_modules_path + "button_action.js"
          },
          {
            name: "filter_advanced",
            url: cnfg.async_modules_path + "filter_advanced_data.js"
          },
          {
            name: "code_editor",
            url: cnfg.async_modules_path + "code_editor_data.js"
          },
          {
            name: "icon",
            url: cnfg.async_modules_path + "icon_data.js"
          },
          {
            name: "quoteRequest",
            url: cnfg.async_modules_path + "quote_request_data.js"
          },
          {
            name: "view_container",
            url: cnfg.async_modules_path + "view_container_data.js"
          },
          {
            name: "element_ref",
            url: cnfg.async_modules_path + "element_ref_data.js"
          },
          {
            name: "quote_tool_objects_renderer",
            url: cnfg.async_modules_path + "quote_tool_objects_renderer_data.js"
          },
          {
            name: "quote_tool_objects_renderer_generator",
            url: cnfg.async_modules_path + "quote_tool_objects_renderer_generator_data.js"
          },
          {
            name: "trigger",
            url: cnfg.async_modules_path + "trigger_data.js"
          },
          {
            name: "voting",
            url: cnfg.async_modules_path + "voting_data.js"
          },
          {
            name: "view_tabs",
            url: cnfg.async_modules_path + "view_tabs.js"
          },
          {
            name: "filter_tabs",
            url: cnfg.async_modules_path + "filter_tabs.js"
          },
          {
            name: "gps_coords",
            url: cnfg.async_modules_path + "gps_coords.js"
          },
          {
            name: "google_map",
            url: cnfg.async_modules_path + "google_map_data.js"
          },
          {
            name: "data_migrations",
            url: cnfg.async_modules_path + "data_migrations.js"
          },{
            name: "additional_settings",
            url: cnfg.async_modules_path + "gh_additional_settings_data.js"
          },{
            name: "send_request",
            url: cnfg.async_modules_path + "send_request_data.js"
          },
          {
            name: "webcam",
            url: cnfg.async_modules_path + "webcam_data.js"
          },
          {
            name: "json_viewer",
            url: cnfg.async_modules_path + "json_viewer_data.js"
          },{
            name: "notifications",
            url: cnfg.async_modules_path + "notifications_data.js"
          },
          { 
            name: "api",
            url: cnfg.async_modules_path + "api_data.js"
          },
          { 
            name: "smart_input",
            url: cnfg.async_modules_path + "smart_input_data.js"
          },
          {
            name: "json_editor",
            url: cnfg.async_modules_path + "json_editor_data.js"
          },
          {
            name: "grapes_html_editor",
            url: cnfg.async_modules_path + "grapes_html_editor_data.js"
          },
          {
            name: "quiz",
            url: cnfg.async_modules_path + "quiz_data.js"
          },
          {
            name: "markdown_viewer",
            url: cnfg.async_modules_path + "markdown_viewer_data.js"
          }
        ];
      };
    }
  ]);
