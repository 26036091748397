/*
{
senderEmail: "webmaster@atlasiko.com", // user who send email
senderName: "test", // username
theme: "Quote Request Form", //theme of mail
body: "<!DOCTYPE html><html> <head>   <title>Page Title</title> " // content of mail
attachments: [ // added file to email
		{
			fileType: "Base64", 
			fileName: "attached.html",
			fileSource: "PCFET0NUWVBFIGh0bWw+PGh0bWw+IDxoZWFkPiAgIDx0..."
		}
		{
		...
		}
	]
recipients:[ // who receive email
	{
		name: "Company", 
		email: "company@gmail.com", 
		replyToEmail: "user@gmail.com" // mail for reply
	},
	{
		name: "User", 
		email: "user@gmail.com", 
		replyToEmail: "company@gmail.com"
	}
]
	
*/
require('./../config.js');

angular.module('sendingEmailModule', [])
    .service('sendEmailService', ['$q', '$http', 'cnfg', function ($q, $http, cnfg) {
				let self = this;

        this.sendEmail = function (data) {
            var deferred = $q.defer();
            generatingEmail(data);
            deferred.resolve('Success!');
            return deferred.promise;
        }

        function generatingEmail(data) {
            var email = {
                "recipients": [],
                "senderEmail": "sales@atlasiko.com",
                "senderName": "Atlasiko",
                "theme": "Gudhub send you email",
                "body": "Html",
                "attachments": []
    
            }

            for(let key in data){
                if(data[key].length > 0){
                    email[key] = data[key];
                }
            }
            
            //-- Adding company email and name to JSON
            var userEmail = data.emails.split(',')
            userEmail.forEach(function(user_email){
                var companyObjToJSON = {
                    name: email.senderName,
                    email: user_email
                };
                if(data.replyToEmail !== undefined) {
                    companyObjToJSON.replyToEmail = data.replyToEmail;
                };
                if(data.validate == true || data.validate == false) {
                    email.validate = data.validate
                }
                var emailCorect = formValidation(user_email)
                if(emailCorect){
                    email.recipients.push(companyObjToJSON);
                }
            });

						self.sendToApi(email);
        }

        function  formValidation(email) {
            var validationPass = true;
            var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            //--email checking
            if (!reg.test(email)) {
                validationPass = false;
            } else {}

            return validationPass;
				}
				

        this.sendToApi = function(email) {
				
            return $http({
              method: 'POST',
              url:  cnfg.server_url + '/api/json/sendemail',
              headers: {
                'Content-Type': 'application/json'
            },
              data: JSON.stringify(email),
            }).then(function(result) {
              return result;
            }, function(err) {
              return $q.reject(err);
            });
        }
          
        return this;
    }]);