import jsonpath from "jsonpath";

export function ifNoConnections(outputs) {
  let numberOfConnections = 0;
  for (const prop in outputs) {
    numberOfConnections += outputs[prop].connections.length;
  }
  return numberOfConnections == 0;
}

export function getConditionFunc(value_condition, value) {
  switch (value_condition) {
    case ">":
      return (condition) => valuesIsNumber([condition, value]) ?  Number(condition) > Number(value) :  condition > value;
    case "<":
      return (condition) =>  valuesIsNumber([condition, value]) ?  Number(condition) < Number(value) :  condition < value;
    case ">=":
      return (condition) =>  valuesIsNumber([condition, value]) ?  Number(condition) >= Number(value) :  condition >= value;
    case "<=":
      return (condition) =>  valuesIsNumber([condition, value]) ?  Number(condition)<= Number(value) : condition <= value;
    case "!=":
      return (condition) =>  valuesIsNumber([condition, value]) ?  Number(condition) != Number(value) : condition != value;
    case "==":
    default:
      return (condition) =>  valuesIsNumber([condition, value]) ?  Number(condition) == Number(value) : condition == value;
  }
}

function valuesIsNumber(values = []) {
  return values.every((value) => !Number.isNaN(Number(value)));
}

export function jsonToProperties(obj = {}, props = []) {
  const data = [];
  props.forEach((prop) => {
    try {
      const value = jsonpath[prop.search_type](obj, prop.property_name);
      data.push(value);
    } catch (err) {
      console.log(err);
    }
  });
  return data;
}

export function createQueryString(queries = []) {
  return queries.length
    ? `?${queries.map((query) => query.join("=")).join("&")}`
    : "";
}
