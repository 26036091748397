import Rete from "rete";
import { MainControl } from "./../controls/main_control.js";
import { ifNoConnections } from "./../controls/utilities.js";
import { items, field_value } from "./../controls/sockets.js";
import {
  awaitDebugger,
  DeferredPromise,
} from "../controls/automation_debugger";

export class ItemDestructor extends Rete.Component {
  constructor() {
    super("Item Destructor");
  }

  builder(node) {
    const outItems = new Rete.Output("outItems", "Items", items);
    const outItemRef = new Rete.Output("outItemRef", "Item Ref", field_value);
    const outItemId = new Rete.Output("outItemId", "Item Id", field_value);
    const outAppId = new Rete.Output("outAppId", "App Id", field_value);
    const inpItems = new Rete.Input("inpItems", "Items", items, true);
    const outputsFields = node.data.fields || [];
    const outputs = outputsFields.map(
      (field) => new Rete.Output(field.field_id, field.field_name, field_value)
    );
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );
    node
      .addControl(cntr)
      .addInput(inpItems)
      .addOutput(outItems)
      .addOutput(outItemRef)
      .addOutput(outItemId)
      .addOutput(outAppId);
    outputs.forEach((output) => node.addOutput(output));
    return node;
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    const app_id = node.data.app_id;
    const fieldsId = node.data.fields || [];
    for (const inputItems of inputs["inpItems"]) {
      if (inputItems && inputItems.length) {
        const results = inputItems
          .map((item) => {
            return item.fields.filter((field) => {
              return fieldsId.find(
                (fieldId) => fieldId.field_id == field.field_id
              );
            });
          })
          .filter((item) => !!item[0])
          .flat();
        
        outputs["outItemId"] = inputItems.map( (item) => `${item.item_id}`);
        outputs["outItemRef"] = inputItems.map( (item) => `${app_id}.${item.item_id}`);
        outputs["outAppId"] = [app_id];
        outputs["outItems"] = inputItems;

        for (const field of results) {
          if(outputs[field.field_id]){
            outputs[field.field_id].push(field.field_value)
          }else {
            outputs[field.field_id] = [field.field_value];
          }
          
        }
      }
    }

    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) resolve(results);
  }

  getTemplate() {
    return {
      name: "Item Destructor",
      icon: "plus",
      constructor: "node",
      data_model: {
        app_id: "",
        fields: [],
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Default Settings",
              type: "header",
            },
            {
              type: "ghElement",
              property: "app_id",
              data_model: function (fieldModel) {
                return {
                  field_name: "Application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
          ],
          [
            {
              title: "Value Outputs",
              type: "header",
            },
            {
              type: "html",
              class: "option-column_750px",

              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "field_id",
                      type: "field",
                      prop_name: "Field",
                      data_model: function (option) {
                        return {
                          app_id: fieldModel.app_id,
                        };
                      },
                      display: true,
                    },
                    {
                      property: "field_name",
                      type: "text",
                      prop_name: "Field Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.fields" pattern="field_model.patterns"></gh-option-table>',
            },
          ],
        ],
      },
    ];
  }
}
