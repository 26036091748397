
export class DeferredPromise{
  constructor() {
    this._promise = new Promise ((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject;
    })
    this.updater = () => {};
    this.then = this._promise.then.bind(this._promise)
    this.catch = this._promise.catch.bind(this._promise)
  }
  get get() {
    return this._promise
  }
}

export async function awaitDebugger(node, inp, out){
  if(node.data.promise && node.data.promise.updater){
    node.data.promise.updater({
      inputsData : inp,
      outputsData: out
    })
    return node.data.promise.get
  }
}