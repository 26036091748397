import Rete from "rete";
import { MainControl } from "../controls/main_control.js";
import { ifNoConnections } from "../controls/utilities.js";
import { items, field_value } from "./../controls/sockets.js";
import {
  awaitDebugger,
  DeferredPromise,
} from "../controls/automation_debugger";

export class PopUpForm extends Rete.Component {
  constructor() {
    super("Pop Up Form");
  }

  builder(node) {
    const start = new Rete.Input("start", "Start", field_value);
    const message = new Rete.Output("message", "Message", field_value);
    node.data.promise = new DeferredPromise();
    const answers = node.data.answers || [];
    const outputs = answers.map(
      (answer, index) => new Rete.Output(answer.output_name, answer.output_name, field_value)
    );

    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );

    node.addControl(cntr)
        .addInput(start)
        .addOutput(message);

        outputs.forEach((output) => node.addOutput(output));
    return node;
  }


  async worker(node, inputs, outputs, triggeredData, resolve, $injector) {
    console.log("START POUP FORM");
    const GhDialog = $injector.get('GhDialog');
    const start = inputs['start'][0] || null;
    let result = null;
    let goToTheNextStep = false;//we use it to destroy the setInterwall
    
    //If someone closed the Dialog by clicking on Cancel buton or on the cross then we go to the nect step
    let closeCallBack = function(){console.log("closeCallback", goToTheNextStep = true)}

    //We use setInterval stop execusion of the worker
    function waitingForTheNextStep(milisecond) {
      return new Promise(resolve => {
        let interval = setInterval(() => {
          if(goToTheNextStep){
            resolve();
            clearInterval(interval);
          }
        }, milisecond);
      });
    }

    //If we don't have a value on the "Start" input then we go to the nexct connected node
    if(!start) goToTheNextStep = true;

    //-- Openning dialog --//
    if(start)
    GhDialog.show({
      position: 'center',
      closeCallBack: closeCallBack,
      template: {
        toolbar: '',
        content: `<div class="dialog-delgete-app-content">
            <div style="padding-bottom: 15px;">{{data.message}}</div>
            <!--div style="padding-bottom: 10px;"><gh-element value="timeValue" style="display: flex;" decorator="{data_model: {data_range: false, time_picker: true,  interpretation: [{	src: \'form\', settings: {show_field_name: 0}, style: {font_size: 20}}]}, data_type: \'date\', field_name: \'date\'}" elem-src="form" data-position="" element-type="date"></gh-element></div-->
            <button type="button" class="btn btn-grean" ng-repeat="answer in answers" ng-click="answer.func(answer.output_name ,answer.text)">{{answer.text}}</button>
            <button type="button" class="btn btn-blue-reverse" ng-click="cancel()">Cancel</button></div>`
      },
      locals: {
        node: node,
        goToTheNextStep: goToTheNextStep,
        result: result,
        outputs: outputs
      },
      controller: ['$scope', 'node', 'outputs', function($scope, node, outputs) {
        $scope.data = node.data;
        $scope.answers =[];

        // Generating answers wariants for the dialog
        node.data.answers.forEach(answer =>{
          $scope.answers.push({
            text : answer.answer,
            output_name : answer.output_name,
            func : function(outputName, value) {
              goToTheNextStep = true;
              outputs[outputName] = [value];
              result = value;
              $scope.cancel(); 
            }
          });
        });

      }]
    }).then(function(data) {});

    await waitingForTheNextStep(500);
    console.log("result",result);

    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) {
      resolve(result);
    }
  }

  getTemplate() {
    return {
      name: "Pop Up Form",
      icon: "scheduling",
      constructor: "node",
      data_model: {
        question: "",
        answers: [],
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Default Settings",
              type: "header",
              class: "option-column_500px"
            },
            {
              type: "ghElement",
              property: "message",
              data_model(fieldModel) {
                return {
                  field_name: "Message",
                  data_type: "text",
                  data_model: {
                  },
                };
              },
            },
          ],
          [
            {
              title: "Answers",
              type: "header",
              class: "option-column_750px"
            },
            {
              type: "html",
              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "answer",
                      type: "text",
                      prop_name: "Text",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "output_name",
                      type: "text",
                      prop_name: "Output Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    }
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.answers" pattern="field_model.patterns" ></gh-option-table>',
            },
          ],
          
        ],
      }
    ];
  }
}
