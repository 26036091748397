import {itemGetByItemRef} from "./gudhub_api/gudhub_api.js";





//es6 doesn't hoist const/let declarations
const dummy = function(...args){
    return new Promise((resolve, reject) => {resolve("result")});
}

class Actions {
    constructor(){
        this.fileDuplicate = dummy, // (app_id, inpItems, inpFiles, field_id, gudhub)
        this.detectFace = dummy,// async (items, auth_key, app_id)
        this.trainingModel = dummy,// async (items, auth_key, app_id)
        this.apiFieldValueGet = dummy, // async (auth_key, app_id, item_id, element_id)!!!!!!
        this.apiItemGet = dummy, // async (auth_key, app_id, item_id)!!!!!!
        this.createFiles = dummy 
        this.itemGetByItemRef = itemGetByItemRef
    }
}

export const act = new Actions();
