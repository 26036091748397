import Rete from "rete";
import { MainControl } from "./../controls/main_control.js";
import { ifNoConnections } from "./../controls/utilities.js";
import * as sockets from "./../controls/sockets.js";
import {awaitDebugger, DeferredPromise} from "../controls/automation_debugger";

export class ObjectConstructor extends Rete.Component {
  constructor() {
    super("Object Constructor");
  }

  builder(node) {
    const inputsData = node.data.inputs || [];
    const outObject = new Rete.Output("outObject", "Object", sockets.object);
    const inpObject = new Rete.Input("inpObject", "Object", sockets.object);
    const inputs = inputsData.map((input) => {
      const socketType = sockets[input.type] || sockets.field_value;
      return new Rete.Input(input.key.toString(), input.input_name, socketType);
    });
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
        node.data.promise
    );
    node.addControl(cntr).addOutput(outObject).addInput(inpObject);
    inputs.forEach((input) => node.addInput(input));
    return node;
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    const inputsData = node.data.inputs || [];
    const inputObject =  inputs["inpObject"][0] || {};
    
    for (const inpData of inputsData) {
      const value =  inputs[inpData.key][0].join()
      if(value != undefined){
        inputObject[inpData.property_name] = value;
      }
    }
    outputs["outObject"] = [inputObject];
    console.log(node.data.promise)
    
    await awaitDebugger(node, inputs, outputs)
    if (ifNoConnections(node.outputs)) {
      resolve([inputObject]);
    }
  }

  getTemplate() {
    return {
      name: "Object Constructor",
      icon: "plus",
      constructor: "node",
      data_model: {
        app_id: "",
        fields: [],
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Value Inputs",
              type: "header",
            },
            {
              type: "html",
              class: "option-column_750px",

              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "property_name",
                      type: "text",
                      prop_name: "Property Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "type",
                      type: "text_opt",
                      prop_name: "Type",
                      data_model: function (option) {
                        return {
                          options: [
                            {
                              name: "Value",
                              value: "field_value",
                            },
                            {
                              name: "Item",
                              value: "items",
                            },
                            {
                              name: "Object",
                              value: "object",
                            },
                          ],
                        };
                      },
                      display: true,
                    },
                    {
                      property: "input_name",
                      type: "text",
                      prop_name: "Input Socket Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "key",
                      prop_name: "key",
                      type: "number",
                      data_model: function (option) {
                        return {};
                      },
                      getMaxValue: function () {
                        fieldModel.max_value = fieldModel.max_value || 1;
                        return fieldModel.max_value;
                      },
                      setMaxValue: function (maxValue) {
                        fieldModel.max_value = maxValue;
                      },

                      display: false,
                      generate_dynamic: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.inputs" pattern="field_model.patterns"></gh-option-table>',
            },
          ],
        ],
      },
    ];
  }
}
