import Rete from "rete";
import { MainControl } from "./../controls/main_control.js";
import {
  awaitDebugger,
  DeferredPromise,
} from "../controls/automation_debugger.js";
import jsonpath from "jsonpath";
import { ifNoConnections } from "./../controls/utilities.js";
import * as sockets from "./../controls/sockets.js";
import { jsonToProperties } from "../controls/utilities.js";

export class ObjectDestructor extends Rete.Component {
  constructor() {
    super("Object Destructor");
  }

  async builder(node) {
    const outputsData = node.data.outputs || [];
    const inpObject = new Rete.Input(
      "inpObject",
      "Object",
      sockets.object,
      true
    );
    const outputs = outputsData.map((output) => {
      const socketType = sockets[output.type] || sockets.field_value;
      return new Rete.Output(
        output.key.toString(),
        output.output_name,
        socketType
      );
    });
    node.data.promise = new DeferredPromise();

    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );
    node.addControl(cntr).addInput(inpObject);
    outputs.forEach((output) => node.addOutput(output));
    return node;
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    const outputsData = node.data.outputs || [];
    for (const inputObject of inputs["inpObject"]) {
      if (inputObject) {
        for (const outputData of outputsData) {
          try {
            const value = jsonpath[outputData.search_type](
              inputObject,
              outputData.property_name
            );
            let outputValue = [value];
            if (outputData.type === "object") {
              if (typeof value === "string") {
                outputValue = JSON.parse(value);
              } else outputValue = value;
            }
            outputs[outputData.key] = outputValue;
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) {
      resolve();
    }
  }

  getTemplate() {
    return {
      name: "Object Destructor",
      icon: "plus",
      constructor: "node",
      data_model: {
        app_id: "",
        fields: [],
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Mock",
              type: "header",
            },
            {
              type: "ghElement",
              property: "mock",
              data_model: function () {
                return {
                  data_model: {
                    code_mode: "javascript",
                    save_file: false,
                    interpretation: [
                      {
                        src: "form",
                        settings: {
                          show_field_name: 0,
                        },
                      },
                    ],
                  },
                  field_name: "Code Editor",
                  name_space: "code_editor",
                  data_type: "code_editor",
                };
              },
            },
          ],
          [
            {
              title: "Value Outputs",
              type: "header",
            },
            {
              type: "html",
              class: "option-column_750px",

              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "search_type",
                      type: "text_opt",
                      prop_name: "Search Type",
                      data_model: function (option) {
                        return {
                          options: [
                            {
                              name: "Value",
                              value: "value",
                            },
                            {
                              name: "Query",
                              value: "query",
                            },
                          ],
                        };
                      },
                      display: true,
                    },
                    {
                      property: "property_name",
                      type: "text",
                      prop_name: "Property Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "type",
                      type: "text_opt",
                      prop_name: "Type",
                      data_model: function (option) {
                        return {
                          options: [
                            {
                              name: "Value",
                              value: "field_value",
                            },
                            {
                              name: "Object",
                              value: "object",
                            },
                          ],
                        };
                      },
                      display: true,
                    },
                    {
                      property: "output_name",
                      type: "text",
                      prop_name: "Output Socket Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "key",
                      prop_name: "key",
                      type: "number",
                      data_model: function (option) {
                        return {};
                      },
                      getMaxValue: function () {
                        fieldModel.max_value = fieldModel.max_value || 1;
                        return fieldModel.max_value;
                      },
                      setMaxValue: function (maxValue) {
                        fieldModel.max_value = maxValue;
                      },

                      display: false,
                      generate_dynamic: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.outputs" pattern="field_model.patterns"></gh-option-table>',
            },
          ],
          [
            {
              title: "Output Result",
              type: "header",
            },
            {
              type: "ghElement",
              property: "result",
              onInit: function (settingScope) {
                settingScope.$watch(
                  function () {
                    return settingScope.fieldModel;
                  },
                  function (newValue) {
                    const mock =
                      typeof newValue.mock === "string"
                        ? JSON.parse(newValue.mock)
                        : newValue.mock || {};
                    const outputs = newValue.outputs || [];
                    settingScope.field_model.data_model.documentData = jsonToProperties(
                      mock,
                      outputs
                    );
                  },
                  true
                );
              },
              data_model: function (fieldModel) {
                const mock =
                  typeof fieldModel.mock === "string"
                    ? JSON.parse(fieldModel.mock)
                    : fieldModel.mock || {};
                const outputs = fieldModel.outputs || [];
                const documentData = jsonToProperties(mock, outputs);
                return {
                  field_name: "Result",
                  data_type: "json_viewer",
                  data_model: {
                    documentData: documentData,
                    interpretation: [
                      {
                        src: "form",
                        id: "json",
                        settings: {
                          editable: 1,
                          show_field_name: 0,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
          ],
        ],
      },
    ];
  }
}
