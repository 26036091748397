import angular from "angular";
import JSONEditor from "jsoneditor";
require("../../node_modules/jsoneditor/dist/jsoneditor.min.css");
require("./json_editor.scss");
angular.module("jsonEditorModule", []).directive("jsonEditor", [
  "documentManager",
  function (documentManager) {
    return {
      scope: {
        ghField: '=',
        ghAppId: '@',
        ghItemId: '@'
      },
      replace: false,
      restrict: "E",
      controller: [
        "$scope",
        "$element",
        function ($scope, $element) {
          $scope.showTextarea = false;
          
          //initialize JSONEditor
          const container = angular.element($element[0].querySelector(".jsoneditor"))[0];
          const options = {
            search: false,
            mode: $scope.ghField.settings.editable ? $scope.ghField.data_model.mode : 'view'
          };
          const editor = new JSONEditor(container, options);

          //copy JSON
          $scope.copy = () => {
            const textArea = document.createElement("textarea");
            textArea.style.position = "fixed";
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = 0;
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";
            textArea.value = JSON.stringify(editor.get());
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
              const successful = document.execCommand("copy");
              if (successful) {
                console.log("Copied!");
              }
            } catch (err) {
              console.log("Oops, unable to copy");
            }

            document.body.removeChild(textArea);
          };

          let documentInfo = {
            app_id: $scope.ghAppId,
            item_id: $scope.ghItemId,
            element_id: $scope.ghField.element_id,
          };

          if(documentManager.getDocument(documentInfo)) {
            documentManager.getDocument(documentInfo)
            .then(resp => {
              editor.set(JSON.parse(resp.data))
            })
          }

          $scope.save = async () => {
            $scope.saveWaiting = true;
            let documentObj = {
              app_id: $scope.ghAppId,
              item_id: $scope.ghItemId,
              element_id: $scope.ghField.element_id,
              data: JSON.stringify(editor.get())
            };
            await documentManager.createDocument(documentObj)
              .then((response) => {
                if(response.data) $scope.saveWaiting = false;
              });
          };

          $scope.paste = () => {
            $scope.showTextarea = !$scope.showTextarea;
            let textarea = angular.element($element[0].querySelector("#json_paste"))[0];
            if (textarea.value) {
              try {
                $scope.invalidJson = false;
                editor.set(JSON.parse(textarea.value))
              } catch (e) {
                $scope.showTextarea = true;
                $scope.invalidJson = true;
              }
            }
          };
        },
      ],
      template: `<div class="jsoneditor"></div>
      <div ng-show="saveWaiting" class="json_waiting"></div>
      <div class="json_buttons">
        <div class="copy_btn" title="Copy" ng-click="copy()" role="button" tabindex="0"><span gh-icon="double_plus fff 25px normal"></span></div>
        <div class="paste_btn" title="Paste" ng-click="paste()" role="button" tabindex="0"><span gh-icon="file fff 25px normal"></span></div>
        <div class="save_btn" title="Save" ng-click="save()" role="button" tabindex="0"><span gh-icon="disk fff 23px normal"></span></div>
      </div>
      <div class="paste_container" ng-show="showTextarea">
        <p class="json_editor_help">Paste your JSON here and click "Apply"</p>
        <textarea id="json_paste"></textarea>
        <div ng-show="invalidJson" class="json_error">You must type valid JSON</div>
        <div class="apply_btn gh-btn inline gh-btn-bg gh-btn-blue" ng-click="paste()" role="button" tabindex="0"><p>Apply</p></div>
      </div>
      `,
    };
  },
]);
