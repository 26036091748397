import Rete from "rete";
import * as sockets from "./../controls/sockets.js";
import { MainControl } from "../controls/main_control.js";
import { createQueryString, ifNoConnections } from "../controls/utilities.js";
import {
  awaitDebugger,
  DeferredPromise,
} from "../controls/automation_debugger";

export class Request extends Rete.Component {
  constructor() {
    super("Request");
  }

  builder(node) {
    const fields = node.data.fields || [];
    const inpRun = new Rete.Input("inpRun", "RUN", sockets.field_value);
    const inputs = fields.map((field) => {
      const socketType = sockets[field.input_type] || sockets.field_value;
      return new Rete.Input(
        field.key.toString(),
        `${field.option_type} ${field.input_name}`,
        socketType
      );
    });
    const output = new Rete.Output("outObject", "Object", sockets.object);
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );
    if (
      (node.data.request_method === "POST" ||
        node.data.request_method === "PUT") &&
      node.data.body_type === "json"
    ) {
      const inpObject = new Rete.Input("inpObject", "Object", sockets.object);
      node.addInput(inpObject);
    }

    node
      .addControl(cntr)
      .addOutput(output)
      .addInput(inpRun);
    inputs.forEach((input) => node.addInput(input));
    return node;
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    let url = (node.data.url || "").trim();
    let result = {};
    const method = node.data.request_method || "GET";
    // if (!/\/$/.test(url)) {
    //   url = url + "/";
    // }

    const fields = node.data.fields || [];
    const options = {
      params: [],
      query: [],
      headers: [],
      form_data: [],
    };
    for (const field of fields) {
      const inputData = inputs[field.key][0] || [];
      const value =
        field.input_type === "field_value" ? inputData[0] || "" : inputData;
      const data =
        field.option_type === "params" ? value : [field.input_name, value];

      options[field.option_type].push(data);
    }
    url = `${url}${options.params.join("/")}${createQueryString(
      options.query
    )}`;

    const headers = {
      ...Object.fromEntries(options.headers),
    };
    let form;
    if (
      node.data.request_method === "POST" ||
      node.data.request_method === "PUT"
    ) {
      if (node.data.body_type === "json") {
        headers["Content-Type"] = "application/json";
        //--!!!!! Temporary Fix !!!!!! --//
        //From input we receive array of objects so we select first element from arey. It used to be like this inputs.inpObject[0][0]
        form = JSON.stringify(inputs.inpObject[0][0] || {});
      } else {
        form = new FormData();
        options.form_data.forEach((data) => form.append(...data));
      }
    }

    //-- We send request if only when "inpRun" hase a value
    //We do it becouse trigger starts all the time and there no other way to STOP sending requests 
    let sendRequest = inputs["inpRun"][0] ? inputs["inpRun"][0][0] : null;
    
    if(sendRequest){
      const response = await fetch(url, { method, headers, body: form });
      result = await response.json();
      outputs.outObject = result;
      console.log(result);
    }

    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) {
      resolve(result);
    }
  }

  getTemplate() {
    return {
      name: "Request",
      icon: "remote_add",
      constructor: "node",
      data_model: {
        request_method: "GET",
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              type: "header",
              title: "Request Settings",
            },
            {
              class: "option-column_500px",
              type: "ghElement",
              property: "url",
              data_model() {
                return {
                  field_name: "URL address",
                  name_space: "url_address",
                  data_type: "text",
                };
              },
            },
            {
              type: "ghElement",
              property: "request_method",
              data_model() {
                return {
                  field_name: "Request Method",
                  name_space: "request_method",
                  data_type: "radio_button",
                  data_model: {
                    options: [
                      {
                        name: "GET",
                        value: "GET",
                        color: "#05b52c",
                      },
                      {
                        name: "POST",
                        value: "POST",
                        color: "#ffa200",
                      },
                      {
                        name: "PUT",
                        value: "PUT",
                        color: "#0b73b2",
                      },
                      {
                        name: "DELETE",
                        value: "DELETE",
                        color: "#FF0000",
                      },
                    ],
                    interpretation: [
                      {
                        src: "form",
                        id: "current_one",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              class: "option-column_500px",
              type: "ghElement",
              property: "body_type",
              showIf:
                "request_method === 'POST' || fieldModel.request_method === 'PUT'",
              data_model() {
                return {
                  field_name: "Body Type",
                  name_space: "body_type",
                  data_type: "radio_button",
                  data_model: {
                    options: [
                      {
                        name: "JSON",
                        value: "json",
                        color: "#05b52c",
                      },
                      {
                        name: "formData",
                        value: "form_data",
                        color: "#ffa200",
                      },
                    ],
                    interpretation: [
                      {
                        src: "form",
                        id: "current_one",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: "header",
              title: "Inputs Settings",
            },
            {
              type: "html",
              class: "option-column_750px",

              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "option_type",
                      type: "text_opt",
                      prop_name: "Params Type",
                      data_model: function (option) {
                        const options = [];
                        if (fieldModel.request_method) {
                          options.push(
                            { name: "Headers", value: "headers" },
                            { name: "Params", value: "params" },
                            { name: "Query", value: "query" }
                          );
                          if (fieldModel.body_type === "form_data") {
                            options.push({
                              name: "Body formData",
                              value: "form_data",
                            });
                          }
                        }
                        return {
                          options,
                        };
                      },
                      display: true,
                    },
                    {
                      property: "input_name",
                      type: "text",
                      prop_name: "Input Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "input_type",
                      type: "text_opt",
                      prop_name: "Input Type",
                      data_model: function (option) {
                        return {
                          options: [
                            { name: "Field Value", value: "field_value" },
                            { name: "Items", value: "items" },
                            { name: "Object", value: "object" },
                          ],
                        };
                      },
                      display: true,
                    },
                    {
                      property: "key",
                      prop_name: "key",
                      type: "number",
                      data_model: function (option) {
                        return {};
                      },
                      getMaxValue: function () {
                        fieldModel.max_value = fieldModel.max_value || 1;
                        return fieldModel.max_value;
                      },
                      setMaxValue: function (maxValue) {
                        fieldModel.max_value = maxValue;
                      },

                      display: false,
                      generate_dynamic: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.fields" pattern="field_model.patterns" ></gh-option-table>',
            },
          ],
        ],
      },
    ];
  }
}
