import Rete from "rete";
import { MainControl } from "./../controls/main_control.js";
import { ifNoConnections } from "./../controls/utilities.js";
import { object, items } from "./../controls/sockets.js";
import {
  awaitDebugger,
  DeferredPromise,
} from "../controls/automation_debugger";

export class ObjectToItem extends Rete.Component {
  constructor() {
    super("Object To Item");
  }

  builder(node) {
    const inpObject = new Rete.Input("inpObject", "Object", object);
    const outItem = new Rete.Output("outItems", "Items", items);
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );
    node.addControl(cntr).addInput(inpObject).addOutput(outItem);
    return node;
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    const fieldsMap = node.data.fieldsMap || [];
    for(const inpObject of inputs["inpObject"]){
      if(inpObject){
        outputs["outItems"] = gudhub.jsonToItems(inpObject, fieldsMap);
      }
    }
    
    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) {
      resolve();
    }
  }

  getTemplate() {
    return {
      name: "ObjectToItem",
      icon: "pile",
      constructor: "node",
      data_model: {
        app_id: "",
        fields: [],
      },
    };
  }

  getSettings() {
    return [
        {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Mock",
              type: "header",
            },
            {
              type: "ghElement",
              property: "mock",
              data_model: function () {
                return {
                  data_model: {
                    code_mode: "javascript",
                    save_file: false,
                    interpretation: [
                      {
                        src: "form",
                        settings: {
                          show_field_name: 0,
                        },
                      },
                    ],
                  },
                  field_name: "Code Editor",
                  name_space: "code_editor",
                  data_type: "code_editor",
                };
              },
            },
          ],
          [
            {
              title: "Fields Options",
              type: "header",
            },
            {
              type: "ghElement",
              property: "app_id",
              class: 'option-column_500px',
              data_model: function (fieldModel) {
                return {
                  field_name: "Application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: "html",
              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "field_id",
                      type: "field",
                      prop_name: "Field",
                      data_model: function (option) {
                        return {
                          app_id: fieldModel.app_id,
                        };
                      },
                      display: true,
                    },
                    {
                      property: "json_path",
                      type: "text",
                      prop_name: "JSON Path",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.fieldsMap" pattern="field_model.patterns"></gh-option-table>',
            },
          ],
          [
            {
              title: "Output Result",
              type: "header",
            },
            {
              type: "ghElement",
              property: "result",
              onInit: function (settingScope) {
                settingScope.$watch(function () {
                  return settingScope.fieldModel;
                }, function(newValue) {
                  const mock = typeof newValue.mock === 'string' ? JSON.parse(newValue.mock) : newValue.mock || {};
                  const fieldsMap = newValue.fieldsMap || [];
                  settingScope.field_model.data_model.documentData = gudhub.jsonToItems(mock, fieldsMap)
                }, true);
              },
              data_model: function (fieldModel) {
                const mock = typeof fieldModel.mock === 'string' ? JSON.parse(fieldModel.mock) : fieldModel.mock || {};
                const fieldsMap = fieldModel.fieldsMap || [];
                const documentData = gudhub.jsonToItems(mock, fieldsMap); 
                return {
                  field_name: "Result",
                  data_type: "json_viewer",
                  data_model: {
                   documentData: documentData,
                   interpretation: [ {
                     src: "form",
                     id: "json",
                     settings: {
                       editable: 1,
                       show_field_name: 0,
                       show_field: 1,
                     },
                   },]
                  },
                };
              },
            },
          ],
        ],
      },
    ];
  }
}
