

angular.module('fieldDataProcessor', [])

.run([ 'fieldListenerService', function(fieldListenerService){
  fieldListenerService.start();
}])





/*============================================================================*/
/*=======================     FIELD LISTENER SERVICE     =====================*/
/*============================================================================*/
.service('fieldListenerService', [ 'PipeService', 'fieldProcesingService', 'calculation', 'fileManager', function(PipeService, fieldProcesingService, calculation, fileManager){
  this.start = function(){

    /* ---- FIELD VALUE GET LISTENING ---- */
    PipeService.onRoot('gh_value_get', {}, function (event, data) {
      
      /*
      * This checking need to check if all address values received else do nothing.
      * (similar functional was been inside old fieldProcesingService.getFieldValue with deferred.reject)\
      */
      if(data.app_id && data.item_id && data.field_id) {
        fieldProcesingService
          .getFieldValue(data.app_id, data.item_id, data.field_id)
          .then(function (field_value) {
            PipeService.emit("gh_value_get", data, field_value);
          });
      }
    });

    PipeService.onRoot('gh_interpreted_value_get', {}, function(event, data) {
      var evalField =  function (argObj) {
        return new Promise( function (resolve) {

          PipeService.on('gh_model_get', {
            app_id: argObj.app_id,
            field_id: argObj.field_id
          }, async function itemPipe(event, fieldModel) {
            PipeService.destroy('gh_model_get', {app_id: argObj.app_id, field_id: argObj.field_id}, itemPipe);
            if (!fieldModel || !fieldModel.data_type) return resolve('');
  
            switch (fieldModel.data_type) {

              case 'calculator':
              case 'string_join':
                try{
                  if (fieldModel.data_model.is_generate_once || fieldModel.data_model.is_persistent_value) {
                    const field_value = await getValue({
                      app_id: argObj.app_id,
                      item_id: argObj.item_id,
                      field_id: argObj.field_id
                    })
                      if(field_value == null){
                        const result = await calculation.getEvaluatedExpression(fieldModel.data_model.expression, fieldModel.data_model.arguments, argObj.item_id, argObj.app_id)
                        resolve(eval(result));
                      }else{
                        resolve(field_value);
                      }
                  } else {
                    const result = await calculation.getEvaluatedExpression(fieldModel.data_model.expression, fieldModel.data_model.arguments, argObj.item_id, argObj.app_id)
                      resolve(eval(result));
                  }
                } catch(err){
                  console.warn("Field Type:",fieldModel.data_type,"itemID",argObj.item_id, "fieldID", argObj.field_id, err);
                  resolve(null)
                }
                break;

              case 'data_ref':
                const address = {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id:  fieldModel.field_id,
                }
                
                let field_value = await getValue(address);
                if (fieldModel.data_model.use_self_value && field_value != null) {
                  resolve(field_value);
                }  else {
                  address.field_id = fieldModel.data_model.watch.field_id
                  field_value = await getValue(address);
                  if(!field_value){
                    resolve("");
                  }else {
                  const [appIdFromValue, itemIdFromValue] = field_value.split(".");
                  const foundedCases = fieldModel.data_model.cases.filter(
                    (eachCase) => eachCase.app_id == appIdFromValue
                  );
                  if (foundedCases.length) {
                    foundedCases.forEach(foundedCase => {
                      evalField({
                        app_id: appIdFromValue,
                        item_id: itemIdFromValue,
                        field_id: foundedCase.field_id,
                      }).then(evalFieldRes => resolve(evalFieldRes));
                    })
                  } else {
                    resolve("");
                  }
                  }
                }
                break;

              case 'item_ref':
                PipeService.on('gh_value_get', {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.field_id
                }, function itemPipe(event, field_value) {
                  PipeService.destroy('gh_value_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, itemPipe);

                  if (!field_value) {
                    resolve('');
                  } else {
                    Promise.all(field_value.split(',').map((currFieldVal) => {
                      var promise = Promise.resolve();
                      fieldModel.data_model.refs.forEach(function (currentRef) {
                        if (currentRef.app_id == currFieldVal.split('.')[0]) {
                          promise = evalField({
                            app_id: currFieldVal.split('.')[0],
                            item_id: currFieldVal.split('.')[1],
                            field_id: currentRef.field_id
                          });
                        }
                      });
                      return promise;
                    })).then((vals) => {
                      resolve(vals.join(', '))});
                  }


                }).emit('gh_value_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});
                break;

                case 'field':
                PipeService.on('gh_value_get', {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.field_id
                }, function itemPipe(event, field_value) {
                   if(field_value){
                          var fieldValue = field_value.split(',');
                          var fielName = '';
                            fieldValue.forEach(function(value, index){
                              PipeService.on('gh_model_get', {
                                app_id: fieldModel.data_model.app_id,
                                field_id: value
                              }, function modelGet(event, data) {
                                if(data.field_name != undefined){
                                  fielName += data.field_name + " "
                                }
                                if(index+1 == fieldValue.length){
                                  resolve(fielName);
                                }
                                PipeService.destroy('gh_model_get', {
                                  app_id: fieldModel.data_model.app_id,
                                  field_id: value
                                }, modelGet);

                            }).emit('gh_model_get', {}, {app_id: fieldModel.data_model.app_id,field_id: value});
                          })
                        }else{
                          resolve('');
                        }
                  PipeService.destroy('gh_value_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, itemPipe);

                }).emit('gh_value_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});
                break;

              case 'quote_tool':
                PipeService.on('gh_value_get', {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.field_id
                }, function itemPipe(event, field_value) {

                  PipeService.destroy('gh_value_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, itemPipe);


                  if (!field_value) {
                    resolve('');
                  } else {
                    fileManager.downloadFileFromString(argObj.app_id, field_value).then((res) => {
                      try{
                        resolve(JSON.stringify(res.data));
                      } catch (err){
                        resolve('');
                      }
                    }, () => {
                      resolve('');
                    });
                  }

                }).emit('gh_value_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});
                break;

              case 'text_opt':
                PipeService.on('gh_value_get', {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.field_id
                }, function itemPipe(event, field_value) {

                  PipeService.on('gh_model_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, function fieldModelGet(event, field_model) {

                    PipeService.destroy('gh_model_get', {
                      app_id: argObj.app_id,
                      item_id: argObj.item_id,
                      field_id: argObj.field_id
                    }, fieldModelGet);

                    if(field_value){
                      var fieldValue = field_value.split(',');
                      var fielName = '';
                      fieldValue.forEach(function(value, index){
                        var option = field_model.data_model.options.find(function(opt){
                          return opt.value == value;
                        })
                        if(option) {
                           fielName += option.name + " ";
                        }
                      })
                    }
                    resolve(field_value ? fielName : '');

                  }).emit('gh_model_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});

                  PipeService.destroy('gh_value_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, itemPipe);

                }).emit('gh_value_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});
                break;

              case 'tag':
                PipeService.on('gh_value_get', {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.field_id
                }, function itemPipe(event, field_value) {
                  PipeService.on('gh_model_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, function fieldModelGet(event, field_model) {

                    PipeService.destroy('gh_model_get', {
                      app_id: argObj.app_id,
                      item_id: argObj.item_id,
                      field_id: argObj.field_id
                    }, fieldModelGet);

                    if(field_value){
                        var fieldValue = field_value.split(',');
                        var fielName = '';
                        fieldValue.forEach(function(value, index){
                          var option = field_model.data_model.options.find(function(opt){
                            return opt.value == value;
                          })
                          if(option){
                            fielName += option.name + " " ;
                          }
                        })
                      }
                      resolve(field_value ? fielName : '');

                  }).emit('gh_model_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});

                  PipeService.destroy('gh_value_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, itemPipe);

                }).emit('gh_value_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});
                break;

              case 'radio_button':
                PipeService.on('gh_value_get', {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.field_id
                }, function itemPipe(event, field_value) {

                  PipeService.on('gh_model_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, function fieldModelGet(event, field_model) {

                    PipeService.destroy('gh_model_get', {
                      app_id: argObj.app_id,
                      item_id: argObj.item_id,
                      field_id: argObj.field_id
                    }, fieldModelGet);

                    if(field_value){
                      var fieldValue = field_value.split(',');
                      var fielName = '';
                      fieldValue.forEach(function(value, index){
                        var option = field_model.data_model.options.find(function(opt){
                          return opt.value == value;
                        })
                        if(option) {
                          fielName += option.name + " ";
                        }
                      })
                    }
                    resolve(field_value ? fielName : '');

                  }).emit('gh_model_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});

                  PipeService.destroy('gh_value_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, itemPipe);

                }).emit('gh_value_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});
                break;


              case 'quote_tool':
                PipeService.on('gh_value_get', {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.field_id
                }, function itemPipe(event, field_value) {

                  PipeService.destroy('gh_value_get', {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  }, itemPipe);


                  if (!field_value) {
                    resolve('');
                  } else {
                    fileManager.downloadFileFromString(argObj.app_id, field_value).then((res) => {
                      try{
                        resolve(JSON.stringify(res.data));
                      } catch (err){
                        resolve('');
                      }
                    }, () => {
                      resolve('');
                    });
                  }

                }).emit('gh_value_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});
								break;

							case 'doc_template' :
								const getDocTemplateValueOptions = {
									app_id: argObj.app_id,
									item_id: argObj.item_id,
									field_id: argObj.field_id
								};
								PipeService.on('gh_value_get', getDocTemplateValueOptions, function getFile(event, fieldValue) {
									if (!fieldValue) {
										resolve('')
									}
									fileManager.getFile(argObj.app_id, fieldValue).then(file => {
										resolve(file.url);
									});

									PipeService.destroy('gh_value_get', getDocTemplateValueOptions, getFile);
								}).emit('gh_value_get', {}, getDocTemplateValueOptions);
								break;

              case 'text_editor' :
              case 'tinymse' :
								const getTextEditorValueOptions = {
									app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.field_id
								};
								PipeService.on('gh_value_get', getTextEditorValueOptions, function getFile(event, fieldValue) {
									if (!fieldValue) {
										resolve('')
									}
									fileManager.getFile(argObj.app_id, fieldValue).then(file => {
                    resolve(file.url + '?timestamp=' + file.last_update);                    
									});

									PipeService.destroy('gh_value_get', getTextEditorValueOptions, getFile);
								}).emit('gh_value_get', {}, getTextEditorValueOptions);
                break;

              case 'file':
							case 'image' :
								const getImageValueOptions = {
									app_id: argObj.app_id,
                  item_id: argObj.item_id,
                  field_id: argObj.field_id
								};
								PipeService.on('gh_value_get', getImageValueOptions, function getImage(event, fieldValue) {
									if (!fieldValue) {
										resolve('')
                  }
                  PipeService.on('gh_model_get', getImageValueOptions, function fieldModelGet(event, field_model) {

                    PipeService.destroy('gh_model_get', getImageValueOptions, fieldModelGet);
                    let fieldValueArray = fieldValue.split(',')
                    
                      if(field_model.data_model.display_mode == 'multiple') {
                        fileManager.getFiles(argObj.app_id, fieldValueArray).then(
                          files => {
                            let multipleFiles = ''
                            files.forEach( (file, index) => {
                              multipleFiles += file.url + ','
                            })
                            resolve(multipleFiles.slice(0,-1))
                          },
                          error => resolve('no image')
                        );
                      }
                      else {
                        fileManager.getFile(argObj.app_id, fieldValue).then(
                          file => {
                            resolve(file.url)},
                          error => resolve('no image')
                        );
                      }
                    

                  }).emit('gh_model_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id, field_id: argObj.field_id});

									PipeService.destroy('gh_value_get', getImageValueOptions, getImage);
								}).emit('gh_value_get', {}, getImageValueOptions);
								break;
              
                case 'date' :
                  const getDateTimeStemp = {
                    app_id: argObj.app_id,
                    item_id: argObj.item_id,
                    field_id: argObj.field_id
                  };
                  PipeService.on('gh_value_get', getDateTimeStemp, function getStringDate(event, fieldValue) {
                    if (!fieldValue) {
                      resolve('')
                    }
                    //converting date to "Tue Jan 11 2022 10:14:40" format to be acecteble in the spread sheet
                    let stringDate = new Date(+fieldValue).toString();
                    let result = stringDate.substring(0,stringDate.indexOf(" GMT"));
                    resolve(result);

                    PipeService.destroy('gh_value_get', getDateTimeStemp, getStringDate);
                  }).emit('gh_value_get', {}, getDateTimeStemp);
                  break;

              default:
                PipeService.on('gh_item_get', {
                  app_id: argObj.app_id,
                  item_id: argObj.item_id
                }, function itemPipe(event, item) {
                  PipeService.destroy('gh_item_get', {app_id: argObj.app_id, item_id: argObj.item_id}, itemPipe);

                  if (!item) {
                    resolve('');
                    return ;
                  }

                  var fieldExist = false;

                  angular.forEach(item.fields, function (appField) {
                    if (appField.field_id == argObj.field_id) {
                      // cachedValues[args] = !result[0] || typeof result[0] === 'number' ? result[0] : result[0].match(/[+-]?\d+(\.\d+)?/) && result[0].match(/[+-]?\d+(\.\d+)?/)[0] || '';
                      fieldExist = true;
                      resolve(appField.field_value);
                    }
                  });
                  if (!fieldExist) {
                    resolve('');
                  }
                }).emit('gh_item_get', {}, {app_id: argObj.app_id, item_id: argObj.item_id});
            }

          }).emit('gh_model_get', {}, {app_id: argObj.app_id, field_id: argObj.field_id});
        });
      };
      
      function getValue(address){
        return new Promise (resolve => {
          PipeService.on("gh_value_get", address, function valuePipe(event, value){
            resolve(value);
            PipeService.destroy("gh_value_get", address, valuePipe);
          }).emit("gh_value_get", {}, address)
        })
      }

      evalField(data).then(function (field_value) {
        PipeService.emit('gh_interpreted_value_get', data, field_value);
      });
    });

    /* ---- FIELD VALUE SET LISTENING ---- */
    PipeService.onRoot('gh_value_set', {}, function(event, data){

      if(data.item_id){
        fieldProcesingService.setFieldValue(data.app_id, data.item_id, data.field_id, data.new_value).then(function(newFieldValue){
          var val = data.new_value;
          delete data.new_value;
          // PipeService.emit('gh_value_update', data, val); Duplicated call of gh_value_update
        });
      }
/*
      if(!data.item_id){
        var val = data.new_value;
        delete data.new_value;
        PipeService.emit('gh_value_update', data, val);
      }
*/
    });

    /* ---- FIELD MODEL LISTENING ---- */
    PipeService.onRoot('gh_model_get', {}, function (event, data) {
      fieldProcesingService.getField(data.app_id, data.field_id)
        .then(function (field_model) {
          PipeService.emit('gh_model_get', data, field_model);
        })
        .catch((err) => {
          console.log("Field model :", err)
        });
    });

    PipeService.onRoot('gh_model_test_get', {}, function (event, data) {

          PipeService.emit('gh_model_test_get', data, {data_type: 'test'});

    });

    PipeService.onRoot('gh_model_update', {}, function (event, data) {
      fieldProcesingService.updateField(data.app_id, data.field_model)
        .then(function (field_model) {
          PipeService.emit('gh_model_update', {app_id: data.app_id, field_id: data.field_id}, field_model);
        });
    });

    PipeService.onRoot('gh_models_get', {}, function (event, data) {
      fieldProcesingService.getFieldModels(data.app_id)
        .then(function (fieldList) {
          PipeService.emit('gh_models_get', data, fieldList);
        });
    });

    PipeService.onRoot('gh_model_delete', {}, function (event, data) {
      fieldProcesingService.deleteField(data.app_id, data.field_id)
        .then(function (status) {
          PipeService.emit('gh_model_delete', data, status);
        });
    });

  };

  return this;
}])








/*============================================================================*/
/*==============     APPLICATION FIELD DATA PROCESSING SERVICE     ===========*/
/*============================================================================*/
/*  this is the main method where we initiate data exchanging between server and client*/

.factory('fieldProcesingService', ['$q', 'authService', 'appDataProcesingService', function($q, authService, appDataProcesingService) {
  return gudhub.fieldProcessor
  /*=============================     FIELD     ==============================*/
  /*==========================================================================*/
  /* ------------  Returns the object of field data_model ------------------- */
  // function getField(app_id, field_id) {
  //   var deferred = $q.defer();
  //
  //   if(!app_id || !field_id){
  //     deferred.reject();
  //   }
  //
  //   appDataProcesingService.getApp(app_id).then(function(app) {
  //
  //     var element_field_model = {};
  //
  //     angular.forEach(app.field_list, function(field_model){
  //       if(field_model.field_id == field_id){
  //         element_field_model = field_model;
  //       }
  //     });
  //
  //     deferred.resolve(element_field_model);
  //
  //   }, function(err){
  //     deferred.reject();
  //   });
  //
  //   return deferred.promise;
  // }
  //
  // /* ----------  Returns the object list of fields data_model --------------- */
  // function getFieldModels(app_id) {
  //   var deferred = $q.defer();
  //
  //   if(!app_id){
  //     deferred.reject();
  //   }
  //
  //   appDataProcesingService.getApp(app_id).then(function(app) {
  //     deferred.resolve(app.field_list);
  //   }, function(err){
  //     deferred.reject();
  //   });
  //
  //   return deferred.promise;
  // }
  //
  // /* ----------------  Update field_model in field_list---------------------- */
  // function updateField(appId, fieldModel) {
  //   var deferred = $q.defer(), newModel;
  //
  //   if(!appId || !fieldModel){
  //     deferred.reject();
  //   }
  //   authService.getToken()
  //     .then(function (token) {
  //       if(fieldModel.data_model){
  //         return fieldApi.updateField(token, appId, fieldModel);
  //       }
  //       return fieldModel;
  //     })
  //     .then(function (newModel) {
  //       return fieldStorageService.updateField(appId, newModel);
  //     })
  //     .then(deferred.resolve)
  //     .catch(deferred.reject);
  //
  //   return deferred.promise;
  // }
  //
  // /* ---------- Deleting field on server and in the storage ----------------- */
  // function deleteField(app_id, field_id) {
  //   var deferred = $q.defer();
  //
  //   authService.getToken().then(function(token) {
  //     // Send request to server
  //     fieldApi.deleteField(token, field_id).then(function(){
  //       // Delete field information from storage
  //       fieldStorageService.deleteField(app_id, field_id).then(function(){
  //         deferred.resolve(true);
  //       }, () => {
  //         deferred.resolve(false);
  //       });
  //     }, function(){
  //       deferred.reject();
  //     });
  //   }, function() {
  //     deferred.reject();
  //   });
  //
  //   return deferred.promise;
  // }
  //
  //
  //
  // /*===========================     FIELD VALUE    ===========================*/
  // /*==========================================================================*/
  // /* -------------------  Returns the field value --------------------------- */
  // function getFieldValue(app_id, item_id, field_id) {
  //   var deferred = $q.defer();
  //
  //   if(!app_id || !item_id || !field_id){
  //     deferred.reject();
  //   }
  //
  //   appDataProcesingService.getApp(app_id).then(function(app) {
  //
  //     var field_value = null;
  //     angular.forEach(app.items_list, function(item){
  //
  //       if(item.item_id == item_id){
  //         var field = item.fields.filter(function(field){
  //           return field.field_id == field_id;
  //         })[0];
  //
  //         if(field){
  //           field_value = field.field_value;
  //         }
  //       }
  //     });
  //     deferred.resolve(field_value);
  //
  //   }, function(err){
  //     deferred.reject();
  //   });
  //
  //   return deferred.promise;
  // }
  //
  // /* --------------------  Set new value to field --------------------------- */
  // function setFieldValue(appId, itemId, fieldId, newValue) {
  //   var deferred = $q.defer();
  //
  //   if(!appId || !itemId || !fieldId){
  //     deferred.reject();
  //   }
  //
  //   authService.getToken().then(function(token) {
  //     // Send request to server
  //     fieldApi.setFieldValue(token, appId, itemId, fieldId, newValue).then(function(){
  //       // Update field value in storage
  //       fieldStorageService.updateFieldValue(appId, itemId, fieldId, newValue).then(function(){
  //         deferred.resolve();
  //       }, function(){
  //         deferred.reject();
  //       });
  //     }, function(){
  //       deferred.reject();
  //     });
  //   }, function() {
  //     deferred.reject();
  //   });
  //
  //   return deferred.promise;
  // }
  //
  // return {
  //   getField: getField,
  //   getFieldModels: getFieldModels,
  //   updateField: updateField,
  //   deleteField: deleteField,
  //   getFieldValue: getFieldValue,
  //   setFieldValue: setFieldValue
  // };

}])




/*============================================================================*/
/*====================     APPLICATION FIELD API     =========================*/
/*============================================================================*/
// .factory('fieldApi', ['$q', 'cnfg', 'appDataProcesingService', '$httpParamSerializerJQLike', '$http', 'itemsDataProcessing', function($q, cnfg, appDataProcesingService, $httpParamSerializerJQLike, $http, itemsDataProcessing) {
//
//   /*=============================     FIELD     ==============================*/
//   /*==========================================================================*/
//   /* -------------------------  Delete field -------------------------------- */
//   function deleteField(token, id) {
//     var requestConfig = {
//       method: 'POST',
//       url: cnfg.server_url + '/api/app/delete-field',
//       headers: {'Content-Type': 'application/x-www-form-urlencoded'},
//       data: $httpParamSerializerJQLike({
//         token: token,
//         field_id: id
//       })
//     };
//     return $http(requestConfig);
//   }
//
//   function updateField(token, appId, fieldModel) {
//     var deferred = $q.defer(), newModel;
//
//     appDataProcesingService.getApp(appId)
//       .then(function (app) {
//
//         var appObj = {
//           'app_id': app.app_id,
//           'app_name': app.app_name,
//           'group_id': app.group_id,
//           'icon': app.icon,
//           'field_list': angular.copy(app.field_list),
//           'views_list': app.views_list
//         };
//
//         angular.forEach(appObj.field_list, function (storageFieldModel) {
//           if (storageFieldModel.field_id == fieldModel.field_id) {
//             angular.extend(storageFieldModel, fieldModel || {});
//             newModel = storageFieldModel;
//           }
//         });
//
//         return appDataProcesingService.updateApp(appObj);
//       })
//       .then(function () {
//         deferred.resolve(newModel);
//       })
//       .catch(function () {
//         deferred.reject();
//       });
//
//     return deferred.promise;
//   }
//
//
//
//   /*===========================     FIELD VALUE    ===========================*/
//   /*==========================================================================*/
//   function setFieldValue(token, appId, itemId, fieldId, newValue){
//     var deferred = $q.defer();
//
//     let itemForUpdate = [{item_id: itemId,
//                 fields: [{
//                   field_id: fieldId,
//                   field_value: newValue
//                 }]
//         }]
//
//
//     itemsDataProcessing.updateItems(appId, itemForUpdate).then(function(items){
//           deferred.resolve();
//     }, function(){
//           deferred.reject();
//     });
//
//     return deferred.promise;
//   }
//
//
//
//
//
//   return {
//     deleteField: deleteField,
//     updateField: updateField,
//     setFieldValue: setFieldValue
//   };
//
// }])






/*============================================================================*/
/*==============     APPLICATION FIELD STORAGE SERVICE     ===================*/
/*============================================================================*/
// .service('fieldStorageService', ['$q', 'storage', 'appDataProcesingService', function($q, storage, appDataProcesingService) {
//
//   /* -------------------------  Delete field -------------------------------- */
//   function deleteField(appId, fieldId) {
//     var deferred = $q.defer();
//
//     const storageApp = storage.getApp( appId );
//
//       // Delete field_model from app
//       angular.forEach(storageApp.field_list, function(field_model, i){
//         if(field_model.field_id == fieldId){
//           storageApp.field_list.splice(i, 1);
//         }
//       });
//
//       // Delete field in all items
//       angular.forEach(storageApp.items_list, function(item, i){
//         angular.forEach(item.fields, function(field, k){
//           if(field.field_id == fieldId){
//             item.fields.splice(k, 1);
//           }
//         });
//       });
//
//       deferred.resolve();
//    
//
//     return deferred.promise;
//   }
//
//   /* -------------------------  Update field -------------------------------- */
//   function updateField(appId, fieldModel){
//     var deferred = $q.defer();
//
//     const storageApp = storage.getApp( appId );
//
//       var newModel;
//       angular.forEach(storageApp.field_list, function(storageFieldModel){
//         if(storageFieldModel.field_id == fieldModel.field_id){
//           angular.extend(storageFieldModel, fieldModel);
//           newModel = storageFieldModel;
//         }
//       });
//
//       deferred.resolve(newModel);
//     return deferred.promise;
//   }
//
//   /* -----------------------  Update field value ---------------------------- */
//   function updateFieldValue(appId, itemId, fieldId, newValue){
//     // var deferred = $q.defer();
// /*
//     storage.getAppFromStorage(appId).then(function( storageApp ) {
//
//       // Delete field in all items
//       angular.forEach(storageApp.items_list, function(item){
//         if(item.item_id == itemId){
//           angular.forEach(item.fields, function(field){
//             if(field.field_id == fieldId){
//               field.field_value = newValue;
//               deferred.resolve();
//             }
//           });
//         }
//       });
//
//       deferred.reject();
//     });
//     */
//     return $q.when();
//   }
//
//   return {
//     deleteField: deleteField,
//     updateField: updateField,
//     updateFieldValue: updateFieldValue
//   };
// }]);
