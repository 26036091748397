import Rete from "rete";
import { MainControl } from "../controls/main_control.js";
import { ifNoConnections } from "../controls/utilities.js";
import * as sockets from "../controls/sockets.js";
import {awaitDebugger, DeferredPromise} from "../controls/automation_debugger";

export class MessageConstructor extends Rete.Component {
  constructor() {
    super("Message Constructor");
  }

  builder(node) {
    const inputsData = node.data.inputs || [];
    const outMessage = new Rete.Output("outMessage", "Message", sockets.field_value);
    const inputMessageTemplate = new Rete.Input("inputMessageTemplate", "Message Template", sockets.field_value,true);
    const inputs = inputsData.map((input) => {
      return new Rete.Input(input.key.toString(), input.input_name, sockets.field_value);
    });
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
        node.data.promise
    );
    node
      .addControl(cntr)
      .addOutput(outMessage)
      .addInput(inputMessageTemplate);
    inputs.forEach((input) => node.addInput(input));
    return node;
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    const inputsData = node.data.inputs || [];
    let message = null;
    
    inputs["inputMessageTemplate"].forEach(inputMessageTemplate => {
      let messageTemplate = inputMessageTemplate ?  inputMessageTemplate[0] : false;

      if(messageTemplate){
        for (const inpData of inputsData) {
          if(inputs[inpData.key][0]){
            const value =  inputs[inpData.key][0].join();
            const regExp = new RegExp('\\*\\|' + inpData.variable_name + '\\|\\*','g');
            messageTemplate = messageTemplate.replace(regExp, value);
          }else console.warn("MessageConstructor:", inpData.variable_name, inputs[inpData.key][0]);
        }

        message = messageTemplate;
      }
      
    });
    

    outputs["outMessage"] = [message];
    
    await awaitDebugger(node, inputs, outputs)
    if (ifNoConnections(node.outputs)) {
      resolve([inputObject]);
    }
  }

  getTemplate() {
    return {
      name: "Message Constructor",
      icon: "speech_bubble",
      constructor: "node",
      data_model: {
        app_id: "",
        fields: [],
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Value Inputs",
              type: "header",
            },
            {
              type: "html",
              class: "option-column_750px",
              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "input_name",
                      type: "text",
                      prop_name: "Input Socket Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "variable_name",
                      type: "text",
                      prop_name: "Variable Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "key",
                      prop_name: "key",
                      type: "number",
                      data_model: function (option) {
                        return {};
                      },
                      getMaxValue: function () {
                        fieldModel.max_value = fieldModel.max_value || 1;
                        return fieldModel.max_value;
                      },
                      setMaxValue: function (maxValue) {
                        fieldModel.max_value = maxValue;
                      },

                      display: false,
                      generate_dynamic: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.inputs" pattern="field_model.patterns"></gh-option-table>',
            },
          ],
        ],
      },
    ];
  }
}
