import Rete from "rete";
import { MainControl } from "./../controls/main_control.js";
import { ifNoConnections } from "./../controls/utilities.js";
import { items } from "./../controls/sockets.js";
import {
  awaitDebugger,
  DeferredPromise,
} from "../controls/automation_debugger";

export class PopulateWithItemRef extends Rete.Component {
  constructor() {
    super("Populate with ItemRef");
  }

  builder(node) {
    const srcItems = new Rete.Input("srcItems", "Source items", items);
    const destItems = new Rete.Input("destItems", "Destination Items", items);
    const newItems = new Rete.Output("newItems", "New items", items);
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
      node.data.promise
    );
    node
      .addControl(cntr)
      .addInput(srcItems)
      .addInput(destItems)
      .addOutput(newItems);
    return node;
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    const srcItems = inputs["srcItems"][0] || [];
    const destItems = inputs["destItems"][0] || [];
    const srcFieldId = node.data.srcFieldId;
    const destFieldId = node.data.destFieldId;
    const srcAppId = node.data.src_app_id;
    const result = gudhub.populateWithItemRef(
      srcItems,
      srcFieldId,
      destItems,
      destFieldId,
      srcAppId
    );
    outputs["newItems"] = result;
    await awaitDebugger(node, inputs, outputs);
    if (ifNoConnections(node.outputs)) {
      resolve(result);
    }
  }

  getTemplate() {
    return {
      name: "Populate with ItemRef",
      icon: "table",
      constructor: "node",
      data_model: {
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              type: "ghElement",
              property: "src_app_id",
              data_model: function (fieldModel) {
                return {
                  field_name: "Source application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: "ghElement",
              property: "srcFieldId",
              onInit: function (settingScope,) {
                settingScope.$watch(function () {
                  return settingScope.fieldModel.src_app_id;
                }, function(newValue) {
                  settingScope.field_model.data_model.app_id  = newValue;
                });
              },
              data_model: function (fieldModel) {
                return {
                  data_model: {
                    app_id: fieldModel.src_app_id,
                  },
                  field_name: "Source field",
                  name_space: "source_field",
                  data_type: "field",
                };
              },
            },
            {
              type: "ghElement",
              property: "dest_app_id",
              data_model: function (fieldModel) {
                return {
                  field_name: "Dest application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: "ghElement",
              property: "destFieldId",
              onInit: function (settingScope,) {
                settingScope.$watch(function () {
                  return settingScope.fieldModel.dest_app_id;
                }, function(newValue) {
                  settingScope.field_model.data_model.app_id = newValue;
                });
              },
              data_model: function (fieldModel) {
                return {
                  data_model: {
                    app_id: fieldModel.dest_app_id,
                  },
                  field_name: "Dest field",
                  name_space: "dest_field",
                  data_type: "field",
                };
              },
            },
          ],
        ],
      },
    ];
  }
}
