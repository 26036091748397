import Rete from "rete";
import { NoEditControl } from "../controls/no_edit_control.js";
import { object } from "../controls/sockets.js";
import { ifNoConnections } from "../controls/utilities.js";

export class API extends Rete.Component {
  constructor() {
    super("API");
  }

  builder(node) {
    const getMethod = new Rete.Output("GET", "GET", object, true);
    const headMethod = new Rete.Output("HEAD", "HEAD", object, true);
    const postMethod = new Rete.Output("POST", "POST", object, true);
    const putMethod = new Rete.Output("PUT", "PUT", object, true);
    const deleteMethod = new Rete.Output("DELETE", "DELETE", object, true);
    const cntr = new NoEditControl(
      this.editor,
      "text",
      false,
      this.getTemplate()
    );

    return node
      .addControl(cntr)
      .addOutput(getMethod)
      .addOutput(headMethod)
      .addOutput(postMethod)
      .addOutput(putMethod)
      .addOutput(deleteMethod);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, reject) {
    try {
      switch (triggeredData.api) {
        case "GET":
          outputs["GET"] = triggeredData.request;
          break;
        case "HEAD":
          outputs["HEAD"] = triggeredData.request;
          break;
        case "POST":
          outputs["POST"] = triggeredData.request;
          break;
        case "PUT":
          outputs["PUT"] = triggeredData.request;
          break;
        case "DELETE":
          outputs["DELETE"] = triggeredData.request;
          break;
        default:
      }

      if (ifNoConnections(node.outputs)) resolve(triggeredData.response);
    } catch (err) {
      reject(err);
    }
  }

  getTemplate() {
    return {
      name: "API",
      icon: "remote_add",
      constructor: "api",
      data_model: {},
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Default Settings",
              type: "header",
            },
            {
              type: "ghElement",
              property: "app_id",
              data_model: function (fieldModel) {
                return {
                  field_name: "Application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: "html",
              onInit: function (settingScope) {
                settingScope.$watch(
                  function () {
                    return settingScope.fieldModel.app_id;
                  },
                  function (newValue) {
                    settingScope.field_model.recipient.app_id = newValue;
                  }
                );
              },
              data_model: function (fieldModel) {
                return {
                  recipient: {
                    app_id: fieldModel.app_id,
                  },
                };
              },
              control:
                '<gh-filter gh-filter-data-model="field_model" filter-list="fieldModel.filters_list" gh-mode="variable"></gh-filter>',
            },
          ],
        ],
      },
    ];
  }
}
