export const itemGetByItemRef = async function (items, item_ref_field_id, gudhub, inputType){
    let result = [];
    let itemsRefs = [];

    switch(inputType) {
        case 'values':
            items.forEach(item => {
                let newArrey = item.split(",");
                itemsRefs = itemsRefs.concat(newArrey);
            });
            return _getItemsByRefsApi(itemsRefs, gudhub);     


        case 'items':
            items.forEach(item => {
                //-- Getting Item reference field_value from Item
                let itemRefField = item.fields.find(field => field.field_id == item_ref_field_id);
                if( itemRefField ){
                    let newArrey = itemRefField.field_value.toString().split(",")
                    itemsRefs = itemsRefs.concat(newArrey);
                }
            });
            return _getItemsByRefsApi(itemsRefs, gudhub);

        default:
          console.warn("itemGetByItemRef: inputType is undefined");
          return [];
      }
}



const _getItemsByRefsApi = function(itemsRefs, gudhub){
    let result = [];

    //-- Getting unique items only
    let uniqueItemsRefs = [...new Set(itemsRefs)];

    //-- Receiving items from Java Server
    uniqueItemsRefs.forEach(itemsRef => {
        let appId_itemId = itemsRef.toString().split('.');
        result.push( gudhub.getItem(...appId_itemId) );
    });


    //-- In case we didn't find fields with item_reference then we return empty arrey
    if(result.length){
        return  Promise.all(result);
    } else return result;
    
}
