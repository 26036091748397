import { NoEditControl } from "../controls/no_edit_control.js";
import Rete from "rete";
import { object, field_value } from "./../controls/sockets.js";

export class Response extends Rete.Component {
  constructor() {
    super("Response");
  }

  builder(node) {
    var inputObject = new Rete.Input("inputObject", "Object", object);
    var inputHtml = new Rete.Input("inputHTML", "HTML", field_value);
    var inputXML = new Rete.Input("inputXML", "XML", field_value);
    var cntr = new NoEditControl(
      this.editor,
      "text",
      false,
      this.getTemplate()
    );

    return node.addControl(cntr).addInput(inputObject).addInput(inputHtml).addInput(inputXML);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    let responce = {
      "json" : inputs["inputObject"][0] || null,
      "html" : inputs["inputHTML"][0] || null,
      "xml" : inputs["inputXML"][0] || null
    }

    resolve(responce);
  }

  getTemplate() {
    return {
      name: "Response",
      icon: "remote_add",
      constructor: "resolve",
      data_model: {},
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [],
      },
    ];
  }
}
