import { MainControl } from "./../controls/main_control.js";
import Rete from "rete";
import * as sockets from "./../controls/sockets.js";
import {getConditionFunc,} from "./../controls/utilities.js";
import {awaitDebugger, DeferredPromise} from "../controls/automation_debugger";

export class ifCondition extends Rete.Component {
  constructor() {
    super("If Condition");
  }

  builder(node) {
    const inputs = node.data.inputs || [];
    const outputs = node.data.outputs || [];
    const inpSockets = inputs.map(
      (input) =>
        new Rete.Input(input.key.toString(), input.field_name, sockets[input.type])
    );
    const outSockets = outputs.map(
      (output) =>
        new Rete.Output(output.key.toString(), output.field_name, sockets[output.type])
    );

    const socketType = node.data.is_item == 'item' ? sockets.items : sockets.field_value;
    const inpData = new Rete.Input("inpData", "IF", socketType, true);
    
    node.data.promise = new DeferredPromise();
    const cntr = new MainControl(
      this.editor,
      "text",
      false,
      node,
      this.getSettings(),
      this.getTemplate(),
        node.data.promise
    );

    inpSockets.forEach((inpSocket) => node.addInput(inpSocket));
    outSockets.forEach((outSocket) => node.addOutput(outSocket));
    return node.addControl(cntr).addInput(inpData);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    const ifDataType = node.data.is_item;
    const inpData = inputs.inpData[0] || [];
    const outputsSockets = node.data.outputs || [];


    for (const outputSocket of outputsSockets) {
      if (ifDataType == 'item') {
        const items = gudhub.filter(inpData, outputSocket.filters_list);
        if (items.length) {
          outputs[outputSocket.key] = inputs[outputSocket.inpKey][0];
        }
      } else {
        
        const conditionFunc = getConditionFunc(
          outputSocket.value_condition,
          outputSocket.value
        );
        const isTrue = outputSocket.every
          ? inpData.every(conditionFunc)
          : inpData.some(conditionFunc);
        if (isTrue) {
          outputs[outputSocket.key] = inputs[outputSocket.inpKey][0];
        }
      }
    }
    await awaitDebugger(node, inputs, outputs);
  }

  getTemplate() {
    return {
      name: "If Condition",
      icon: "build_stage",
      constructor: "node",
      data_model: {
        app_id: "",
      },
    };
  }

  getSettings() {
    return [
      {
        title: "Options",
        type: "general_setting",
        icon: "menu",
        columns_list: [
          [
            {
              title: "Default Settings",
              type: "header",
            },
            {
              type: "ghElement",
              property: "app_id",
              data_model: function (fieldModel) {
                return {
                  field_name: "Application",
                  data_type: "app",
                  data_model: {
                    current_app: false,
                    interpretation: [
                      {
                        src: "form",
                        id: "with_text",
                        settings: {
                          editable: 1,
                          show_field_name: 1,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                };
              },
            },
            {
              type: "ghElement",
              property: "is_item",
              data_model: function (fieldModel) {
                return {
                  field_name: "IF data type",
                  data_type: "text_opt",
                  data_model: {
                    options: [
                      { name: "Field Value", value: "value" },
                      { name: "Items", value: "item" },
                    ],
                  },
                };
              },
            },
            {
              title: "Inputs Settings",
              type: "header",
            },
            {
              type: "html",
              class: "option-column_500px",

              data_model: function (fieldModel) {
                return {
                  patterns: [
                    {
                      property: "type",
                      type: "text_opt",
                      prop_name: "Type",
                      data_model: function (option) {
                        return {
                          options: [
                            { name: "Field Value", value: "field_value" },
                            { name: "Items", value: "items" },
                          ],
                        };
                      },
                      display: true,
                    },
                    {
                      property: "field_name",
                      type: "text",
                      prop_name: "Field Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "key",
                      prop_name: "key",
                      type: "number",
                      data_model: function (option) {
                        return {};
                      },
                      getMaxValue: function () {
                        fieldModel.max_value = fieldModel.max_value || 1;
                        return fieldModel.max_value;
                      },
                      setMaxValue: function (maxValue) {
                        fieldModel.max_value = maxValue;
                      },

                      display: false,
                      generate_dynamic: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.inputs" pattern="field_model.patterns"></gh-option-table>',
            },
          ],
          [
            {
              title: "Outputs",
              type: "header",
            },
            {
              type: "html",
              class: "option-column_750px",
              data_model: function (fieldModel) {
                console.log(fieldModel);
                return {
                  patterns: [
                    {
                      property: "field_name",
                      type: "text",
                      prop_name: "Field Name",
                      data_model: function (option) {
                        return {};
                      },
                      display: true,
                    },
                    {
                      property: "inpKey",
                      type: "text_opt",
                      prop_name: "Input to Output",
                      data_model: function (option, scope) {
                        const inputs = fieldModel.inputs || [];
                        const options = inputs.map((input) => ({
                          name: input.field_name,
                          value: input.key,
                        }));
                        scope.$watch("option.inpKey", () => {
                          const input =
                            fieldModel.inputs.find(
                              (input) => input.key == scope.option.inpKey
                            ) || {};
                          scope.option.type = input.type;
                        });
                        return {
                          options,
                        };
                      },
                      display: true,
                    },
                    {
                      property: "value_condition",
                      type: "text_opt",
                      prop_name: "Value Condition",
                      data_model: function (option) {
                        return {
                          options: [
                            { name: ">", value: ">" },
                            { name: "<", value: "<" },
                            { name: "<=", value: "<=" },
                            { name: ">=", value: ">=" },
                            { name: "=", value: "==" },
                            { name: "!=", value: "!=" },
                          ],
                        };
                      },
                      display: fieldModel.is_item != 'item',
                    },
                    {
                      property: "value",
                      type: "text",
                      prop_name: "Value",
                      data_model: function (option) {
                        return {};
                      },
                      display: fieldModel.is_item != 'item'
                    },
                    {
                      type: "boolean",
                      property: "every",
                      prop_name: "Some/Every",
                      data_model: function (option) {
                        return {};
                      },
                      display: fieldModel.is_item != 'item',
                    },
                    {
                      property: "filters_list",
                      prop_name: "Item Conditions",
                      type: "filter_table",
                      display: fieldModel.is_item,
                      data_model: function (option, scope) {
                        scope.appId = fieldModel.app_id;
                        option.filters_list
                          ? (scope.filters_list = option.filters_list)
                          : (scope.filters_list = option.filters_list = []);
                        return {
                          mode: "variable",
                        };
                      },
                    },
                    {
                      property: "key",
                      prop_name: "key",
                      type: "number",
                      data_model: function (option) {
                        return {};
                      },
                      getMaxValue: function () {
                        fieldModel.max_value = fieldModel.max_value || 1;
                        return fieldModel.max_value;
                      },
                      setMaxValue: function (maxValue) {
                        fieldModel.max_value = maxValue;
                      },

                      display: false,
                      generate_dynamic: true,
                    },
                  ],
                };
              },
              control:
                '<gh-option-table items="fieldModel.outputs" pattern="field_model.patterns" ></gh-option-table>',
            },
          ],
        ],
      },
    ];
  }
}
